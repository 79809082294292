<template>
  <div class="select-metrics">
    <b-field label="Metrics :" grouped group-multiline>
      <p class="control">
        <b-button
          :type="
            value.length == tabAllMetrics.length ? 'is-danger' : 'is-warning'
          "
          @click="toggleShowAllMetrics()"
          rounded
        >
          {{
            value.length == tabAllMetrics.length ? "Unselect all" : "Select all"
          }}</b-button
        >
      </p>
      <b-checkbox
        v-on:input="clickBox"
        v-model="checkMetrics"
        type="is-warning"
        v-for="metric in tabAllMetrics"
        :key="metric"
        :native-value="metric"
      >
        {{ metricsLabels[metric] }}
      </b-checkbox>
    </b-field>
  </div>
</template>

<script>
export default {
  name: "SelectMetrics",
  props: { value: Array, startWith: Array },
  mounted() {
    if (!this.startWith) {
      this.$emit("input", this.stdMetrics);
      this.checkMetrics = this.stdMetrics;
    } else {
      this.$emit("input", this.startWith);
      this.checkMetrics = this.startWith;
    }
  },
  computed: {
    tabAllMetrics() {
      return [...new Set(this.stdMetrics.concat(this.startWith))];
    }
  },
  methods: {
    clickBox() {
      this.$emit("input", this.checkMetrics);
    },
    toggleShowAllMetrics() {
      if (this.checkMetrics.length == this.tabAllMetrics.length) {
        this.checkMetrics = [];
      } else {
        this.checkMetrics = this.tabAllMetrics.map(metric => {
          return metric;
        });
      }
      this.$emit("input", this.checkMetrics);
    }
  },
  data() {
    return {
      checkMetrics: [],
      stdMetrics: [
        "stock",
        "backlog",
        "order",
        "demand",
        "cost",
        "delivery",
        "shipment"
      ],
      metricsLabels: {
        stock: "Stock",
        backlog: "Backorder",
        order: "Order",
        demand: "Demand",
        delivery: "Receipt",
        shipment: "Shipment",
        cost: "Cost",
        revenue: "Revenue",
        missed: "Missed Sales"
      }
    };
  }
};
</script>

<style lang="scss">
.select-metrics {
  .label {
    margin-top: 3px;
    margin-right: 1em;
    font-size: 1.1rem;
    color: hsl(0, 0%, 48%);
  }
  .button {
    margin-right: 1em;
  }
  .b-checkbox {
    margin-top: 0;
    margin-right: 1em;
    margin-bottom: 1em;
  }
}
</style>
