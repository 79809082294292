<template>
  <div class="station-status-box-wrapper box">
    <!-- PAINTING: -->
    <div class="level" v-if="faci && faci.type === 'painting'">
      <div class="level-item">
        <b-tooltip
          style="cursor: help;"
          label="Number of units processed so far"
          type="is-dark"
          position="is-bottom"
          size="is-small"
          multilined
        >
          <p class="has-text-centered">
            <b-icon icon="format-list-bulleted" size="is-small"></b-icon>
            Units done:<br />
            {{ myStats.totalProcessed }} pcs
          </p>
        </b-tooltip>
      </div>
      <div class="level-item">
        <b-tooltip
          style="cursor: help;"
          :label="
            `${myStats.successOpes} Successful operations / ${myStats.totalOpes} operations performed`
          "
          type="is-dark"
          position="is-bottom"
          size="is-small"
          multilined
        >
          <p class="has-text-centered">
            <b-icon icon="check-circle" size="is-small"></b-icon>
            Success rate:<br />
            {{ myStats.pourcConform }}%
          </p>
        </b-tooltip>
      </div>
      <div
        class="level-item"
        :class="{ [`has-text-danger has-text-weight-bold`]: workdeskProduct }"
      >
        <b-tooltip
          style="cursor: help;"
          label="Current unit processing time"
          type="is-dark"
          position="is-bottom"
        >
          <p class="has-text-centered">
            <b-icon icon="timer" size="is-small"></b-icon>
            Current:
            <br />
            <timer-elapsed
              v-if="workdeskProduct"
              :is-active="true"
              :start-sec="
                workdeskProduct.stats.moves[
                  workdeskProduct.stats.moves.length - 1
                ].enter_at.seconds
              "
              :end-sec="
                currentGame.params.game_end_at
                  ? currentGame.params.game_end_at.seconds
                  : null
              "
            ></timer-elapsed>
            <span v-if="!workdeskProduct">00:00</span>
          </p>
        </b-tooltip>
      </div>
      <div class="level-item">
        <b-tooltip
          style="cursor: help;"
          label="Average processing time of past units"
          type="is-dark"
          position="is-bottom"
          size="is-small"
          multilined
        >
          <p class="has-text-centered">
            <b-icon icon="timer" size="is-small"></b-icon>
            Average:
            <br />
            <span>{{ myStats.averageTime | minsec }}</span>
          </p>
        </b-tooltip>
      </div>
    </div>
    <!-- WAREHOUSE: -->
    <div class="level" v-if="faci && faci.type === 'inventoryShipping'">
      <div class="level-item">
        <b-tooltip
          style="cursor: help;"
          label="Units delivered to the customers"
          type="is-dark"
          position="is-bottom"
          size="is-small"
          multilined
        >
          <p class="has-text-centered">
            <b-icon icon="playlist-check" size="is-small"></b-icon>
            Units sold:<br />
            {{ recapCustomerOrders.done }}/{{ recapCustomerOrders.all }} pcs
          </p>
        </b-tooltip>
      </div>
      <div
        class="level-item"
        :class="{
          [`has-text-danger`]: recapCustomerOrders.pourcOTIF < 90,
          [`has-text-success`]: recapCustomerOrders.pourcOTIF >= 90
        }"
      >
        <b-tooltip
          style="cursor: help;"
          :label="
            `${recapCustomerOrders.otif} Units delivered on time without defects`
          "
          type="is-dark"
          position="is-bottom"
          size="is-small"
          multilined
        >
          <p class="has-text-centered">
            <b-icon icon="calendar-check" size="is-small"></b-icon>
            On Time In Full:<br />
            {{ recapCustomerOrders.pourcOTIF }}%
          </p>
        </b-tooltip>
      </div>
      <div
        class="level-item has-text-weight-bold"
        :class="{
          [`has-text-danger`]:
            recapCustomerOrders.all > recapCustomerOrders.done,
          [`has-text-success`]:
            recapCustomerOrders.all <= recapCustomerOrders.done
        }"
      >
        <b-tooltip
          style="cursor: help;"
          label="Quantity of units the customer is waiting for"
          type="is-dark"
          position="is-bottom"
          size="is-small"
          multilined
        >
          <p class="has-text-centered">
            <b-icon icon="clock-alert" size="is-small"></b-icon>
            Pending:<br />
            {{ recapCustomerOrders.all - recapCustomerOrders.done }} pcs
          </p>
        </b-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TimerElapsed from "./TimerElapsed.vue";

export default {
  components: { TimerElapsed },
  name: "StationStatusBox",
  computed: {
    ...mapGetters(["currentGame", "currentProducts", "currentLeanGameOrders"]),
    myStats() {
      let retObj = {
        totalProcessed: 0,
        totalTime: 0,
        averageTime: 0,
        totalOpes: 0,
        failedOpes: 0,
        pourcConform: 0
      };
      this.currentProducts.forEach(pp => {
        pp.stats.moves.forEach(pm => {
          if (pm.fid === this.faci.id && pm.zone === "workdesk") {
            if (pm.leave_at) {
              retObj.totalProcessed += 1;
              retObj.totalTime +=
                pm.leave_at.toMillis() - pm.enter_at.toMillis();
            }
            pm.actions.forEach(pma => {
              if (pma.type === "operation") {
                retObj.totalOpes += 1;
                if (pma.status === "Failed") {
                  retObj.failedOpes += 1;
                }
              }
            });
          }
        });
      });
      retObj.successOpes = retObj.totalOpes - retObj.failedOpes;
      retObj.averageTime = retObj.totalProcessed
        ? Math.round(retObj.totalTime / retObj.totalProcessed)
        : 0;
      retObj.pourcConform = retObj.totalOpes
        ? Math.round(
            (100 * (retObj.totalOpes - retObj.failedOpes)) / retObj.totalOpes
          )
        : 100;
      return retObj;
    },
    workdeskProduct() {
      return this.currentProducts.find(pp => {
        return (
          this.faci && pp.pos.fid === this.faci.id && pp.pos.zone === "workdesk"
        );
      });
    },
    recapCustomerOrders() {
      let globals = {
        all: 0,
        done: 0,
        otif: 0,
        pourcOTIF: 0
      };
      this.currentLeanGameOrders.forEach(order => {
        globals.all += order.qty;
        if (order.fulfilled_at) {
          globals.done += order.qty;
          let checkOnTime = this.currentGame.params.game_start_at
            ? (order.fulfilled_at.seconds -
                this.currentGame.params.game_start_at.seconds) /
                60 <
              order.created_min + order.obj_lead_time
            : false;
          if (checkOnTime) {
            globals.otif +=
              order.qty - (order.defectProducts ? order.defectProducts : 0);
          }
        }
      });
      globals.pourcOTIF = globals.all
        ? Math.round((100 * globals.otif) / globals.all)
        : 0;
      return globals;
    }
  },
  props: { faci: Object }
};
</script>

<style lang="scss">
.station-status-box-wrapper {
  padding: 0.5em;
}
</style>
