<template>
  <custom-chart
    ref="chart"
    class="lines-chart"
    v-if="chartdata"
    :chart-data="chartdata"
    :options="options"
  ></custom-chart>
</template>

<script>
import CustomChart from "@/components/bg/Stats/CustomChart.js";
import tinycolor from "tinycolor2";

export default {
  name: "LinesChart",
  props: {
    faciData: Object,
    focusData: Object,
    baseColor: String,
    timeUnit: String,
    globalMax: Object
  },
  components: {
    CustomChart
  },
  computed: {
    chartdata() {
      let retObj = {
        labels: Object.values(this.faciData)[0].map((data, index) => {
          return index + 1;
        }),
        datasets: []
      };

      let rankFaci = 0;
      let totFaci = Object.keys(this.faciData).length;
      let arrColors = tinycolor("#4247CF").analogous(totFaci * 2, totFaci);
      for (let [faciKey, faciArr] of Object.entries(this.faciData).reverse()) {
        // const newCol = tinycolor(this.baseColor)
        //   .brighten((80 * rankFaci) / totFaci)
        //   .toString();
        retObj.datasets.push({
          label: faciKey.charAt(0).toUpperCase() + faciKey.slice(1),
          backgroundColor: "transparent",
          borderColor: arrColors[rankFaci].toHexString(),
          data: faciArr,
          yAxisID: "left-y-axis",
          type: "line"
        });
        rankFaci += 1;
      }
      if (this.focusData) {
        retObj.datasets.push({
          label: this.focusData.name,
          // backgroundColor: "transparent",
          backgroundColor: this.focusData.color,
          // borderColor: this.focusData.color,
          data: this.focusData.arr,
          yAxisID: "left-y-axis"
        });
      }
      return retObj;
    },
    options() {
      let retObj = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: "top"
        },
        scales: {
          yAxes: [
            {
              id: "left-y-axis",
              type: "linear",
              position: "left",
              scaleLabel: {
                display: false,
                labelString: "Units"
              },
              ticks: {
                suggestedMin: this.globalMax ? -1 * this.globalMax.minPcs : 0,
                suggestedMax: this.globalMax ? this.globalMax.maxPcs : 0
              }
            }
          ]
        }
      };
      return retObj;
    }
  }
};
</script>

<style lang="scss">
.lines-chart {
  margin-top: 1em;
}
</style>
