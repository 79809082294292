<template>
  <div class="lean-game-improve-wrapper modal-card">
    <header class="modal-card-head">
      <div class="modal-card-title has-text-centered">
        <b-icon icon="chevron-triple-up"></b-icon>
        Improvements
      </div>
      <button
        class="delete"
        aria-label="close"
        @click="$parent.close()"
      ></button>
    </header>
    <section class="modal-card-body py-2">
      <div class="box">
        <div
          class="title is-5 has-text-grey has-text-centered"
          v-if="!showImprovements.length"
        >
          No improvement available in this run.
        </div>
        <div
          v-for="(improvement, ind) in showImprovements"
          :key="improvement.id"
        >
          <div class="box-divider" v-if="ind > 0"></div>
          <div class="columns mb-0">
            <div class="column is-narrow">
              <h3 class="has-text-weight-bold is-size-5 px-3 pt-1">
                #{{ ind + 1 }}
              </h3>
            </div>
            <div class="column">
              <h3 class="is-size-5 has-text-grey pt-1 is-italic">
                {{ improvement.name }}
              </h3>
            </div>
            <div class="column is-narrow">
              <b-button
                type="is-warning"
                v-if="!improvement.update"
                size="is-small"
                icon-left="pencil"
                @click="improvement.update = true"
                >Setup</b-button
              >
              <b-button
                type="is-success is-light"
                v-if="improvement.update"
                size="is-small"
                icon-left="chevron-up"
                @click="improvement.update = false"
                >OK</b-button
              >
            </div>
          </div>
          <div
            class="bg-transit-slide bg-slide-large"
            :class="{ 'bg-transit-slide-open': improvement.update }"
          >
            <component
              v-if="improvement.component && improvement.update"
              v-bind:is="improvement.component"
              :game="localGame"
              :improve="true"
              :ref="improvement.id"
              @update="updateLocalGame"
              @sendvalidate="updateAllGame"
            ></component>
          </div>
          <div
            class="bg-transit-slide"
            :class="{
              'bg-transit-slide-open': improvement.update,
              'box-divider-greylane': improvement.update
            }"
          ></div>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot buttons is-centered">
      <b-button
        rounded
        type="is-grey"
        size="is-medium"
        v-if="isChanged"
        @click="cancelChange"
        >Cancel</b-button
      >
      <b-button
        rounded
        form="update-game-form"
        type="is-success"
        tag="input"
        native-type="submit"
        size="is-medium"
        value="Update game"
        v-if="isChanged"
        @click.prevent="updateAllGame"
      ></b-button>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "LeanGameImprove",
  computed: {
    ...mapGetters(["currentGame"]),
    showImprovements() {
      return this.allImprovements.filter(imp => {
        return imp.show;
      });
    },
    currentImprovements() {
      return this.currentGame.params.improvements
        ? this.currentGame.params.improvements
        : this.currentGame.params.runs
        ? this.currentGame.params.runs[this.currentGame.params.currentRun - 1]
            .improvements
        : {};
    }
  },
  mounted() {
    this.resetLocalImprovements();
    this.localGame = JSON.parse(JSON.stringify(this.currentGame));
  },
  methods: {
    cancelChange() {
      this.isChanged = false;
      this.localGame = JSON.parse(JSON.stringify(this.currentGame));
      setTimeout(() => {
        this.allImprovements.forEach(imp => {
          if (this.$refs[imp.id] && this.$refs[imp.id][0]) {
            this.$refs[imp.id][0].initializeData();
          }
        });
      }, 3);
    },
    updateAllGame() {
      if (this.isChanged) {
        this.$store
          .dispatch("updateGameDoc", [
            {
              gid: this.currentGame.id,
              obj: this.localGame
            }
          ])
          .then(() => {
            this.isChanged = false;
            return this.$buefy.toast.open("Game updated");
          });
      }
    },
    updateLocalGame(event) {
      if (event.type === "map") {
        this.localGame.params.mapSize = event.obj.mapSize;
        Object.entries(event.obj.facilities).forEach(([fid, faciData]) => {
          this.localGame.facilities[fid].position = faciData.position;
        });
      }
      if (event.type === "demandparams") {
        this.localGame.params.consumerDemandParams = event.obj;
      }
      if (event.type === "demand") {
        this.localGame.params.consumerDemand = event.obj.tab;
        this.localGame.params.consumerDemandParams = event.obj.params;
      }
      if (event.type === "batch") {
        Object.values(event.obj).forEach(temp => {
          this.localGame.params.productsTemplates[temp.template_id].batchSize =
            temp.batchSize;
        });
      }
      if (event.type === "guides") {
        Object.values(event.obj).forEach(temp => {
          this.localGame.params.productsTemplates[temp.template_id].showGuides =
            temp.showGuides || false;
        });
      }
      if (event.type === "changeover") {
        Object.values(event.obj).forEach(fac => {
          if (this.localGame.facilities[fac.id].params && fac.params) {
            this.localGame.facilities[fac.id].params.changeoverDuration =
              fac.params.changeoverDuration || 0;
          }
        });
      }
      if (event.type === "kanban") {
        Object.values(event.obj).forEach(fac => {
          if (this.localGame.facilities[fac.id].params && fac.params) {
            this.localGame.facilities[fac.id].params.kanbanCapa =
              fac.params.kanbanCapa || null;
            if (
              !fac.params.kanbanCapa &&
              this.localGame.facilities[fac.id].params.sendAuto
            ) {
              this.localGame.facilities[fac.id].params.sendAuto = false;
            }
          }
        });
      }
      if (event.type === "balance") {
        Object.values(event.obj).forEach(fac => {
          if (this.localGame.facilities[fac.id].params && fac.params) {
            this.localGame.facilities[fac.id].params = fac.params;
            this.localGame.facilities[fac.id].name = fac.name;
            this.localGame.facilities[fac.id].icon = fac.icon;
          }
        });
      }
      if (event.type === "jidoka") {
        Object.values(event.obj).forEach(fac => {
          if (
            this.localGame.facilities[fac.id].params &&
            fac.params &&
            fac.type === "painting"
          ) {
            this.localGame.facilities[fac.id].params.stampRemove =
              fac.params.stampRemove;
          }
        });
      }
      if (event.type === "balanceremove") {
        Object.values(this.localGame.facilities).forEach(fac => {
          if (fac.prioNextStation === event.obj.id) {
            fac.prioNextStation = event.obj.prioNextStation;
          }
        });
        delete this.localGame.facilities[event.obj.id];
      }
      // Missing Balance workload and Kanban system
      this.isChanged = true;
    },
    resetLocalImprovements() {
      this.allImprovements.forEach(imp => {
        if (this.currentImprovements && this.currentImprovements[imp.id]) {
          imp.show = true;
        }
      });
    }
  },
  watch: {
    currentRunImprovements() {
      this.resetLocalImprovements();
    }
  },
  data() {
    return {
      isChanged: false,
      localGame: null,
      allImprovements: [
        {
          id: "movestations",
          name: "Move stations",
          update: false,
          show: false,
          component: () => import("@/components/lean/Create/LeanConfigMap.vue")
        },
        {
          id: "batch",
          name: "Reduce batch size",
          update: false,
          show: false,
          component: () =>
            import(
              "@/components/lean/Play/Menus/Improvements/LeanImprovementBatch.vue"
            )
        },
        {
          id: "autoinspect",
          name: "Auto-inspect work (Jidoka)",
          update: false,
          show: false,
          component: () =>
            import(
              "@/components/lean/Play/Menus/Improvements/LeanImprovementJidoka.vue"
            )
        },
        {
          id: "balancestations",
          name: "Balance workload",
          update: false,
          show: false,
          component: () =>
            import(
              "@/components/lean/Play/Menus/Improvements/LeanImprovementBalance.vue"
            )
        },
        {
          id: "heijunka",
          name: "Level customer demand ",
          update: false,
          show: false,
          component: () =>
            import("@/components/lean/Create/LeanConfigDemand.vue")
        },
        {
          id: "pokayoke",
          name:
            "Add click-guides to reduce defects (poka-yoke / error-proofing)",
          update: false,
          show: false,
          component: () =>
            import(
              "@/components/lean/Play/Menus/Improvements/LeanImprovementGuides.vue"
            )
        },
        {
          id: "kanban",
          name: "Kanban (pull-system)",
          update: false,
          show: false,
          component: () =>
            import(
              "@/components/lean/Play/Menus/Improvements/LeanImprovementKanban.vue"
            )
        },
        {
          id: "smed",
          name: "Reduce tool-changeover time (SMED)",
          update: false,
          show: false,
          component: () =>
            import(
              "@/components/lean/Play/Menus/Improvements/LeanImprovementChangeover.vue"
            )
        }
      ]
    };
  }
};
</script>

<style lang="scss">
.lean-game-improve-wrapper {
}
</style>
