<template>
  <div class="bg-debrief" v-if="currentGame">
    <article>
      <div v-if="!hidePrinting">
        <section class="intro-section mt-2">
          <p>
            Add our Completion Certificate on your LinkedIn profile, and save
            your results for later analysis :
          </p>
          <div class="buttons is-centered mt-4 mb-2">
            <b-button
              tag="a"
              target="_blank"
              :href="certifUrl"
              size="is-medium"
              icon-left="linkedin"
              type="is-info"
              >Add certificate to my profile</b-button
            >
            <b-button
              size="is-medium"
              type="is-warning"
              icon-left="file"
              @click="downloadPdf"
              :disabled="!currentGame.week"
              >Download debrief as pdf</b-button
            >
            <b-button
              size="is-medium"
              type="is-success"
              icon-left="file-excel-box"
              @click="downloadExcel"
              :disabled="!currentGame.week"
              >Download game data (excel)</b-button
            >
          </div>
        </section>
        <p class="mb05">
          It isn't easy to make supply meet demand.<br />
          During this session you may have felt frustrated as other players
          weren't able to deliver, or ordered too much.<br />
          Now is time to analyse the mechanisms behind those difficulties, and
          how we can overcome them.<br />
        </p>
      </div>
      <section class="intro-section mt-4">
        <div
          class="avoid-pagebreak"
          v-if="
            !currentGame.params.debriefContent ||
              currentGame.params.debriefContent.includes('Global Results')
          "
        >
          <h5 class="title is-4 mb05">
            Supply Chain Performance
          </h5>
          <div class="tile is-ancestor mb-1">
            <div class="tile is-parent" v-if="kpi.totalRevenue">
              <div class="tile is-child box">
                <h1
                  class="title is-4 has-text-info"
                  :class="{
                    'has-text-danger': kpi.totalRevenue < kpi.totalCost,
                    'has-text-info': kpi.totalRevenue >= kpi.totalCost
                  }"
                >
                  {{
                    (kpi.totalRevenue - kpi.totalCost)
                      | currency(currentGame.params.currency, 0, {
                        showPlusSign: true
                      })
                  }}
                </h1>
                <h1 class="subtitle is-6">
                  Profit (Rev.
                  {{ kpi.totalRevenue | currency(currentGame.params.currency) }}
                  - Cost
                  {{ kpi.totalCost | currency(currentGame.params.currency) }})
                </h1>
              </div>
            </div>
            <div class="tile is-parent" v-if="!kpi.totalRevenue">
              <div class="tile is-child box">
                <h1 class="title is-4 has-text-danger">
                  {{ kpi.totalCost | currency(currentGame.params.currency) }}
                </h1>
                <h1 class="subtitle is-6">Total Cost</h1>
              </div>
            </div>
            <div class="tile is-parent">
              <div class="tile is-child box">
                <h1
                  class="title is-4"
                  :class="{
                    'has-text-danger': kpi.missedSales['1'],
                    'has-text-success': kpi.missedSales['1'] <= 0
                  }"
                >
                  {{ kpi.sumDemand["1"] - kpi.missedSales["1"] }} /
                  {{ kpi.sumDemand["1"] }} =
                  {{
                    Math.floor(
                      1000 * (1 - kpi.missedSales["1"] / kpi.sumDemand["1"])
                    ) / 10
                  }}%
                </h1>
                <h1 class="subtitle is-6">
                  {{
                    currentFacilities[0].name
                      | capitalize({ onlyFirstLetter: true })
                  }}
                  On-time sales
                </h1>
              </div>
            </div>
            <div class="tile is-parent">
              <div class="tile is-child box">
                <h1 class="title is-4">
                  {{ kpi.averageTotalStock | currency("") }} units
                </h1>
                <h1 class="subtitle is-6">
                  Average Total Stock
                </h1>
              </div>
            </div>
            <div class="tile is-parent">
              <div class="tile is-child box">
                <h1 class="title is-4 has-text-danger">
                  {{ kpi.nbWeeksBacklog | currency("") }}/{{
                    currentGame.params.totalWeeks
                  }}
                  {{ currentGame.params.timeUnit || "week" }}s
                </h1>
                <h1 class="subtitle is-6">
                  With backorders
                </h1>
              </div>
            </div>
          </div>
          <div class="content">
            At each stage, a performant supply-chain :
            <ul>
              <li>
                Shouldn't have too many backorders
                <span class="has-text-grey">
                  - they drive penalities and lost sales.
                </span>
              </li>
              <li>
                Shouldn't have too much stock
                <span class="has-text-grey"
                  >- this increases storage costs, immobilized assets and
                  obsolescence risk.</span
                >
              </li>
            </ul>
            <p>
              In this simulation our main focus is to keep
              <strong>the sum of both costs - all stages combined</strong> as
              low as possible.
            </p>
          </div>
        </div>
        <br />
        <div
          class="avoid-pagebreak"
          v-if="
            !currentGame.params.debriefContent ||
              currentGame.params.debriefContent.includes('Fill Rate')
          "
        >
          <h1 class="title is-4 mb05">
            Individual Performance - Fill rate vs.
            {{ kpi.totalRevenue ? "Profit" : "Cost" }}
          </h1>
          <p class="mb-3">
            The capacity to fulfill the clients needs can be illustrated by the
            'Fill rate' (% of units shipped on time).
            <br />
            We may target a more reasonable Fill rate (97% for example) if this
            helps reduce significally supply-chain costs.
          </p>
          <div class="tile is-ancestor">
            <div
              class="tile is-parent"
              v-for="faci in currentFacilities"
              :key="faci.id"
            >
              <div class="tile is-child box">
                <h1 class="title is-5">
                  <b-icon :icon="faci.icon"></b-icon>
                  {{ faci.name | capitalize({ onlyFirstLetter: true }) }}
                  <span class="is-size-6 has-text-grey">{{
                    faci.playerName | capitalize({ onlyFirstLetter: true })
                  }}</span>
                </h1>
                <h1
                  class="title is-4"
                  :class="{
                    'has-text-success':
                      kpi.missedSales[faci.id] <= 0.05 * kpi.sumDemand[faci.id],
                    'has-text-danger':
                      kpi.missedSales[faci.id] > 0.05 * kpi.sumDemand[faci.id]
                  }"
                >
                  {{ kpi.sumDemand[faci.id] - kpi.missedSales[faci.id] }} /
                  {{ kpi.sumDemand[faci.id] }} =
                  {{
                    Math.floor(
                      1000 *
                        (1 - kpi.missedSales[faci.id] / kpi.sumDemand[faci.id])
                    ) / 10
                  }}%
                </h1>
                <h1 class="subtitle is-6 mb05">
                  Units sold on time
                </h1>
                <h1
                  class="title is-4 "
                  :class="{
                    'has-text-danger':
                      kpi.finalRevenue[faci.id] < kpi.finalCost[faci.id],
                    'has-text-info':
                      kpi.finalRevenue[faci.id] >= kpi.finalCost[faci.id]
                  }"
                >
                  {{
                    (kpi.finalRevenue[faci.id] - kpi.finalCost[faci.id])
                      | currency(currentGame.params.currency, 0, {
                        showPlusSign: true
                      })
                  }}
                </h1>
                <h1 class="subtitle is-6" v-if="!kpi.finalRevenue[faci.id]">
                  Cost
                </h1>
                <h1 class="subtitle is-6" v-if="kpi.finalRevenue[faci.id]">
                  Profit (Rev.
                  {{
                    kpi.finalRevenue[faci.id]
                      | currency(currentGame.params.currency)
                  }}
                  - Cost
                  {{
                    kpi.finalCost[faci.id]
                      | currency(currentGame.params.currency)
                  }})
                </h1>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div class="avoid-pagebreak">
          <div
            v-if="currentGame.params.debriefContent.includes('Global Results')"
          >
            <h5 class="title is-4 mb05">
              Supply Chain evolution
            </h5>
            <p class="content">
              See below the evolution of key metrics throughout the game, all
              stages combined.
            </p>
          </div>
          <div class="container is-max-print">
            <stats-section
              ref="globalStatsSection"
              :game="currentGame"
              :show-legend="true"
              :style="{
                height: currentGame.params.debriefContent.includes(
                  'Global Results'
                )
                  ? 'auto'
                  : 0,
                opacity: currentGame.params.debriefContent.includes(
                  'Global Results'
                )
                  ? 1
                  : 0
              }"
              :custom-metrics="baseMetrics"
              :show-details="false"
              :show-faci-tab="allFaci"
              :show-total="true"
            ></stats-section>
          </div>
        </div>
        <div
          class="intro-section avoid-pagebreak"
          v-if="
            sellOut &&
              (!currentGame.params.debriefContent ||
                currentGame.params.debriefContent.includes('Facility Charts'))
          "
        >
          <h1 class="title is-4 mb05">
            Stages Evolution
          </h1>
          <p class="content">
            Find below the comparison of profiles of the different roles in the
            chain.
          </p>
          <div class="container box" v-if="!hidePrinting">
            <select-metrics
              v-model="checkMetrics"
              :start-with="baseMetrics"
            ></select-metrics>
          </div>
          <stats-section
            class="mb05"
            ref="faciStatsSection"
            :game="currentGame"
            :my-style="{ height: '280px' }"
            :show-details="false"
            :show-legend="true"
            :show-faci-tab="allFaci"
            :show-total="false"
            :custom-metrics="checkMetrics"
          ></stats-section>
        </div>
        <div
          class="intro-section avoid-pagebreak"
          v-if="
            !currentGame.params.debriefContent ||
              currentGame.params.debriefContent.includes('Orders/Stock Charts')
          "
        >
          <div v-if="sellOut">
            <h1 class="title is-4 mb05">
              Response to Demand
            </h1>
            <div class="content">
              The graphs below compare the end consumer demand to the response
              of the industrial partners : orders and stock.
              <br />
              Do you see a gap between the input signal and the reaction inside
              the supply-chain system ?
            </div>
          </div>
          <div class="container is-max-print mt-2" v-if="sellOut">
            <div class="columns is-centered">
              <div class="column is-half">
                <h1 class="title is-5 has-text-centered">
                  Orders vs. End Consumer Demand
                </h1>
                <lines-chart
                  :timeUnit="currentGame.params.timeUnit || 'week'"
                  baseColor="#06BD98"
                  :faciData="ordersObj"
                  :focusData="{
                    color: '#ffdd57',
                    name: 'End Consumer Demand',
                    arr: sellOut
                  }"
                ></lines-chart>
              </div>
              <div class="column is-half">
                <h1 class="title is-5 has-text-centered">
                  Stock/Backorders vs. End Consumer Demand
                </h1>
                <lines-chart
                  :timeUnit="currentGame.params.timeUnit || 'week'"
                  baseColor="#2e3192"
                  :faciData="stockObj"
                  :focusData="{
                    color: '#ffdd57',
                    name: 'End Consumer Demand',
                    arr: sellOut
                  }"
                ></lines-chart>
              </div>
            </div>
          </div>
          <div class="content pt-2">
            We often see supply-chains alternate between phases of over-stock
            and out-of-stock.<br />The amplitude of variations increase as we
            move upstream from retailer to manufacturer.<br />This phenomenon is
            called the <strong>Bullwhip effect</strong>.
          </div>
        </div>
        <div
          class="mt-5 avoid-pagebreak"
          v-if="
            !currentGame.params.debriefContent ||
              currentGame.params.debriefContent.includes('Variability')
          "
        >
          <h1 class="title is-4 mb05">
            Variability
          </h1>
          <div class="content">
            You must have felt it was a challenge to cope with a changing and
            uncertain demand.
            <br />Small variabilities in the final consumer orders may have big
            impacts on the industrial chain.
          </div>
          <div class="tile is-ancestor">
            <div class="tile is-parent">
              <div class="tile is-child box">
                <h1 class="title is-4 has-text-success">
                  {{ kpi.maxConsumer }} units
                </h1>
                <h1 class="subtitle is-6 mb05">
                  Biggest order of the final consumer
                </h1>
                <h1 class="title is-4 has-text-danger">
                  vs. {{ kpi.maxOrderQty }} units
                </h1>
                <h1 class="subtitle is-6">
                  Biggest order by the
                  {{ kpi.maxOrderFaci | capitalize({ onlyFirstLetter: true }) }}
                  in
                  {{ currentGame.params.timeUnit || "week" }}
                  {{ kpi.maxOrderWeek }}
                </h1>
              </div>
            </div>
            <div class="tile is-parent">
              <div class="tile is-child box">
                <h1 class="title is-4 has-text-success">
                  {{ kpi.avgConsumer | currency("", 1) }} units
                </h1>
                <h1 class="subtitle is-6 mb05">
                  Average {{ currentGame.params.timeUnit || "week" }}ly consumer
                  demand
                </h1>
                <h1 class="title is-4 has-text-danger">
                  vs. {{ kpi.avgOrder | currency("", 1) }} units
                </h1>
                <h1 class="subtitle is-6">
                  Average {{ currentGame.params.timeUnit || "week" }}ly
                  supply-chain orders
                </h1>
              </div>
            </div>
            <div class="tile is-parent">
              <div class="tile is-child box">
                <h1 class="title is-4 has-text-success">
                  {{ kpi.stDevConsumer | currency("", 1) }} units
                </h1>
                <h1 class="subtitle is-6 mb05">
                  Standard deviation of consumer demand
                </h1>
                <h1 class="title is-4 has-text-danger">
                  vs.{{ kpi.stDevOrder | currency("", 1) }} units
                </h1>
                <h1 class="subtitle is-6">
                  Standard deviation of supply-chain orders
                </h1>
              </div>
            </div>
            <div class="tile is-parent">
              <div class="tile is-child box">
                <h1 class="title is-4 has-text-success">
                  {{ kpi.coefVarConsumer }}%
                </h1>
                <h1 class="subtitle is-6 mb05">
                  Coef. of variation (StDev / Mean) of consumer demand
                </h1>
                <h1 class="title is-4 has-text-danger">
                  vs. {{ kpi.coefVarOrder }}%
                </h1>
                <h1 class="subtitle is-6">
                  Coefficient of variation of supply-chain orders
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div
          class="mt-6 avoid-pagebreak mb-6"
          v-if="
            !currentGame.params.debriefContent ||
              currentGame.params.debriefContent.includes('Lead times')
          "
        >
          <h1 class="title is-4 mb05">
            Lead times
          </h1>
          <div class="content">
            In our example, each node adds lead times for both information and
            material flows.<br />It results in an end-to-end supply chain which
            is
            <b
              >{{ kpi.sumLeadTimes }}
              {{ currentGame.params.timeUnit || "week" }}s</b
            >
            long from <em>{{ currentFacilities[0].name | capitalize }}</em> to
            <em>
              {{
                currentFacilities[currentFacilities.length - 1].name
                  | capitalize
              }} </em
            >.
            <br />
            The more delays in the system, the bigger the variability and
            amplification of information, which leads to distorted and delayed
            material flows.
            <br />
            <br />Very few managers are aware of the long trajectories that
            their order must complete before being fulfilled. We tend to expect
            the goods to arrive with the next truck.<br />But in reality the
            system structure makes each delivery to the consumer the result of a
            demand signal sent at least {{ kpi.sumLeadTimes }}
            {{ currentGame.params.timeUnit || "week" }}s ago!
          </div>
          <div class="columns mx-4 my-4">
            <div
              class="column is-2 has-text-centered columns is-mobile is-vcentered mx-0"
            >
              <div class="column">
                <b-icon size="is-medium" icon="account"></b-icon>
                <br />
                Consumer
              </div>
              <div class="column is-hidden-mobile">
                <faci-arrow :is-grey="true" :only-left="false"></faci-arrow>
              </div>
            </div>
            <div class="column">
              <div class="system-border-padding">
                <div class="has-text-centered has-text-danger mt-5 is-italic">
                  Order delays
                </div>
                <div class="columns is-vcentered pt-5 pb-3 mb-0">
                  <div
                    class="column has-text-centered columns is-mobile is-vcentered mx-0"
                    :key="faciRank.id"
                    v-for="faciRank in currentFacilities"
                  >
                    <div class="column is-paddingless has-text-centered">
                      <b-icon size="is-medium" :icon="faciRank.icon"></b-icon>
                      <br />
                      {{
                        faciRank.name | capitalize({ onlyFirstLetter: true })
                      }}
                      <div v-if="faciRank.type === 'factory'">
                        <em class="has-text-info is-size-6"
                          ><b-icon icon="wrench" size="is-small"></b-icon>
                          {{ faciRank.leadtime
                          }}{{ currentGame.params.timeUnit[0] }}</em
                        >
                      </div>
                    </div>
                    <div class="column is-hidden-mobile">
                      <faci-arrow
                        v-if="faciRank.type !== 'factory'"
                        :show-leadtime="true"
                        :faci="faciRank"
                      ></faci-arrow>
                    </div>
                  </div>
                </div>
                <div class="has-text-centered has-text-info mb-5 is-italic">
                  Shipping/Production delays
                </div>
              </div>
              <div class="content has-text-centered is-italic is-size-5 mt-3">
                Total lead time:
                <b
                  >{{ kpi.sumLeadTimes }}
                  {{ currentGame.params.timeUnit || "week" }}s</b
                >.
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="
            !currentGame.params.debriefContent ||
              currentGame.params.debriefContent.includes('To Go Further')
          "
          class="avoid-pagebreak"
        >
          <h1 class="title is-4 mb05">
            To go further
          </h1>
          <div class="content">
            The best supply-chains manage fluctuations of the demand in a smooth
            way : they are reactive, agile and sustainable 👌
            <br /><em>A few questions to stimulate your thinking...</em>
            <br />
            <div class="notification py-1 mt-2">
              <div v-for="question in questions" :key="question.q">
                <p class="bq-group-list py-2 mb-0">
                  <a
                    class="title is-6 px-4"
                    @click="question.toggle = !question.toggle"
                  >
                    {{ question.text }}
                    <b-icon
                      class="is-pulled-right"
                      :icon="question.toggle ? 'menu-up' : 'menu-down'"
                    >
                    </b-icon
                  ></a>
                </p>
                <blockquote v-if="question.toggle" class="py-0">
                  <ul>
                    <li v-for="answer in question.answers" :key="answer">
                      {{ answer }}
                    </li>
                  </ul>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import XlsxPopulate from "xlsx-populate";
import html2pdf from "html2pdf.js";
import StatsSection from "@/components/bg/Stats/StatsSection.vue";
import LinesChart from "@/components/bg/Stats/LinesChartContainer.vue";
import SelectMetrics from "@/components/bg/Stats/SelectMetrics.vue";
import FaciArrow from "@/components/bg/Facility/FaciArrow.vue";

let getMean = function(data) {
  return (
    data.reduce(function(a, b) {
      return Number(a) + Number(b);
    }) / data.length
  );
};
let getSD = function(data) {
  let m = getMean(data);
  return Math.sqrt(
    data.reduce(function(sq, n) {
      return sq + Math.pow(n - m, 2);
    }, 0) /
      (data.length - 1)
  );
};

export default {
  name: "BgDebrief",
  components: {
    FaciArrow,
    StatsSection,
    LinesChart,
    SelectMetrics
  },
  mounted() {
    this.$store.dispatch("bindStats", {
      id: this.currentGame.id,
      force: false
    });
    let stObj = this.$refs.globalStatsSection.statsObj;
    if (stObj.all && stObj.all.stock) {
      this.updateStats(this.$refs.globalStatsSection.statsObj);
    } else {
      let unwatch = this.$watch(
        "$refs.globalStatsSection.statsObj.all",
        newVal => {
          if (newVal && newVal.stock) {
            this.updateStats(this.$refs.globalStatsSection.statsObj);
            unwatch();
          }
        }
      );
    }
  },
  methods: {
    downloadPdf() {
      this.questions.forEach(qq => {
        qq.toggle = true;
      });
      document.querySelector(".game-debrief").classList.add("is-printing");
      this.hidePrinting = true;
      // this.$refs.globalStatsSection.updateCharts();
      // this.$refs.faciStatsSection.updateCharts();
      setTimeout(() => {
        html2pdf(document.querySelector(".game-debrief section.section"), {
          margin: 5,
          pagebreak: { avoid: ".avoid-pagebreak" },
          filename: "beergame_debrief.pdf",
          html2canvas: {
            scale: 2,
            scrollY: 0,
            onclone: element => {
              const canvasElements = Array.from(
                element.querySelectorAll("svg")
              );
              canvasElements.forEach(s => {
                const bBox = s.getBBox();
                s.setAttribute("x", bBox.x);
                s.setAttribute("y", bBox.y);
                s.setAttribute("width", bBox.width * 0.5);
                s.setAttribute("height", bBox.height);
              });
            }
          },
          jsPDF: { orientation: "l", format: "a4" }
        });
        setTimeout(() => {
          document
            .querySelector(".game-debrief")
            .classList.remove("is-printing");
          this.hidePrinting = false;
        }, 5);
      }, 10);
    },
    updateStats(statsObj) {
      let sumStock = statsObj["all"]["stock"].reduce(
        (previous, current) => (current += previous)
      );
      this.kpi.averageTotalStock = sumStock / statsObj["all"]["stock"].length;
      this.kpi.retailerUnitsSold = statsObj["1"]["demand"].reduce(
        (previous, current) => (current += previous)
      );
      this.kpi.retailerUnitsSold -=
        statsObj["1"]["backlog"][statsObj["1"]["backlog"].length - 1];

      this.kpi.totalRevenue =
        statsObj["all"]["revenue"][statsObj["all"]["revenue"].length - 1];
      this.kpi.totalCost =
        statsObj["all"]["cost"][statsObj["all"]["cost"].length - 1];
      this.kpi.totalMissed = statsObj["all"]["missed"]
        ? statsObj["all"]["missed"].reduce((a, b) => a + b, 0)
        : 0;

      this.kpi.nbWeeksBacklog = statsObj["all"]["backlog"].filter(bval => {
        return bval > 0;
      }).length;

      let facis = Object.keys(statsObj).filter(faciId => {
        return (
          faciId !== "all" &&
          faciId !== "0" &&
          typeof statsObj[faciId] === "object"
        );
      });
      this.kpi.maxOrderQty = 0;
      this.kpi.maxOrderWeek = 0;
      this.kpi.maxOrderFaci = "";
      facis.forEach(faciId => {
        this.kpi.missedSales[faciId] = statsObj[faciId]["backlog"].reduce(
          (missed, current, index, array) => {
            return (missed || 0) + Math.max(0, current - array[index - 1]);
          }
        );
        this.kpi.missedSales[faciId] += statsObj[faciId]["missed"]
          ? statsObj[faciId]["missed"].reduce((a, b) => a + b, 0)
          : 0;
        this.kpi.sumDemand[faciId] = statsObj[faciId]["demand"].reduce(
          (previous, current) => (current += previous)
        );
        this.kpi.weeksBacklog[faciId] = statsObj[faciId]["backlog"].filter(
          bval => {
            return bval > 0;
          }
        ).length;
        this.kpi.finalRevenue[faciId] =
          statsObj[faciId]["revenue"] && statsObj[faciId]["revenue"].length
            ? statsObj[faciId]["revenue"][
                statsObj[faciId]["revenue"].length - 1
              ]
            : 0;
        this.kpi.finalCost[faciId] =
          statsObj[faciId]["cost"][statsObj[faciId]["cost"].length - 1];
        statsObj[faciId]["order"].forEach((orderQty, week) => {
          if (orderQty > this.kpi.maxOrderQty) {
            this.kpi.maxOrderQty = orderQty;
            this.kpi.maxOrderFaci = this.currentGame.facilities[faciId].name;
            this.kpi.maxOrderWeek = week;
          }
        });
      });
      this.kpi.sumLeadTimes = this.currentFacilities.reduce((prev, curr) => {
        return prev + curr.leadtime;
      }, 0);
      let kpiFaci = { avgList: [], stdDevList: [], coefVarList: [] };
      facis.forEach(faciId => {
        kpiFaci.avgList.push(getMean(statsObj[faciId]["order"]));
        kpiFaci.stdDevList.push(getSD(statsObj[faciId]["order"]));
        kpiFaci.coefVarList.push(
          getSD(statsObj[faciId]["order"]) / getMean(statsObj[faciId]["order"])
        );
      });
      this.kpi.avgOrder = getMean(kpiFaci.avgList);
      this.kpi.stDevOrder = getMean(kpiFaci.stdDevList);
      this.kpi.coefVarOrder = Math.round(getMean(kpiFaci.coefVarList) * 100);
      this.kpi.maxConsumer = Math.max(...statsObj["0"]["order"]);
      this.kpi.avgConsumer = getMean(statsObj["0"]["order"]);
      this.kpi.stDevConsumer = getSD(statsObj["0"]["order"]);
      this.kpi.coefVarConsumer = Math.round(
        (100 * getSD(statsObj["0"]["order"])) / getMean(statsObj["0"]["order"])
      );
      this.excelData = statsObj;
      facis.forEach(faciId => {
        this.ordersObj[this.currentGame.facilities[faciId].name] =
          statsObj[faciId]["order"];
      });
      facis.forEach(faciId => {
        this.stockObj[this.currentGame.facilities[faciId].name] = statsObj[
          faciId
        ]["stock"].map((val, ii) => {
          return val - statsObj[faciId]["backlog"][ii];
        });
      });
      this.sellOut = statsObj["0"]["order"];
    },
    downloadExcel() {
      const excelParams = [
        "stock",
        "backlog",
        "order",
        "demand",
        "delivery",
        "shipment",
        "cost"
      ];
      XlsxPopulate.fromBlankAsync().then(workbook => {
        // Modify the workbook.
        const sheet = workbook.sheet("Sheet1");
        sheet
          .row(1)
          .style("bold", true)
          .style("fill", "fbacad")
          .style("fontColor", "192e30")
          .style("wrapText", true);
        // ADD the week number on the left based on retailer's week column :
        sheet.cell(1, 1).value(this.currentGame.params.timeUnit || "week");
        this.excelData["1"]["week"].forEach((week, ww) => {
          sheet.cell(2 + ww, 1).value(week);
        });
        // Run through each Facility to add the excel params stats :
        this.currentFacilities.forEach((faci, faciRank) => {
          excelParams.forEach((param, pp) => {
            //Populate the header for the parameter
            sheet
              .cell(1, 2 + faciRank * excelParams.length + pp)
              .value(
                this.$options.filters.capitalize(faci["name"]) + " " + param
              );
            sheet.column(2 + faciRank * excelParams.length + pp).width(13);
            //Populate the list of values at each line
            for (
              let ii = 0;
              ii < this.excelData[faci["id"]][param].length;
              ii++
            ) {
              sheet
                .cell(2 + ii, 2 + faciRank * excelParams.length + pp)
                .value(this.excelData[faci["id"]][param][ii]);
            }
          });
        });

        // Write to file.
        return workbook.outputAsync().then(blob => {
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            // If IE, you must uses a different method.
            window.navigator.msSaveOrOpenBlob(blob, "beergame_data.xlsx");
          } else {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.href = url;
            a.download = "beergame_stats.xlsx";
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
          }
        });
      });
    }
  },
  computed: {
    ...mapGetters([
      "loading",
      "currentGame",
      "currentFacilities",
      "currentUser"
    ]),
    allFaci() {
      return this.currentFacilities.map(faci => {
        return faci["id"];
      });
    },
    certifUrl() {
      let today = new Date();
      let obj = {
        name: "Supply%20chain%20practice%20through%20the%20Beer%20Game",
        orgId: "70246155",
        site: "https%3A%2F%2Fzensimu.com",
        curYear: today.getFullYear(),
        curMonth: today.getMonth()
      };
      return `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${obj.name}&organizationId=${obj.orgId}&issueYear=${obj.curYear}&
issueMonth=${obj.curMonth}&certUrl=${obj.site}`;
    },
    baseMetrics() {
      let retTab = ["stock", "backlog", "order", "cost"];
      if (this.kpi.totalRevenue > 0) {
        retTab.push("revenue");
      }
      if (this.kpi.totalMissed > 0) {
        retTab.push("missed");
      }
      return retTab;
    }
  },
  data() {
    return {
      hidePrinting: false,
      excelData: {},
      checkMetrics: [],
      kpi: {
        averageTotalStock: 0,
        retailerUnitsSold: 0,
        sumLeadTimes: 0,
        totalCost: 0,
        totalRevenue: 0,
        nbWeeksBacklog: 0,
        maxOrderQty: 0,
        maxOrderFaci: "",
        maxOrderWeek: 0,
        maxOrderConsumer: 0,
        sumDemand: {},
        missedSales: {},
        weeksBacklog: {},
        finalCost: {},
        finalRevenue: {}
      },
      sellOut: null,
      ordersObj: {},
      stockObj: {},
      questions: [
        {
          text:
            "Which parameters may have an influence on the Bullwhip effect ?",
          answers: [
            "Lead times make supply-chains less reactive to changes of demand. This also increases the tendency to 'secure' stock and order more than needed.",
            "The number of stages in the chain has a negative impact. Each level tries to avoid backorders and secure their own stock, which creates tension in the chain.",
            "Batch sizes and Minimum Order Quantities (MOQ) reduce costs (thanks to economies of scale) - but reduce flexibility.",
            "The lack of visibility on the stocks/demand make it impossible to anticipate production. When industrials master their whole supply-chain's capacity, they can take better stock decisions."
          ],
          toggle: false
        },
        {
          text: "How could a different supply network improve performance ?",
          answers: [
            "Reducing the number of stages would be quite beneficial. Some industrials choose a 'direct to consumer' strategy, which reduces time-to-market, allows unifed IT-systems and access to all the data.",
            "Having alternative sources of supply can reduce risk and improve reactivity. In the same sense, a factory having flexible production lines can smooth its productions depending on demand.",
            "In product development, making use of generic components can reduce their variability and obsolescence risk. Industrials should try to design products with a late differentiation (ex: using the same bottles for different beer brands)."
          ],
          toggle: false
        },
        {
          text: "Cite a few innovations that might reduce the Bullwhip effect",
          answers: [
            "ERPs are now deployed to the cloud. This improves visibility and access to standardized information in the whole chain.",
            "E-commerce distribution reduces the number of intermediates : products are available faster and for the whole world.",
            "New production methods such as 3D-printing allow more flexibility and late customization.",
            "Artificial Intelligence and 'Demand Sensing' techniques can boost forecast quality, stock decision-makings and reactivity."
          ],
          toggle: false
        }
      ]
    };
  }
};
</script>

<style lang="scss">
.bg-debrief {
  padding-bottom: 2em;
  .container.is-max-print {
    width: 1000px !important;
    max-width: 100%;
  }
  .system-border-padding {
    padding-left: 30px;
    border: solid 1px lightgrey;
  }
  .intro-section {
    padding-top: 0.5em;
    padding-bottom: 1em;
    .is-italic.has-text-grey {
      margin-top: 0.5em;
    }
    .bq-group-list {
      border-radius: 3px;
      &:hover {
        background-color: #ddd;
      }
    }
    .mb15 {
      margin-bottom: 1.5em;
    }
    .mb05 {
      margin-bottom: 0.5em;
      &.subtitle {
        margin-bottom: 2em;
      }
    }
    .tile.box {
      display: flex;
      flex-direction: column;
    }
    .content ul {
      margin-top: 0.1em;
      margin-bottom: 0.5em;
    }
  }
  .column.notification {
    padding: 1.5em;
    a {
      text-decoration: none;
    }
  }
}
</style>
