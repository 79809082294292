<template>
  <div class="lean-stage-wrapper" v-if="currentGame">
    <b-sidebar
      :open="showMessages && !showOnboarding"
      :can-cancel="[]"
      type="is-light"
      :right="true"
      v-if="$route.name === 'play'"
    >
      <game-messages :is-open="showMessages && !showOnboarding"></game-messages>
    </b-sidebar>
    <b-modal :active="showOnboarding" trap-focus @close="closeLeanOnboarding">
      <lean-student-onboarding
        @closeonboarding="closeLeanOnboarding"
      ></lean-student-onboarding>
    </b-modal>
    <div class="container is-widescreen pt-5">
      <!-- <lean-student-tour></lean-student-tour> -->
      <div class="columns is-vcentered">
        <div class="column">
          <game-status-box></game-status-box>
        </div>
        <div class="column">
          <div class="columns is-vcentered">
            <div class="column is-narrow px-0" v-if="openFaciPanel">
              <b-tooltip
                label="Back to workshop"
                type="is-dark"
                position="is-bottom"
              >
                <b-button
                  style="height: 2.68em;"
                  size="is-large"
                  icon-left="grid"
                  @click="
                    $store.commit('setOpenFaciPanel', {
                      fid: null,
                      gid: currentGame.id
                    })
                  "
                >
                  <b-icon
                    size="is-medium"
                    icon="arrow-left"
                    class="pt-2 pl-2"
                  ></b-icon>
                </b-button>
              </b-tooltip>
            </div>
            <div class="column px-0 has-text-centered">
              <h2 class="title is-4 mt-1 mb-1">
                <span v-if="openFaciPanel">
                  {{ currentGame.facilities[openFaciPanel].name }}
                </span>
                <p v-if="myFaci && !openFaciPanel">
                  Playing as:
                  <a
                    @click="
                      $store.commit('setOpenFaciPanel', {
                        fid: myFaci.id,
                        gid: currentGame.id
                      })
                    "
                    >{{ myFaci.name }}</a
                  >
                </p>
                <p
                  v-if="!currentGame.params.game_start_at"
                  class="is-size-6 has-text-danger"
                >
                  The game has not started yet
                </p>
                <p
                  v-if="currentGame.params.game_end_at"
                  class="is-size-6 has-text-danger"
                >
                  The game is finished -
                  <router-link :to="'/game/' + currentGame.id + '/results'"
                    >Go to debrief</router-link
                  >
                </p>
                <p
                  v-if="
                    currentGame.params.game_start_at &&
                      !myFaci &&
                      !currentGame.params.game_end_at
                  "
                  class="is-size-6 has-text-danger"
                >
                  You are playing as observer
                </p>
                <p
                  v-if="
                    !currentGame.params.game_end_at &&
                      openFaciPanel &&
                      currentGame.params.game_start_at &&
                      myFaci &&
                      myFaci.id !== openFaciPanel
                  "
                  class="is-size-6 has-text-danger"
                >
                  You are playing as another station
                </p>
              </h2>
            </div>
          </div>
        </div>
        <div class="column is-5">
          <station-status-box
            v-if="openFaciPanel"
            :faci="currentGame.facilities[openFaciPanel]"
          ></station-status-box>
        </div>
      </div>
      <div class="columns is-centered">
        <div class="column is-1 pt-1 pb-0" v-if="!openFaciPanel">
          <lean-box-side :is-small="true"></lean-box-side>
        </div>
        <!-- WORKSTATIONS GRID : -->
        <div v-if="!openFaciPanel" class="column">
          <lean-shop-map
            :game="currentGame"
            :in-game="true"
            @editname="$emit('editname')"
          ></lean-shop-map>
        </div>
        <!-- OPEN FACI : -->
        <div v-if="openFaciPanel" class="column">
          <component
            v-if="faciComponents[openFaciPanel]"
            v-bind:is="faciComponents[openFaciPanel]"
            :faci="currentGame.facilities[openFaciPanel]"
          ></component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StationStatusBox from "@/components/lean/Play/StationElements/StationStatusBox.vue";
import GameStatusBox from "@/components/lean/Play/StationElements/GameStatusBox.vue";
import LeanBoxSide from "@/components/lean/Play/Menus/LeanBoxSide.vue";
import GameMessages from "@/components/bg/Play/GameMessages.vue";
import LeanStudentOnboarding from "@/components/lean/Play/Menus/LeanStudentOnboarding.vue";
// import LeanStudentTour from "@/components/lean/Play/Menus/LeanStudentTour.vue";
import LeanShopMap from "@/components/lean/Play/LeanShopMap.vue";

export default {
  name: "LeanStage",
  components: {
    GameStatusBox,
    StationStatusBox,
    LeanBoxSide,
    GameMessages,
    LeanStudentOnboarding,
    LeanShopMap
    // LeanStudentTour
  },
  computed: {
    ...mapGetters([
      "openFaciPanel",
      "currentGame",
      "custoLook",
      "currentUser",
      "isCreator",
      "showOnboarding",
      "showMessages",
      "myFaci",
      "isLeanGameActive"
    ]),
    faciComponents() {
      let obj = {};
      Object.values(this.currentGame.facilities).forEach(faci => {
        if (faci.type && this.stationsComponents[faci.type]) {
          obj[faci.id] = this.stationsComponents[faci.type];
        }
      });
      return obj;
    },
    routeQueryStation() {
      return this.$route.query.station;
    }
  },
  mounted() {
    this.$store.dispatch("bindProducts", {
      id: this.currentGame.id,
      force: true
    });
    this.$store.dispatch("bindMessages", {
      id: this.currentGame.id,
      force: false
    });
    this.$store.dispatch("updateLeanGameMinutes");
  },
  methods: {
    closeLeanOnboarding() {
      this.$store.commit("setShowOnboarding", false);
      if (!this.currentGame.creator && !this.currentGame.params.game_start_at) {
        setTimeout(() => {
          this.$store.dispatch("startLeanRun", [this.currentGame]);
        }, 500);
      }
    }
  },
  watch: {
    isLeanGameActive() {
      this.$store.dispatch("updateLeanGameMinutes");
    },
    routeQueryStation: {
      immediate: true,
      handler(newStation) {
        if (newStation && this.openFaciPanel !== newStation) {
          this.$store.commit("setOpenFaciPanel", {
            fid: newStation,
            gid: this.currentGame.id,
            routeUpdated: true
          });
        } else if (!newStation && this.openFaciPanel) {
          this.$store.commit("setOpenFaciPanel", {
            fid: null,
            gid: this.currentGame.id,
            routeUpdated: true
          });
        }
      }
    }
  },
  data() {
    return {
      hoverFaci: null,
      isStatsModalActive: false,
      stationsComponents: {
        painting: () =>
          import("@/components/lean/Play/Stations/PaintingStation.vue"),
        inventoryShipping: () =>
          import("@/components/lean/Play/Stations/InventoryShippingStation.vue")
      }
    };
  }
};
</script>

<style lang="scss">
.lean-stage-wrapper {
  padding-bottom: 2em;
  .map-svg {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .lean-box-side {
    height: 100%;
  }
}
</style>
