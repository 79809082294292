var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lean-orders-wrapper mt-2 mx-1",class:{ 'bottom-border': !_vm.recap }},[(_vm.recap)?_c('div',{staticClass:"box py-3 mb-3"},[_c('div',{staticClass:"level mb-1"},[_c('div',{staticClass:"level-item"},[_c('h3',{staticClass:"title has-text-grey is-3"},[_c('span',{staticClass:"has-text-info"},[_vm._v(_vm._s(_vm.recapCustomerOrders.done))]),_vm._v("/"+_vm._s(_vm.recapCustomerOrders.all)+" pcs "),_c('b-icon',{attrs:{"icon":"arrow-right"}}),_c('em',{staticClass:"has-text-info"},[_vm._v(" "+_vm._s(_vm._f("percent")(_vm.recapCustomerOrders.all ? _vm.recapCustomerOrders.done / _vm.recapCustomerOrders.all : 0))+" ")]),_c('h3',{staticClass:"subtitle has-text-dark is-6"},[_vm._v("Units sold")])],1)]),_c('div',{staticClass:"level-item"},[_c('h3',{staticClass:"title has-text-grey is-3"},[_c('span',{staticClass:"has-text-info"},[_vm._v(_vm._s(_vm.recapCustomerOrders.otif))]),_vm._v("/"+_vm._s(_vm.recapCustomerOrders.all)+" pcs "),_c('b-icon',{attrs:{"icon":"arrow-right"}}),_c('em',{staticClass:"has-text-info"},[_vm._v(" "+_vm._s(_vm._f("percent")(_vm.recapCustomerOrders.all ? _vm.recapCustomerOrders.otif / _vm.recapCustomerOrders.all : 0))+" ")]),_c('h3',{staticClass:"subtitle has-text-dark is-6"},[_vm._v(" Sold on-time and conform ")])],1)])]),_c('p',{staticClass:"has-text-centered is-italic is-size-5"},[_c('b',{class:{
          'has-text-danger': _vm.recapCustomerOrders.late,
          'has-text-success': !_vm.recapCustomerOrders.late
        }},[_c('b-icon',{attrs:{"icon":"clock-alert","size":"is-small"}}),_vm._v(" "+_vm._s(_vm._f("pluralize")(_vm.recapCustomerOrders.late,"pc", { includeNumber: true })))],1),_vm._v(" delivered late, "),_c('b',{class:{
          'has-text-danger': _vm.recapCustomerOrders.defective,
          'has-text-success': !_vm.recapCustomerOrders.defective
        }},[_c('b-icon',{attrs:{"icon":"sim-alert","size":"is-small"}}),_vm._v(" "+_vm._s(_vm._f("pluralize")(_vm.recapCustomerOrders.defective,"pc", { includeNumber: true })))],1),_vm._v(" delivered with defects ")])]):_vm._e(),_c('b-collapse',{attrs:{"aria-id":"ordersTableId","animation":"slide","open":!_vm.recap},scopedSlots:_vm._u([{key:"trigger",fn:function(props){return (_vm.recap)?_c('div',{staticClass:"has-text-centered is-5"},[_c('b-button',{attrs:{"icon-right":props.open ? 'menu-down' : 'menu-up',"aria-controls":"ordersTableId","type":"is-info","rounded":""}},[_vm._v("See detail")])],1):_vm._e()}}],null,true)},[_c('div',{staticClass:"box max-height-400 mt-2 py-2 px-2"},[_c('b-table',{staticClass:"has-background-white",attrs:{"id":"clientorders-table","data":_vm.clientOrdersList,"narrowed":true,"row-class":function (row) { return row.rowClass; },"mobile-cards":false}},[_c('b-table-column',{attrs:{"custom-key":"rank","width":"30px"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('em',{staticClass:"has-text-grey"},[_vm._v("#"+_vm._s(props.row.rank))])]}}])}),_c('b-table-column',{attrs:{"label":"Created:"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('em',[_vm._v(_vm._s(_vm._f("minsec")((props.row.created_min * 60000))))])]}}])}),_c('b-table-column',{attrs:{"label":"Due:"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('em',{class:{
              'has-text-danger has-text-weight-bold': props.row.is_too_late
            }},[_vm._v(_vm._s(_vm._f("minsec")((props.row.due_min * 60000))))])]}}])}),_c('b-table-column',{attrs:{"label":"Detail:"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(_vm._f("capitalize")(props.row.product_name,{ onlyFirstLetter: true }))+": "),_c('b',[_vm._v(_vm._s(props.row.qty)+" pcs")])]}}])}),_c('b-table-column',{attrs:{"label":"Status:"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(!props.row.fulfilled_at)?_c('b-icon',{staticClass:"has-text-grey",attrs:{"icon":"close","size":"is-small"}}):_vm._e(),(props.row.fulfilled_at)?_c('b-tooltip',{staticStyle:{"cursor":"help"},attrs:{"multilined":"","size":"is-small","type":"is-dark","position":"is-bottom","label":"Order fulfilled " +
                (props.row.is_on_time ? "on time " : "late ") +
                (props.row.defectProducts
                  ? ("with " + (props.row.defectProducts) + " defective product" + (props.row.defectProducts > 1 ? 's' : '') + ".")
                  : "with no defect.")}},[_c('b',{class:{
                'has-text-success':
                  props.row.is_on_time && !props.row.defectProducts,
                'has-text-danger':
                  !props.row.is_on_time || props.row.defectProducts
              }},[_c('b-icon',{attrs:{"icon":"check","size":"is-small"}}),_vm._v(" "+_vm._s(_vm._f("minsec")((props.row.fulfilled_min * 60000)))+" "),(!props.row.is_on_time)?_c('b-icon',{attrs:{"icon":"clock-alert","size":"is-small"}}):_vm._e(),(props.row.defectProducts > 0)?_c('b-icon',{attrs:{"icon":"sim-alert","size":"is-small"}}):_vm._e()],1)]):_vm._e()]}}])}),_c('b-table-column',{attrs:{"custom-key":"Action","visible":_vm.isActive},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"is-relative"},[(
                !props.row.fulfilled_at &&
                  (!_vm.game.params.consumerDemandParams.firstonly ||
                    props.index <= _vm.recapCustomerOrders.nextRank) &&
                  props.row.check_enough
              )?_c('b-icon',{staticClass:"lean-moving-order-arrow",attrs:{"icon":"arrow-right-bold","type":"is-primary","size":"is-large"}}):_vm._e(),(
                !props.row.fulfilled_at &&
                  (!_vm.game.params.consumerDemandParams.firstonly ||
                    props.index <= _vm.recapCustomerOrders.nextRank)
              )?_c('b-button',{attrs:{"size":"is-small","type":"is-info","disabled":!props.row.check_enough},on:{"click":function($event){return _vm.fulfillOrder(props.row)}}},[_vm._v("Fulfill")]):_vm._e()],1)]}}])})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }