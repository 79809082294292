<template>
  <div
    class="faci-wrapper column columns is-desktop mb-0"
    :class="{
      expanded: showAllData,
      'is-large-faci': myFaci,
      'is-2-desktop': !myFaci && !showAllData,
      'is-5-desktop is-size-5': myFaci && !showAllData,
      'is-3-desktop': showAllData
    }"
  >
    <div class="column m0 is-1" :class="{ 'is-2': !myFaci && !showAllData }">
      <faci-arrow
        v-if="faci.client_id"
        :is-stage="true"
        :faci="faci"
        :is-grey="true"
        :only-left="true"
      ></faci-arrow>
    </div>
    <div class="column m0">
      <div
        class="faci-box"
        :style="{
          backgroundColor: faciBckColor
        }"
      >
        <div class="faci-header has-text-centered">
          <div class="faci-status is-left has-text-grey">
            <div class="faci-status-item has-text-left">
              <!-- <span class="is-size-6" v-if="myFaci"
                >{{ currentGame.params.timeUnit || "week" | capitalize }}
                {{ currentGame.week }}<br
              /></span> -->
              <span v-if="(showAllData || myFaci) && faci.unitPrice">
                <b-tooltip
                  style="cursor: help;"
                  type="is-dark"
                  label="Cumulative Revenue"
                  position="is-bottom"
                >
                  <b class="is-size-6 has-text-success">
                    <b-icon icon="cash" size="is-small"></b-icon>R:
                    {{
                      faci.revenue | currency(currentGame.params.currency)
                    }}</b
                  >
                </b-tooltip>
                <br />
              </span>

              <b-tooltip
                v-if="showAllData || myFaci"
                style="cursor: help;"
                type="is-dark"
                label="Cumulative Cost"
                position="is-bottom"
              >
                <b class="is-size-6 has-text-danger">
                  <b-icon icon="cash" size="is-small"></b-icon>C:
                  {{ faci.cost | currency(currentGame.params.currency) }}</b
                >
              </b-tooltip>
            </div>
          </div>
          <div class="faci-status is-right has-text-grey">
            <div class="faci-status-item" v-if="faci.user">
              <b-tooltip
                style="cursor: help;"
                :label="
                  faci.validatedOrder ? 'Order validated' : 'Waiting for order'
                "
                type="is-dark"
                position="is-top"
              >
                <b-icon
                  size="is-small"
                  :icon="faci.validatedOrder ? 'check' : 'autorenew'"
                ></b-icon>
              </b-tooltip>
            </div>
            <div class="faci-status-item">
              <b-tooltip
                style="cursor: help;"
                :label="
                  faci.user
                    ? currentUser && faci.user === currentUser.id
                      ? 'This is you'
                      : 'Other player'
                    : 'Played by the Computer'
                "
                type="is-dark"
                position="is-top"
              >
                <b-icon
                  size="is-small"
                  :icon="faci.user ? 'account' : 'laptop'"
                ></b-icon>
              </b-tooltip>
            </div>
          </div>
          <div class="faci-name has-text-dark">
            <b-icon
              v-if="!myFaci || showAllData"
              :icon="faci.icon || nameIcon[faci.name]"
              size="is-medium"
              class="faci-main-icon"
            ></b-icon>
            <div class="columns is-centered pt-2 pb-3">
              <div class="column is-narrow py-0" v-if="myFaci && !showAllData">
                <b-icon
                  :icon="faci.icon || nameIcon[faci.name]"
                  size="is-medium"
                  class="faci-main-icon"
                ></b-icon>
              </div>
              <div class="column is-narrow py-0">
                <h3 class="faci-main-name title is-5" v-if="faci.name">
                  {{ faci.name | capitalize({ onlyFirstLetter: true }) }}
                </h3>
                <h4 class="is-6 subtitle">
                  <span
                    v-if="myFaci"
                    style="cursor:pointer"
                    @click="$emit('editname', false)"
                  >
                    {{
                      faci.playerName && faci.playerName.length
                        ? faci.playerName
                        : "Add Name"
                    }}
                    <b-icon icon="pencil" size="is-small"></b-icon>
                  </span>
                  <span v-if="!myFaci">
                    {{
                      faci.playerName && faci.playerName.length
                        ? faci.playerName
                        : ""
                    }}
                  </span>
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div class="faci-divider" v-if="showAllData || myFaci"></div>
        <faci-calc-panel
          @animarrow="animeArrow"
          v-if="showAllData || myFaci"
          :faci="faci"
          :anime-data="animeData"
          :rank-backlog="rankBacklog"
          :my-faci="myFaci"
        ></faci-calc-panel>
        <div
          class="faci-divider"
          v-if="(showAllData && isCreator && !showStudentView) || myFaci"
        ></div>
        <div
          class="faci-content new-order-panel"
          v-if="(showAllData && isCreator && !showStudentView) || myFaci"
        >
          <h3 :class="{ 'has-text-weight-semibold': myFaci }">
            New
            {{ faci.type === "factory" ? "Production" : "Purchase" }} Order:
          </h3>
          <h4 class="has-text-grey is-italic subtitle is-7 mb-0">
            Lead time: {{ faci.leadtime }} {{ currentGame.params.timeUnit
            }}{{ faci.leadtime > 1 ? "s" : "" }}
          </h4>
          <!-- <b-tooltip
              style="cursor: help;"
              type="is-dark"
              :label="
                `Lead time: ${faci.leadtime} ${currentGame.params.timeUnit}s`
              "
            >
              <b-icon size="is-small" icon="help-circle-outline"></b-icon>
            </b-tooltip>
            : -->
          <b-field
            class="field-neworder"
            position="is-centered"
            :message="
              !$v.newOrder.$error || newOrder === ''
                ? ''
                : `Please choose a quantity between ${faci.moq || 0} and 99999`
            "
            :type="{ 'is-danger': $v.newOrder.$error }"
            grouped
            expanded
          >
            <p class="control" v-if="myFaci && !currentGame.finished">
              <b-icon
                class="faci-moving-order-arrow"
                icon="arrow-right-bold"
                type="is-info"
                size="is-large"
              ></b-icon>
            </p>
            <b-input
              @keyup.native.enter="sendOrder()"
              type="number"
              :size="myFaci ? 'is-medium' : 'is-default'"
              :min="0"
              max="99999"
              v-model.trim.number="$v.newOrder.$model"
              :disabled="faci.validatedOrder || currentGame.finished"
            ></b-input>
            <p class="control">
              <button
                class="button is-black btn-play-sendorder is-rounded"
                :class="{ 'is-medium': myFaci }"
                :style="{
                  'background-color': custoLook.btnBestColor,
                  color: 'white'
                }"
                @click="sendOrder()"
                :disabled="
                  faci.validatedOrder ||
                    currentGame.finished ||
                    $v.newOrder.$error ||
                    !batchOrder.isMultiple ||
                    (faci.moq > 0 && newOrder > 0 && newOrder < faci.moq)
                "
              >
                <b-icon v-if="faci.validatedOrder" icon="check"></b-icon>
                {{ faci.validatedOrder ? "" : "Order" }}
              </button>
            </p>
          </b-field>
          <p
            class="is-size-7 is-italic"
            v-if="faci.moq"
            :class="{
              'has-text-success': newOrder <= 0 || newOrder >= faci.moq,
              'has-text-danger': newOrder > 0 && newOrder < faci.moq
            }"
          >
            MOQ
            <b-tooltip
              style="cursor: help;"
              type="is-dark"
              label="Minimum Order Quantity"
            >
              <b-icon size="is-small" icon="help-circle-outline"></b-icon>
            </b-tooltip>
            = {{ faci.moq }} units or
            <a class="has-text-weight-bold" @click="newOrder = 0"
              >order nothing</a
            >
          </p>
          <p
            class="is-size-7 is-italic"
            v-if="faci.batchSize"
            :class="{
              'has-text-success': batchOrder.isMultiple,
              'has-text-danger': !batchOrder.isMultiple
            }"
          >
            Batch size = {{ faci.batchSize }} units.
            <br />
            <a
              class="has-text-weight-bold"
              v-if="!batchOrder.isMultiple"
              @click="roundOrder"
              >Round quantity to {{ batchOrder.roundOrder }} ({{
                batchOrder.nbBatches
              }}
              batches)</a
            >
          </p>
        </div>
        <div class="faci-divider" v-if="showAllData || myFaci"></div>
        <faci-orders-panel
          v-if="showAllData || myFaci"
          :faci="faci"
          :my-faci="myFaci"
        ></faci-orders-panel>
      </div>
    </div>
  </div>
</template>

<script>
import {
  integer,
  minValue,
  maxValue,
  required
} from "vuelidate/lib/validators";
import FaciOrdersPanel from "./FaciOrdersPanel.vue";
import anime from "animejs/lib/anime.es.js";
import FaciCalcPanel from "./FaciCalcPanel.vue";
import FaciArrow from "./FaciArrow.vue";
import { mapGetters } from "vuex";
import tinycolor from "tinycolor2";

export default {
  name: "Facility",
  components: {
    FaciArrow,
    FaciOrdersPanel,
    FaciCalcPanel
  },
  computed: {
    ...mapGetters([
      "loading",
      "currentUser",
      "currentGame",
      "isCreator",
      "showAllData",
      "showStudentView",
      "custoLook"
    ]),
    week() {
      return this.currentGame.week;
    },
    batchOrder() {
      if (!this.faci.batchSize) {
        return {
          isMultiple: true
        };
      }
      let nbBatches =
        this.newOrder <= 0
          ? 0
          : Math.floor(this.newOrder / this.faci.batchSize) + 1;
      return {
        isMultiple:
          this.newOrder / this.faci.batchSize -
            Math.floor(this.newOrder / this.faci.batchSize) ===
          0
            ? true
            : false,
        roundOrder: this.faci.batchSize * nbBatches,
        nbBatches: nbBatches
      };
    },
    nameIcon() {
      return {
        manufacturer: "factory",
        retailer: "cart",
        distributor: "airplane",
        wholesaler: "domain"
      };
    },
    myFaci() {
      return (
        this.faci.user &&
        this.currentUser &&
        this.faci.user == this.currentUser.uid
      );
    },
    rankBacklog() {
      if (!this.myFaci || !this.animeData.backlog) {
        return 1;
      }
      return Math.max(
        1,
        Math.min(
          1.5,
          1 +
            (0.5 *
              Math.round(
                100 *
                  (this.animeData.backlog /
                    Math.max(40, 10 * this.faci.startOrders))
              )) /
              100
        )
      );
    },
    faciBckColor() {
      if (this.myFaci) {
        return tinycolor.mix("#FFFFF5", "red", 60 * (this.rankBacklog - 1));
      } else if (this.showAllData) {
        return "hsl(0, 0%, 98%)";
      } else {
        return "transparent";
      }
    }
  },
  validations() {
    return {
      newOrder: {
        required,
        integer,
        minValue: minValue(0),
        maxValue: maxValue(99999)
      }
    };
  },
  methods: {
    roundOrder() {
      this.newOrder = this.batchOrder.roundOrder;
    },
    animeArrow() {
      if (!this.faci.validatedOrder) {
        anime.remove(".faci-moving-order-arrow");
        anime.set(".faci-moving-order-arrow", { translateX: 0 });
        anime({
          targets: ".faci-moving-order-arrow",
          translateX: -20,
          loop: true,
          duration: 800,
          direction: "alternate",
          easing: "easeInOutSine"
        });
      }
    },
    sendOrder() {
      let minQty = this.faci.moq ? this.faci.moq : 0;
      if (
        (this.newOrder || this.newOrder === 0) &&
        (this.newOrder === 0 || this.newOrder >= minQty) &&
        this.batchOrder.isMultiple
      ) {
        let newOrder = {
          game_id: this.currentGame.id,
          user_id: this.currentUser.uid,
          faci_id: this.faci.id,
          to: this.faci.id,
          from: this.faci.type == "factory" ? null : this.faci.supplier_id,
          type: this.faci.type == "factory" ? "production" : "purchase",
          ongoing: true,
          orderQty: parseInt(this.newOrder),
          shipQty: 0,
          orderWeek: this.currentGame.week,
          applyWeek:
            this.currentGame.week +
            (isNaN(this.faci.ordertime) ? 1 : this.faci.ordertime),
          dueWeek:
            this.currentGame.week +
            (isNaN(this.faci.leadtime)
              ? this.currentGame.params.leadtime
              : this.faci.leadtime)
        };
        return this.$store.dispatch("createOrder", newOrder).then(() => {
          anime.remove(".faci-moving-order-arrow");
          anime.set(".faci-moving-order-arrow", { translateX: 0 });
        });
      } else {
        return Promise.resolve();
      }
    },
    updateAnimeData() {
      anime({
        targets: this.animeData,
        stock: this.faci.stock,
        cost: this.faci.cost,
        backlog: this.faci.backlog,
        revenue: this.faci.revenue,
        easing: "linear",
        round: 1,
        delay: 1500,
        duration: 1500
      });
    }
  },
  props: { faci: Object },
  created() {
    this.animeData = {
      stock: this.faci.stock,
      cost: this.faci.cost,
      backlog: this.faci.backlog,
      revenue: this.faci.revenue
    };
    if (this.faci.moq) {
      this.newOrder = this.faci.moq;
    }
    if (this.faci.batchSize) {
      this.newOrder = this.batchOrder.roundOrder;
    }
  },
  watch: {
    week() {
      this.updateAnimeData();
    },
    faci() {
      this.updateAnimeData();
    }
  },
  data() {
    return {
      newOrder: 0,
      animeData: { stock: 0, cost: 0, backlog: 0, revenue: 0 }
    };
  }
};
</script>

<style lang="scss">
.faci-wrapper {
  margin: 0.5em 0;
  padding: 0;
  @media (max-width: 767px) {
    margin: 0;
  }
  // padding-right: 0;
  .field-neworder {
    .button:hover {
      filter: brightness(105%);
    }
    .help {
      display: block;
    }
  }
  .column.m0 {
    padding: 0;
  }
  .faci-box {
    border-radius: 4px;
    height: auto;
    padding: 20px 0;
    .faci-header {
      position: relative;
      .faci-main-icon {
        display: inline-block;
      }
      .faci-main-name {
        display: block;
        margin-top: 3px;
        // margin-bottom: 10px;
      }
      .faci-status {
        position: absolute;
        &.is-right {
          right: 15px;
        }
        &.is-left {
          left: 15px;
        }
        .faci-status-item {
          display: inline-block;
          margin-right: 5px;
        }
      }
    }
    .new-order-panel {
      padding-left: 5%;
    }

    .faci-top {
      margin: 0;
      .column {
        padding: 0;
      }
      .icon {
        margin-right: 5px;
      }
    }
    .faci-content {
      width: 100%;
      margin: 0em 0;
      @media (max-width: 767px) {
        margin: 0.1em 0;
        font-size: 0.9em;
      }

      .field-neworder {
        margin: 10px 10%;
        margin-bottom: 0px;
      }
    }
  }
}
.faci-wrapper.expanded .faci-box {
  // border: solid 1px rgb(233, 233, 233);
  padding: 10px 0;
  box-shadow: 0 0em 1em -0.4em rgba(#000, 0.1), 0 0px 0 1px rgba(#000, 0.1);
}
.faci-wrapper.is-large-faci .faci-box {
  box-shadow: 0 0em 1em -0.4em rgba(#000, 0.1), 0 0px 0 1px rgba(#000, 0.1);
}
</style>
