export default (game, products) => {
  let retObj = {
    gameObj: game,
    calcTaktTime: { totalDem: 0, takt: 0, timeAvail: 0 },
    loaded: false,
    products: products,
    totalGameDurationMilli: 0,
    productsStatsObj: {
      all: {
        faciData: {},
        faciOpeData: {},
        sumFaciData: {},
        manufLeadTime: { tot: 0, nbProducts: 0 }
      }
    },
    stockEvolution: {
      tabMin: [],
      tabSec: [],
      total: { all: { faciTot: { all: 0 } } },
      totalSec: { all: { faciTot: { all: 0 } } },
      nbMinutes: 0,
      nbSec: 0
    },
    capaTimeTable: { data: [], valAddTot: 0, nbProcStations: 0 },
    faciOpeStatus: { data: [], tots: { all: 0 } },
    recapStatsTable: {
      lines: [],
      totalProfit: 0,
      totalOTIF: 0,
      totalSales: 0,
      totalSalesOnTime: 0,
      totalRevenue: 0
    },
    currentLeanGameOrders: [],
    custoDefects: { all: { nbProd: 0, nbOpe: 0 } }
  };
  let linesObj = {};
  const endSec = game.params.game_end_at
    ? game.params.game_end_at.seconds
    : Date.now() / 1000;
  const startSec = game.params.game_start_at
    ? game.params.game_start_at.seconds
    : Date.now() / 1000;
  retObj.totalGameDurationMilli = endSec * 1000 - startSec * 1000;
  const sortOpeType = {
    OK: 1,
    Successful: 0,
    Failed: 2,
    Rework: 3,
    Correction: 4
  };
  let currentGameMinute = Math.ceil((10 * (endSec - startSec)) / 60) / 10;
  retObj.currentLeanGameOrders = game.params.consumerDemand.filter(dem => {
    return (
      dem.created_min < currentGameMinute ||
      game.params.consumerDemandParams.visibility
    );
  });

  // CALCULATE TAKT and Get nb defects/sales :
  retObj.calcTaktTime.timeAvail =
    game.params.consumerDemandParams.totalDuration * 60 * 1000;
  Object.values(game.params.productsTemplates).forEach(prod => {
    retObj.custoDefects[prod.template_id] = { nbProd: 0, nbOpe: 0 };
  });
  retObj.currentLeanGameOrders
    .sort((a, b) => {
      return a.created_min - b.created_min;
    })
    .forEach(order => {
      retObj.calcTaktTime.totalDem += order.qty;
      // Calculate the last order required delivery time to see the total time available :
      // Quality defects:
      retObj.custoDefects.all.nbProd += order.defectProducts || 0;
      retObj.custoDefects.all.nbOpe += order.defectOpes || 0;
      retObj.custoDefects[order.template_id].nbProd +=
        order.defectProducts || 0;
      retObj.custoDefects[order.template_id].nbOpe += order.defectOpes || 0;
    });
  retObj.calcTaktTime.takt =
    retObj.calcTaktTime.totalDem > 0
      ? retObj.calcTaktTime.timeAvail / retObj.calcTaktTime.totalDem
      : 0;

  // CALCULATE PRODUCTS TIMING STATS
  const endMilli = endSec * 1000;
  const startMilli = startSec * 1000;
  products.forEach(prod => {
    if (!retObj.productsStatsObj[prod.id]) {
      retObj.productsStatsObj[prod.id] = {
        faciData: {},
        faciOpeData: {},
        listActions: [],
        manufLeadTime: { tot: 0, nbProducts: 0 },
        template_id: prod.template_id
      };
    }
    if (!retObj.productsStatsObj[prod.template_id]) {
      retObj.productsStatsObj[prod.template_id] = {
        faciData: {},
        faciOpeData: {},
        manufLeadTime: { tot: 0, nbProducts: 0 }
      };
    }
    if (
      prod.fulfilled_at &&
      prod.stats.moves &&
      prod.stats.moves[0] &&
      (prod.stats.moves[0].fid === "200" || prod.stats.moves[0].fid === "100")
    ) {
      let manufLT =
        prod.fulfilled_at.seconds * 1000 -
        (prod.created_at ? prod.created_at.seconds : 0) * 1000;
      retObj.productsStatsObj[prod.id].manufLeadTime.tot += manufLT;
      retObj.productsStatsObj[prod.template_id].manufLeadTime.tot += manufLT;
      retObj.productsStatsObj["all"].manufLeadTime.tot += manufLT;
      retObj.productsStatsObj[prod.id].manufLeadTime.nbProducts += 1;
      retObj.productsStatsObj[prod.template_id].manufLeadTime.nbProducts += 1;
      retObj.productsStatsObj["all"].manufLeadTime.nbProducts += 1;
    }
    prod.stats.moves.forEach(mm => {
      // FILL IN THE STATS OBJ WITH PRODUCT DATA :
      if (!retObj.productsStatsObj[prod.id].faciData[mm.fid]) {
        retObj.productsStatsObj[prod.id].faciOpeData[mm.fid] = {
          countOpes: {}
        };
        retObj.productsStatsObj[prod.id].faciData[mm.fid] = {
          waitTime: 0,
          moveTime: 0,
          workdeskTime: 0,
          changeoverTime: 0,
          loadTime: 0
        };
      }
      retObj.productsStatsObj[prod.id].faciData[mm.fid].moveTime +=
        mm.move_duration || 0;
      if (mm.zone === "workdesk") {
        retObj.productsStatsObj[prod.id].faciData[
          mm.fid
        ].workdeskTime += mm.leave_at
          ? Math.min(endMilli, mm.leave_at.toMillis()) -
            Math.max(startMilli, mm.enter_at.toMillis())
          : endMilli - Math.max(startMilli, mm.enter_at.toMillis());
        mm.actions.forEach(mact => {
          if (mact.type === "load" || mact.type === "unload") {
            // Substract loading and unloading from processing time :
            retObj.productsStatsObj[prod.id].faciData[mm.fid].loadTime +=
              mact.duration;
            retObj.productsStatsObj[prod.id].faciData[mm.fid].workdeskTime -=
              mact.duration;
          }
          if (mact.type === "changeover") {
            // Substract changeover from processing time :
            retObj.productsStatsObj[prod.id].faciData[mm.fid].changeoverTime +=
              mact.duration;
            retObj.productsStatsObj[prod.id].faciData[mm.fid].workdeskTime -=
              mact.duration;
          }
          if (mact.type === "operation") {
            let statusLabel = mact.status ? mact.status : "Rework";
            if (
              !retObj.productsStatsObj[prod.id].faciOpeData[mm.fid].countOpes[
                statusLabel
              ]
            ) {
              retObj.productsStatsObj[prod.id].faciOpeData[mm.fid].countOpes[
                statusLabel
              ] = {
                nb: 0,
                list: []
              };
            }
            retObj.productsStatsObj[prod.id].faciOpeData[mm.fid].countOpes[
              statusLabel
            ].nb += 1;
            retObj.productsStatsObj[prod.id].faciOpeData[mm.fid].countOpes[
              statusLabel
            ].list.push(mact.opeid);
          }
        });
        retObj.productsStatsObj[prod.id].faciData[
          mm.fid
        ].workdeskTime = Math.max(
          0,
          retObj.productsStatsObj[prod.id].faciData[mm.fid].workdeskTime
        );
      } else {
        let calcWait = mm.leave_at
          ? Math.max(
              0,
              Math.min(endMilli, mm.leave_at.toMillis()) -
                Math.max(startMilli, mm.enter_at.toMillis())
            )
          : Math.max(
              0,
              endMilli - Math.max(startMilli, mm.enter_at.toMillis())
            );
        retObj.productsStatsObj[prod.id].faciData[mm.fid].waitTime += calcWait;
        if (calcWait > mm.move_duration) {
          retObj.productsStatsObj[prod.id].faciData[mm.fid].waitTime -=
            mm.move_duration;
        }
      }
    });
    // Copy product data stat into the "all" sum recap, and into each product template
    for (const [fid, metricsObj] of Object.entries(
      retObj.productsStatsObj[prod.id].faciData
    )) {
      if (!retObj.productsStatsObj["all"].faciData[fid]) {
        retObj.productsStatsObj["all"].faciOpeData[fid] = {
          countOpes: {},
          totOpes: 0
        };
        retObj.productsStatsObj["all"].faciData[fid] = {
          nbProducts: 0
        };
      }
      if (!retObj.productsStatsObj[prod.template_id].faciData[fid]) {
        retObj.productsStatsObj[prod.template_id].faciOpeData[fid] = {
          countOpes: {},
          totOpes: 0
        };
        retObj.productsStatsObj[prod.template_id].faciData[fid] = {
          nbProducts: 0
        };
      }
      retObj.productsStatsObj["all"].faciData[fid].nbProducts += 1;
      retObj.productsStatsObj[prod.template_id].faciData[fid].nbProducts += 1;
      // Count operations status
      Object.keys(
        retObj.productsStatsObj[prod.id].faciOpeData[fid].countOpes
      ).forEach(statusLabel => {
        if (
          !retObj.productsStatsObj["all"].faciOpeData[fid].countOpes[
            statusLabel
          ]
        ) {
          retObj.productsStatsObj["all"].faciOpeData[fid].countOpes[
            statusLabel
          ] = {
            nb: 0
          };
        }
        if (
          !retObj.productsStatsObj[prod.template_id].faciOpeData[fid].countOpes[
            statusLabel
          ]
        ) {
          retObj.productsStatsObj[prod.template_id].faciOpeData[fid].countOpes[
            statusLabel
          ] = { nb: 0, list: [] };
        }
        let nb =
          retObj.productsStatsObj[prod.id].faciOpeData[fid].countOpes[
            statusLabel
          ].nb;
        let list =
          retObj.productsStatsObj[prod.id].faciOpeData[fid].countOpes[
            statusLabel
          ].list;
        // increase the count of ope by status in all/template groups:
        retObj.productsStatsObj["all"].faciOpeData[fid].countOpes[
          statusLabel
        ].nb += nb;
        retObj.productsStatsObj[prod.template_id].faciOpeData[fid].countOpes[
          statusLabel
        ].nb += nb;
        // add to the list of opes:
        retObj.productsStatsObj[prod.template_id].faciOpeData[fid].countOpes[
          statusLabel
        ].list.push(...list);
        retObj.productsStatsObj["all"].faciOpeData[fid].totOpes += nb;
        retObj.productsStatsObj[prod.template_id].faciOpeData[
          fid
        ].totOpes += nb;
      });
      for (const [metricLabel, metricsValue] of Object.entries(metricsObj)) {
        if (!retObj.productsStatsObj["all"].faciData[fid][metricLabel]) {
          retObj.productsStatsObj["all"].faciData[fid][metricLabel] = 0;
        }
        if (
          !retObj.productsStatsObj[prod.template_id].faciData[fid][metricLabel]
        ) {
          retObj.productsStatsObj[prod.template_id].faciData[fid][
            metricLabel
          ] = 0;
        }
        // Add data on delivered products value-added and non-value-added times :
        if (
          prod.fulfilled_at &&
          prod.stats.moves &&
          prod.stats.moves[0] &&
          (prod.stats.moves[0].fid === "200" ||
            prod.stats.moves[0].fid === "100")
        ) {
          if (!retObj.productsStatsObj["all"].sumFaciData[metricLabel]) {
            retObj.productsStatsObj["all"].sumFaciData[metricLabel] = 0;
          }
          retObj.productsStatsObj["all"].sumFaciData[
            metricLabel
          ] += metricsValue;
        }
        retObj.productsStatsObj["all"].faciData[fid][
          metricLabel
        ] += metricsValue;
        retObj.productsStatsObj[prod.template_id].faciData[fid][
          metricLabel
        ] += metricsValue;
      }
    }
  });
  // Create table to show the stations data :
  for (const [facId, facData] of Object.entries(game.facilities)) {
    // PROCESS TIMING RECAP
    let facLine = {
      id: facId,
      name: facData.name,
      valTime: 0,
      avgProcTime: 0,
      qty: 0,
      pourcCapa: 0
    };
    if (retObj.productsStatsObj["all"].faciData[facId]) {
      facLine.qty += retObj.productsStatsObj["all"].faciData[facId].nbProducts;
      for (const [metricLabel, metricData] of Object.entries(
        retObj.productsStatsObj["all"].faciData[facId]
      )) {
        if (metricLabel === "workdeskTime") {
          facLine.valTime += metricData;
          retObj.capaTimeTable.valAddTot += metricData;
        }
      }
      facLine.avgProcTime = facLine.valTime / facLine.qty;
      retObj.capaTimeTable.nbProcStations += facLine.valTime ? 1 : 0;
      facLine.pourcCapa =
        facLine.valTime && retObj.totalGameDurationMilli
          ? facLine.valTime / retObj.totalGameDurationMilli
          : null;
    }
    if (facLine.valTime <= 0) {
      facLine.rowClass = "has-background-light is-italic";
    }
    retObj.capaTimeTable.data.push(facLine);
    // OPE STATUS RECAP
    let facOpeLine = {
      id: facId,
      name: facData.name,
      percSuccess: 0,
      totOpes: retObj.productsStatsObj["all"].faciOpeData[facId]
        ? retObj.productsStatsObj["all"].faciOpeData[facId].totOpes
        : 0
    };
    if (retObj.productsStatsObj["all"].faciOpeData[facId]) {
      for (const [metricLabel, metricData] of Object.entries(
        retObj.productsStatsObj["all"].faciOpeData[facId].countOpes
      )) {
        // if (metricLabel !== "Rework") {
        facOpeLine[metricLabel] = metricData.nb;
        if (!retObj.faciOpeStatus.tots[metricLabel]) {
          retObj.faciOpeStatus.tots[metricLabel] = 0;
        }
        retObj.faciOpeStatus.tots[metricLabel] += metricData.nb;
        retObj.faciOpeStatus.tots.all += metricData.nb;
        // }
      }
    }
    facOpeLine.percSuccess = facOpeLine.totOpes
      ? (facOpeLine.totOpes - (facOpeLine["Failed"] || 0)) / facOpeLine.totOpes
      : 0;
    facOpeLine.rowClass = facOpeLine.totOpes
      ? ""
      : "is-italic has-background-light";
    retObj.faciOpeStatus.data.push(facOpeLine);
  }
  retObj.faciOpeStatus.data.push({
    id: "tot",
    name: "Total",
    ...retObj.faciOpeStatus.tots,
    percSuccess: retObj.faciOpeStatus.tots.all
      ? (retObj.faciOpeStatus.tots.all -
          (retObj.faciOpeStatus.tots["Failed"] || 0)) /
        retObj.faciOpeStatus.tots.all
      : 0,
    totOpes: retObj.faciOpeStatus.tots.all,
    rowClass: "has-text-weight-bold has-background-warning-light"
  });

  // CALCULATE STOCK EVOLUTION STATS and TABLE
  // PER SECOND :
  const maxTimeSec = Math.min(
    10800,
    Math.ceil(endSec - startSec),
    game.params.consumerDemandParams.totalDuration * 60
  );
  for (let ii = 1; ii <= maxTimeSec; ii++) {
    let secObj = {};
    let incrSec = startSec + (ii + 1);
    retObj.stockEvolution.nbSec += 1;
    products.forEach(prod => {
      if (!retObj.stockEvolution.totalSec[prod.template_id]) {
        retObj.stockEvolution.totalSec[prod.template_id] = {
          faciTot: { all: 0 }
        };
      }
      prod.stats.moves.forEach(mm => {
        if (
          mm.enter_at &&
          mm.enter_at.seconds < incrSec &&
          ((mm.leave_at && mm.leave_at.seconds >= incrSec) || !mm.leave_at)
        ) {
          if (!secObj[prod.id]) {
            secObj[prod.id] = mm.fid;
            // If last minute condition - desactivated :
            // if (ii >= maxTime - 1) {
            // }
            if (
              !retObj.stockEvolution.totalSec[prod.template_id].faciTot[mm.fid]
            ) {
              retObj.stockEvolution.totalSec[prod.template_id].faciTot[
                mm.fid
              ] = 0;
            }
            if (!retObj.stockEvolution.totalSec["all"].faciTot[mm.fid]) {
              retObj.stockEvolution.totalSec["all"].faciTot[mm.fid] = 0;
            }
            retObj.stockEvolution.totalSec[prod.template_id].faciTot[
              mm.fid
            ] += 1;
            retObj.stockEvolution.totalSec["all"].faciTot[mm.fid] += 1;
            retObj.stockEvolution.totalSec[prod.template_id].faciTot[
              "all"
            ] += 1;
            retObj.stockEvolution.totalSec["all"].faciTot["all"] += 1;
          }
        }
      });
    });
    retObj.stockEvolution.tabSec.push(secObj);
  }

  //CALCULATE RECAP TABLE AND GAME PERF STATS
  Object.keys(game.params.productsTemplates).forEach(tid => {
    if (retObj.productsStatsObj[tid]) {
      // Calculate totals for each product template selected :
      let totalSales = 0;
      let totalGameRevenue = 0;
      let totalDefect = 0;
      let totalLate = 0;
      retObj.currentLeanGameOrders.forEach(order => {
        if (order.fulfilled_at) {
          totalGameRevenue += game.params.costTypes["sales"] * order.qty;
        }
        if (order.fulfilled_at && tid === order.template_id) {
          totalSales += order.qty;
          let fulMin = game.params.game_start_at
            ? Math.max(
                0,
                Math.ceil(
                  (order.fulfilled_at.seconds -
                    game.params.game_start_at.seconds) /
                    6
                ) / 10
              )
            : null;
          let isOnTime =
            fulMin < order.created_min + order.obj_lead_time ? true : false;
          if (!isOnTime) {
            totalLate += order.qty;
          }
          totalDefect += order.defectProducts || 0;
          if (isOnTime) {
            retObj.recapStatsTable.totalSalesOnTime += order.qty;
            retObj.recapStatsTable.totalOTIF +=
              order.qty - (order.defectProducts || 0);
          }
        }
      });
      retObj.recapStatsTable.totalSales += totalSales;

      if (game.params.costTypes["sales"]) {
        if (!linesObj["sales"]) {
          linesObj["sales"] = {
            name: `Total Revenue`,
            namedetail: "All products sold",
            qty: 0,
            unitProfit: game.params.costTypes["sales"],
            profit: 0,
            pourcSales: null,
            rowClass: "has-background-success-light has-text-weight-bold"
          };
        }
        linesObj["sales"].qty += totalSales;
        linesObj["sales"].profit += game.params.costTypes["sales"] * totalSales;
        retObj.recapStatsTable.totalRevenue +=
          game.params.costTypes["sales"] * totalSales;
      }
      if (game.params.costTypes["rawmaterial"]) {
        // VERSION COUNTING ALL RAW MATERIAL PURCHASED
        // if (!linesObj["rawmaterial"]) {
        //   linesObj["rawmaterial"] = {
        //     name: "Raw materials purchasing cost",
        //     namedetail: "per unit",
        //     qty: 0,
        //     unitProfit: game.params.costTypes["rawmaterial"],
        //     profit: 0,
        //     pourcSales: 0
        //   };
        // }
        // let nbRawMat = products.filter(prod => prod.template_id === tid).length;
        // linesObj["rawmaterial"].qty += nbRawMat;
        // linesObj["rawmaterial"].profit +=
        //   game.params.costTypes["rawmaterial"] * nbRawMat;
        // linesObj["rawmaterial"].pourcSales += totalGameRevenue
        //   ? linesObj["rawmaterial"].profit / totalGameRevenue
        //   : 0;
        if (!linesObj["rawmaterial"]) {
          linesObj["rawmaterial"] = {
            name: "Cost of goods sold",
            namedetail: "per unit sold",
            qty: 0,
            unitProfit: game.params.costTypes["rawmaterial"],
            profit: 0,
            pourcSales: 0
          };
        }
        linesObj["rawmaterial"].qty += totalSales;
        linesObj["rawmaterial"].profit +=
          game.params.costTypes["rawmaterial"] * totalSales;
        linesObj["rawmaterial"].pourcSales += totalGameRevenue
          ? (game.params.costTypes["rawmaterial"] * totalSales) /
            totalGameRevenue
          : 0;
      }
      // Labor cost, count only once (write only for the first product)
      if (game.params.costTypes["station"]) {
        if (!linesObj["station"]) {
          linesObj["station"] = {
            name: "Labor cost",
            namedetail: "per number of stations",
            qty: 0,
            pourcSales: 0,
            unitProfit: game.params.costTypes["station"],
            profit: 0
          };
          linesObj["station"].qty += Object.keys(game.facilities).length;
          linesObj["station"].profit +=
            game.params.costTypes["station"] *
            Object.keys(game.facilities).length;
          linesObj["station"].pourcSales += totalGameRevenue
            ? (game.params.costTypes["station"] *
                Object.keys(game.facilities).length) /
              totalGameRevenue
            : 0;
        }
      }
      if (game.params.costTypes["opes"]) {
        Object.keys(game.params.costTypes["opes"])
          .sort((ota, otb) => sortOpeType[ota] - sortOpeType[otb])
          .forEach(opeType => {
            if (!linesObj[opeType]) {
              linesObj[opeType] = {
                name: `${opeType} operations cost`,
                namedetail: "per operation",
                qty: 0,
                pourcSales: 0,
                unitProfit: game.params.costTypes["opes"][opeType],
                profit: 0
              };
            }
            Object.values(retObj.productsStatsObj[tid].faciOpeData).forEach(
              facOpe => {
                Object.entries(facOpe.countOpes).forEach(tabStatus => {
                  if (tabStatus[0] === opeType) {
                    linesObj[opeType].qty += tabStatus[1].nb;
                    linesObj[opeType].profit +=
                      tabStatus[1].nb * linesObj[opeType].unitProfit;
                    linesObj[opeType].pourcSales += totalGameRevenue
                      ? (tabStatus[1].nb * linesObj[opeType].unitProfit) /
                        totalGameRevenue
                      : 0;
                  }
                });
              }
            );
          });
      }
      if (game.params.costTypes["wip"]) {
        let averageStock =
          retObj.stockEvolution.totalSec[tid].faciTot["all"] /
          retObj.stockEvolution.nbSec;
        if (game.params.costTypes["fg"]) {
          averageStock = Math.round(averageStock);
          averageStock -=
            Math.round(
              (10 * (retObj.stockEvolution.totalSec[tid].faciTot["100"] || 0)) /
                retObj.stockEvolution.nbSec
            ) / 10;
        }
        if (!linesObj["wip"]) {
          linesObj["wip"] = {
            name: `WIP Inventory cost`,
            namedetail: "based on average stock",
            qty: 0,
            pourcSales: 0,
            unitProfit: game.params.costTypes["wip"],
            profit: 0
          };
        }
        linesObj["wip"].qty += Math.round(averageStock);
        linesObj["wip"].profit += game.params.costTypes["wip"] * averageStock;
        linesObj["wip"].pourcSales += totalGameRevenue
          ? (game.params.costTypes["wip"] * averageStock) / totalGameRevenue
          : 0;
      }
      if (game.params.costTypes["fg"]) {
        let averageStock = Math.round(
          (retObj.stockEvolution.totalSec[tid].faciTot["100"] || 0) /
            retObj.stockEvolution.nbSec
        );
        if (!linesObj["fg"]) {
          linesObj["fg"] = {
            name: `FG Inventory cost`,
            namedetail: "based on average stock in the warehouse",
            qty: 0,
            pourcSales: 0,
            unitProfit: game.params.costTypes["fg"],
            profit: 0
          };
        }
        linesObj["fg"].qty += averageStock;
        linesObj["fg"].profit += game.params.costTypes["fg"] * averageStock;
        linesObj["fg"].pourcSales += totalGameRevenue
          ? (game.params.costTypes["fg"] * averageStock) / totalGameRevenue
          : 0;
      }

      if (game.params.costTypes["latepenalty"]) {
        if (!linesObj["latepenalty"]) {
          linesObj["latepenalty"] = {
            name: `Late delivery to customer penalty`,
            namedetail: "per unit",
            qty: 0,
            unitProfit: game.params.costTypes["latepenalty"],
            profit: 0,
            pourcSales: 0,
            rowClass: "has-background-light"
          };
        }
        linesObj["latepenalty"].qty += totalLate;
        linesObj["latepenalty"].profit +=
          game.params.costTypes["latepenalty"] * totalLate;
        linesObj["latepenalty"].pourcSales += totalGameRevenue
          ? (game.params.costTypes["latepenalty"] * totalLate) /
            totalGameRevenue
          : 0;
      }
      if (game.params.costTypes["qualitypenalty"]) {
        if (!linesObj["qualitypenalty"]) {
          linesObj["qualitypenalty"] = {
            name: `Deffective delivery to customer penalty`,
            namedetail: "per unit",
            qty: 0,
            unitProfit: game.params.costTypes["qualitypenalty"],
            profit: 0,
            pourcSales: 0,
            rowClass: "has-background-light"
          };
        }
        linesObj["qualitypenalty"].qty += totalDefect;
        linesObj["qualitypenalty"].profit +=
          game.params.costTypes["qualitypenalty"] * totalDefect;
        linesObj["qualitypenalty"].pourcSales += totalGameRevenue
          ? (game.params.costTypes["qualitypenalty"] * totalDefect) /
            totalGameRevenue
          : 0;
      }
    }
  });
  let totExpenses = 0;
  Object.values(linesObj).forEach(ct => {
    if (ct.profit < 0) {
      totExpenses += ct.profit;
    }
    retObj.recapStatsTable.lines.push(ct);
    retObj.recapStatsTable.totalProfit += ct.profit;
  });
  // RECAP LINES :
  retObj.recapStatsTable.lines.push({
    name: `Total Expenses`,
    namedetail: "",
    qty: null,
    unitProfit: null,
    profit: totExpenses,
    pourcSales: retObj.recapStatsTable.totalRevenue
      ? totExpenses / retObj.recapStatsTable.totalRevenue
      : 0,
    rowClass: "has-background-danger-light has-text-weight-bold"
  });
  retObj.recapStatsTable.lines.push({
    name: `Net ${retObj.recapStatsTable.totalProfit >= 0 ? "Profit" : "Loss"}`,
    namedetail: "",
    qty: null,
    unitProfit: null,
    profit: retObj.recapStatsTable.totalProfit,
    pourcSales: retObj.recapStatsTable.totalRevenue
      ? retObj.recapStatsTable.totalProfit / retObj.recapStatsTable.totalRevenue
      : 0,
    rowClass: "has-background-warning-light is-size-4 has-text-weight-bold"
  });
  // retObj.recapStatsTable.lines.push({
  //   name: `OTIF Products Delivered`,
  //   namedetail: "On-time, in-full and conform",
  //   qty: retObj.recapStatsTable.totalOTIF,
  //   unitProfit: null,
  //   profit: null,
  //   pourcSales: retObj.recapStatsTable.totalSales
  //     ? retObj.recapStatsTable.totalProfit / retObj.recapStatsTable.totalSales
  //     : 0,
  //   rowClass: "has-background-warning-light is-size-5 has-text-weight-bold"
  // });
  retObj.loaded = true;
  return retObj;
};
