<template>
  <div class="play-wrapper">
    <section
      :style="{
        'background-color': custoLook.bodyBckColor
      }"
    >
      <component
        v-if="currentGame && stages[currentGame.type]"
        v-bind:is="stages[currentGame.type]"
        @editname="promptName"
      ></component>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BgStage from "@/components/bg/Play/BgStage.vue";
import LeanStage from "@/components/lean/Play/LeanStage.vue";

export default {
  name: "play",
  computed: {
    ...mapGetters([
      "currentGame",
      "loading",
      "custoLook",
      "authUser",
      "currentUser",
      "myFaci"
    ]),
    finished() {
      return this.currentGame ? this.currentGame.finished : false;
    }
  },
  watch: {
    finished(newVal) {
      if (newVal) {
        this.alertDebriefing();
      }
    }
  },
  mounted() {
    if (
      this.myFaci &&
      (!this.myFaci.playerName || this.myFaci.playerName === "")
    ) {
      this.promptName(true);
    }
  },
  methods: {
    alertDebriefing() {
      this.$buefy.dialog.alert({
        type: "is-success",
        size: "is-medium",
        message: "You reached the end of the game",
        confirmText: "Go to the Debriefing",
        onConfirm: () => {
          this.$router.push({
            name: "debrief",
            params: { id: this.currentGame.id }
          });
        }
      });
    },
    promptName(isFirstTime) {
      if (!this.myFaci) {
        return;
      }
      let val = this.myFaci.playerName
        ? this.myFaci.playerName
        : this.currentUser.playerName
        ? this.currentUser.playerName
        : this.currentUser.firstname
        ? this.currentUser.firstname
        : this.currentUser.name
        ? this.currentUser.name
        : null;
      this.$buefy.dialog.prompt({
        message: `What's your player name ?`,
        size: "is-medium",
        type: "is-info",
        inputAttrs: {
          placeholder: "Albus",
          maxlength: 20,
          required: false,
          value: val
        },
        confirmText: "OK",
        trapFocus: false,
        onCancel: () => {
          let newVal = this.myFaci.playerName ? this.myFaci.playerName : "";
          this.$store
            .dispatch("updateGameDoc", [
              {
                gid: this.currentGame.id,
                obj: {
                  [`facilities.${this.myFaci.id}.playerName`]: newVal
                }
              }
            ])
            .then(() => {
              this.$store.commit("setShowOnboarding", isFirstTime);
            });
        },
        onConfirm: value => {
          let newVal = value ? value : "";
          this.$store
            .dispatch("updateGameDoc", [
              {
                gid: this.currentGame.id,
                obj: {
                  [`facilities.${this.myFaci.id}.playerName`]: newVal
                }
              }
            ])
            .then(() => {
              this.$store.dispatch("updateUserProfile", {
                uid: this.currentUser.uid,
                playerName: newVal
              });
              this.$store.commit("setShowOnboarding", isFirstTime);
            });
        }
      });
    }
  },
  data() {
    return {
      stages: {
        leangame: LeanStage,
        beergame: BgStage,
        bgadvanced: BgStage,
        bgpremium: BgStage
      }
    };
  }
};
</script>

<style lang="scss">
.play-wrapper {
  overflow-x: hidden;
}
</style>
