var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentFacilities)?_c('div',{staticClass:"stage-wrapper"},[_c('bg-recap-box'),_c('section',{staticClass:"pt-2"},[(_vm.isCreator)?_c('div',{staticClass:"container mb-2"},[(_vm.myFaci)?_c('b-field',[_c('b-checkbox',{staticClass:"has-text-link pl-5",attrs:{"type":"is-link"},model:{value:(_vm.showStudent),callback:function ($$v) {_vm.showStudent=$$v},expression:"showStudent"}},[_vm._v(" See the game stage as a student ")])],1):_vm._e()],1):_vm._e(),(_vm.currentGame.finished)?_c('div',{staticClass:"container mb-2 has-text-centered"},[_c('p',{staticClass:"is-italic is-size-4"},[_vm._v(" This game is finished. ")]),_c('p',{staticClass:"mt-3 mb-6"},[_c('b-button',{style:({
            'background-color': _vm.custoLook.btnBestColor,
            color: 'white'
          }),attrs:{"size":"is-medium","type":"is-success","rounded":"","tag":"router-link","to":'/game/' + _vm.currentGame.id + '/results',"icon-left":"chart-line"}},[_vm._v(" See the results ")])],1)]):_vm._e(),_c('div',{staticClass:"columns is-marginless is-paddingless is-centered is-multiline is-desktop mb-4"},_vm._l((_vm.currentFacilities),function(faci){return _c('facility',{key:faci.id,ref:("faci-" + (faci.id)),refInFor:true,attrs:{"faci":faci},on:{"editname":function($event){return _vm.$emit('editname')}}})}),1)]),_c('div',{staticClass:"container pl-3"},[_c('h5',{staticClass:"title is-4 mb-3"},[_vm._v("Statistics")]),(_vm.showAllData)?_c('div',{staticClass:"buttons mb-3"},[_c('button',{staticClass:"button is-dark",style:({
          'background-color': _vm.showStatsTotal
            ? _vm.custoLook.btnBestColor
            : 'hsl(48, 100%, 67%)',
          color: _vm.showStatsTotal ? 'white' : 'hsl(0, 0%, 21%)'
        }),on:{"click":function($event){return _vm.filterFaci('total')}}},[_vm._v(" Game total ")]),_vm._l((_vm.currentFacilities),function(faci){return _c('button',{key:faci.id,staticClass:"button",style:({
          'background-color':
            _vm.showStatsFaci.includes(faci.id) && !_vm.showStatsTotal
              ? _vm.custoLook.btnBestColor
              : 'white',
          color:
            _vm.showStatsFaci.includes(faci.id) && !_vm.showStatsTotal
              ? 'white'
              : 'hsl(0, 0%, 21%)'
        }),on:{"click":function($event){return _vm.filterFaci(faci.id)}}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(faci.name,{ onlyFirstLetter: true }))+" ")])})],2):_vm._e()]),_c('div',{staticClass:"container box"},[_c('select-metrics',{attrs:{"game":_vm.currentGame,"start-with":_vm.baseMetrics},model:{value:(_vm.checkMetrics),callback:function ($$v) {_vm.checkMetrics=$$v},expression:"checkMetrics"}})],1),_c('stats-section',{attrs:{"show-details":true,"show-faci-tab":_vm.showStatsFaci,"show-total":_vm.showStatsTotal,"custom-metrics":_vm.checkMetrics,"game":_vm.currentGame,"show-legend":true}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }