<template>
  <div class="faci-orders-panel-wrapper faci-content">
    <div class="columns title-columns is-mobile">
      <div class="column">
        <h3
          :class="{ 'has-text-weight-semibold': myFaci }"
          v-if="myOrders.length"
        >
          Planned orders :
        </h3>
      </div>
    </div>

    <transition-group name="orders-list" tag="ul">
      <li
        v-for="order in myOrders"
        class="orders-list-item"
        v-bind:key="order.id"
      >
        <a
          class="order-link has-text-dark btn-play-orderdetails"
          :class="{
            'has-text-grey': !isVisible(order),
            'is-italic': !isVisible(order)
          }"
          @click="clickOrder(order)"
        >
          <b-tooltip
            type="is-dark"
            label="See order detail"
            class="is-inline-block pr-1"
            position="is-bottom"
          >
            <b-icon
              size="is-small"
              v-if="order.orderWeek !== currentGame.week"
              :icon="order.type === 'production' ? 'wrench' : 'truck'"
            ></b-icon>
            <strong v-if="isVisible(order)"
              ><span
                v-if="order.orderQty !== order.shipQty"
                class="strikeout mr-1"
                >+{{ order.orderQty }}</span
              >+{{ order.shipQty }}</strong
            >
            <b-icon
              size="is-small"
              v-if="order.orderWeek === currentGame.week"
              icon="email"
            ></b-icon>
            <em v-if="!isVisible(order)" class="mr-1">+{{ order.orderQty }}</em>
            <span v-if="!isVisible(order) && order.type !== 'production'"
              >(tbc)
            </span>
            in
            <strong>{{ order.remainingTime }}</strong>
            {{ currentGame.params.timeUnit || "week"
            }}{{ order.remainingTime > 1 ? "s" : "" }}
          </b-tooltip>
          <b-tooltip
            v-if="!isVisible(order) && order.type !== 'production'"
            style="cursor: help;"
            type="is-dark"
            multilined
            label="You don't have visibility yet on the actual quantity being delivered"
            position="is-bottom"
          >
            <b-icon size="is-small" icon="help-circle-outline"></b-icon>
          </b-tooltip>
        </a>
        <br />
      </li>
    </transition-group>
    <b-modal v-if="showOrder" :active.sync="isModalActive" :width="'500px'">
      <div class="container-center is-size-6">
        <b-notification
          :active.sync="isModalActive"
          aria-close-label="Close panel"
        >
          <h5 class="title is-5" v-if="showOrder.type">
            {{ showOrder.type | capitalize({ onlyFirstLetter: true }) }}
            Order detail :
          </h5>
          <ul class="columns is-mobile">
            <li class="column">
              <strong>Order Qty : </strong
              ><span class="is-italic has-text-grey"
                >{{ showOrder.orderQty }} units</span
              >
            </li>
            <li class="column">
              <strong>Actual Qty : </strong>
              <span
                v-if="isVisible(showOrder)"
                :class="{
                  'has-text-danger': showOrder.shipQty < showOrder.orderQty,
                  'has-text-grey': showOrder.shipQty >= showOrder.orderQty
                }"
                >{{ showOrder.shipQty }} units</span
              ><span
                class="is-italic has-text-grey"
                v-if="!isVisible(showOrder)"
                >TBC</span
              >
            </li>
          </ul>
          <ul
            class="columns is-mobile is-size-7 is-italic has-text-grey"
            v-if="
              showOrder.shipQty > showOrder.orderQty && isVisible(showOrder)
            "
          >
            <li class="column">
              Your supplier is shipping some backorder quantities from one of
              your previous orders.
            </li>
          </ul>
          <ul
            class="columns is-mobile is-size-7 is-italic has-text-grey"
            v-if="
              showOrder.shipQty < showOrder.orderQty && isVisible(showOrder)
            "
          >
            <li class="column">
              Your supplier will ship missing quantities as soon as he receives
              extra stock.
            </li>
          </ul>
          <ul class="columns is-mobile" v-if="showOrder.type !== 'production'">
            <li class="column" v-if="showOrder.to">
              <strong>To : </strong
              ><span class="has-text-grey">{{ showOrder.toName }}</span>
            </li>
            <li class="column" v-if="showOrder.from">
              <strong>From : </strong
              ><span class="has-text-grey">{{ showOrder.fromName }}</span>
            </li>
          </ul>
          <ul class="columns is-mobile">
            <li class="column" v-if="showOrder.dueWeek">
              <strong>Due {{ currentGame.params.timeUnit || "week" }} </strong>
              <span class="has-text-grey"
                >{{ showOrder.dueWeek }}
                <span class="is-italic"
                  >(in {{ showOrder.remainingTime }}
                  {{ currentGame.params.timeUnit || "week"
                  }}{{ showOrder.remainingTime > 1 ? "s" : "" }})</span
                ></span
              >
            </li>
            <li class="column" v-if="showOrder.orderWeek">
              <strong
                >Order {{ currentGame.params.timeUnit || "week" }}
              </strong>
              <span class="has-text-grey">{{ showOrder.orderWeek }} </span>
            </li>
          </ul>
        </b-notification>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "FaciOrdersPanel",
  props: { faci: Object, myFaci: Boolean },
  computed: {
    ...mapGetters([
      "currentFacilities",
      "currentGame",
      "currentOrders",
      "showAllData"
    ]),
    myOrders() {
      if (!this.currentOrders) {
        return [];
      }
      return this.currentOrders
        .filter(order => {
          return (
            order.to == this.faci.id &&
            order.ongoing &&
            order.dueWeek > this.currentGame.week &&
            (order.orderQty > 0 || order.shipQty > 0)
          );
        })
        .map(order => {
          let faciTo = this.currentFacilities.find(faci => {
            return order.to && faci.id == order.to;
          });
          let faciFrom = this.currentFacilities.find(faci => {
            return order.from && faci.id == order.from;
          });
          return {
            ...order,
            id: order.id,
            fromName: faciFrom ? faciFrom.name : null,
            toName: faciTo ? faciTo.name : null,
            remainingTime: order.dueWeek - this.currentGame.week
          };
        })
        .sort((a, b) => {
          return a.remainingTime - b.remainingTime;
        });
    }
  },
  methods: {
    isVisible(order) {
      return (
        order.orderWeek != this.currentGame.week &&
        (order.type === "production" ||
          this.currentGame.params.transparent ||
          this.showAllData ||
          order.remainingTime <= this.faci.visibleWeeks)
      );
    },
    clickOrder(order) {
      this.showOrder = order;
      this.isModalActive = true;
    }
  },
  data() {
    return {
      isModalActive: false,
      showOrder: null
    };
  }
};
</script>

<style lang="scss">
.faci-orders-panel-wrapper {
  @media (max-width: 767px) {
    padding-top: 0;
  }
  padding-left: 5%;
  .title-columns {
    margin: 0;
    padding: 0;
    .column {
      margin: 0;
      padding: 0;
    }
  }
  .order-link {
    display: inline-block;
    padding: 0.1em 0.6em;
    border-radius: 0.3em;
  }
  .order-link:hover {
    background-color: hsla(0, 0%, 21%, 0.1);
  }
  .orders-list-move {
    transition: transform 2s;
  }
  .orders-list-enter {
    opacity: 0;
    transform: translateX(80px);
  }
  .orders-list-leave-to {
    opacity: 0;
    color: green;
    transform: translateY(-30px);
  }
  // .orders-list-leave-active {
  //   position: absolute;
  // }
  .orders-list-item {
    transition: all 500ms;
    display: block;
  }
  .modal-content .notification {
    // max-width: 500px;
    margin: 0 auto;
    .ml5 {
      margin-left: 5px;
    }
    .modal-separator {
      border-bottom: solid 1px grey;
      margin: 0.5em 20%;
    }
    ul.columns {
      margin: 0;
      .column {
        padding: 2px;
      }
    }
  }
  .icon {
    opacity: 0.6;
    margin-right: 5px;
  }
}
</style>
