import { Bar } from "vue-chartjs";
import tinycolor from "tinycolor2";
import { mapGetters } from "vuex";

export default {
  name: "StockLeanLineChart",
  extends: Bar,
  //   mixins: [reactiveProp],
  props: {
    stats: Array,
    perSecond: Boolean,
    filterFaciTab: Array,
    game: Object,
    average: Number,
    step: Number
  },
  methods: {
    launchUpdate() {
      this.renderChart(this.chartData, this.options);
    }
  },
  computed: {
    ...mapGetters(["leanParams"]),
    chartData() {
      if (!this.stats) {
        return {};
      }
      let faciObj = {};
      let secLabels = [];
      this.stats.forEach(secObj => {
        Object.values(secObj).forEach(fid => {
          if (
            !faciObj[fid] &&
            (!this.filterFaciTab || this.filterFaciTab.includes(fid))
          ) {
            faciObj[fid] = { tab: [], name: this.game.facilities[fid].name };
          }
        });
      });
      let incrSec = 0;
      let incrObj = {};
      this.stats.forEach((secObj, secInd) => {
        Object.keys(faciObj).forEach(fid => {
          Object.values(secObj).forEach(secFid => {
            if (secFid === fid) {
              if (!incrObj[fid]) {
                incrObj[fid] = 0;
              }
              incrObj[fid] += 1;
            }
          });
        });
        incrSec += 1;
        if (incrSec >= this.step) {
          Object.keys(faciObj).forEach(fid => {
            faciObj[fid].tab.push(
              Math.round((10 * (incrObj[fid] || 0)) / this.step) / 10
            );
          });
          secLabels.push(this.$options.filters.minsec((secInd + 1) * 1000));
          incrSec = 0;
          incrObj = {};
        }
      });
      let dataSets = [];
      let colorSet = tinycolor("#000").monochromatic(
        Object.values(faciObj).length
      );
      if (this.average) {
        dataSets.push({
          label: "Average inventory",
          backgroundColor: "transparent",
          pointBorderColor: "transparent",
          borderColor: "brown",
          borderDash: [20, 5],
          data: secLabels.map(() => this.average),
          type: "line"
        });
      }
      Object.values(faciObj).forEach((faciObjOne, ind) => {
        //Try to find existing color for faci name :
        let colorMix = null;
        faciObjOne.name
          .toLowerCase()
          .split(" ")
          .forEach(word => {
            Object.values(this.leanParams.availColors).forEach(col => {
              if (col.colorName.toLowerCase() === word) {
                colorMix = tinycolor(col.colorHex);
              }
            });
          });
        dataSets.push({
          label: faciObjOne.name,
          data: faciObjOne.tab,
          backgroundColor: colorMix
            ? tinycolor.mix(colorSet[ind], colorMix, 70).toHexString()
            : colorSet[ind].toHexString(),
          stack: 0
        });
      });
      return {
        labels: secLabels,
        datasets: dataSets
      };
    },
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: { display: true },
        tooltips: {
          mode: "label",
          enabled: true,
          callbacks: {
            label(tooltipItem, data) {
              var label = data.datasets[tooltipItem.datasetIndex].label || "";
              if (!tooltipItem.yLabel) {
                return;
              }
              if (label) {
                label += ": ";
              }
              label += tooltipItem.yLabel;
              return data.datasets[tooltipItem.datasetIndex].backgroundColor !==
                "transparent"
                ? label
                : "";
            },
            footer: (tooltipHover, data) => {
              let faciRank = tooltipHover[0].index;
              let tot = 0;
              data.datasets.forEach(ds => {
                if (ds.backgroundColor !== "transparent") {
                  tot += ds.data[faciRank] || 0;
                }
              });
              return "Total: " + Math.round(10 * tot) / 10 + "pcs";
            }
          }
        },
        scales: {
          yAxes: [
            {
              display: true,
              stacked: true,
              labelString: "WIP Stock (pcs)"
            }
          ]
        }
      };
    }
  },
  watch: {
    filterFaciTab() {
      this.renderChart(this.chartData, this.options);
    }
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  }
};
