<template>
  <b-carousel
    class="lean-student-onboarding is-size-6-mobile"
    ref="leancarouselwrapper"
    :autoplay="false"
    :arrow-hover="false"
    :repeat="false"
    :arrow="true"
    :progress="true"
    progress-type="is-primary"
    v-model="slideRank"
  >
    <b-carousel-item>
      <section class="hero is-medium is-white">
        <div class="hero-body has-text-centered">
          <h1 class="title is-size-4-mobile mb-3">
            Welcome {{ faci ? faci.playerName : "" }}!
          </h1>
          <b-notification
            :closable="false"
            class="mt-2"
            v-if="
              !faci || !faci.custoOnboarding || !faci.custoOnboarding.length
            "
          >
            This game simulates a workshop.
            <div v-if="faci">
              You'll play as an operator in the
              <b>{{ faci.name }}</b> station.
            </div>
            <div v-if="!faci">
              You are playing as <b>observer</b>, you'll be able to watch what
              happens.
            </div>
          </b-notification>
          <article
            class="message"
            v-if="faci && faci.custoOnboarding && faci.custoOnboarding.length"
          >
            <div
              class="message-body has-text-grey-darker"
              style="white-space:pre-wrap"
            >
              {{ faci.custoOnboarding }}
            </div>
          </article>
          Your goal is to produce and sell
          {{ Object.keys(currentGame.params.productsTemplates).length }}
          products:
          <div class="columns mt-1">
            <div
              class="column has-text-centered"
              :key="temp.template_id"
              v-for="temp in Object.values(
                currentGame.params.productsTemplates
              ).sort((a, b) => {
                return a.template_id.localeCompare(b.template_id);
              })"
            >
              <div class="mx-6 px-6 mt-2">
                <component
                  v-bind:is="leanProductFigures[temp.type]"
                  :product="temp"
                  :station="null"
                  :show-guides="false"
                  :show-finished="true"
                  :is-disabled="true"
                  :is-template="true"
                ></component>
              </div>
              <div>
                <b>{{ temp.name }}</b>
              </div>
            </div>
          </div>
        </div>
      </section>
    </b-carousel-item>
    <b-carousel-item>
      <section class="hero is-medium is-white">
        <div class="hero-body has-text-centered">
          <h1 class="title is-size-4-mobile mb-3">
            Workstations
          </h1>
          Each station can be played by a participant or by the computer:
          <b-notification :closable="false" class="mt-2">
            <div class="columns is-centered">
              <div class="column is-11 has-text-left content">
                <ul>
                  <li
                    v-for="fac in Object.values(stationsDescription)"
                    :key="fac.id"
                  >
                    <b> {{ fac.name }}: </b>
                    <span>{{ fac.desc }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </b-notification>
          <div class="mt-2 is-size-5">
            <em>
              Units will start from the
              {{ Object.values(stationsDescription)[0].name }} then move through
              the other stations.
            </em>
          </div>
        </div>
      </section>
    </b-carousel-item>
    <b-carousel-item>
      <section class="hero is-medium is-white">
        <div class="hero-body has-text-centered">
          <h1 class="title is-size-4-mobile mb-3">
            Objectives
          </h1>
          The <b>{{ finalStation.name }}</b> will receive customer orders
          regularly during a
          <b
            >{{
              currentGame.params.consumerDemandParams.totalDuration
            }}-minutes</b
          >
          period.
          <br />
          As a team, you must try to produce and ship all the required units
          <b>on-time</b>, with <b>good quality</b>, and at the
          <b>lowest operating costs</b>. <br /><br />
          At the end, to evaluate your performance, you'll see a financial
          summary of the game.
          <div class="is-size-5 my-2">
            Key Performance Indicator: the <b>Profit</b> (<b
              class="has-text-success"
              >Revenue</b
            >
            - <b class="has-text-danger">Costs</b>)
          </div>
          <div class="is-italic has-text-centered">
            Detailed sources of cost / revenue:
          </div>
          <b-notification :closable="false" class="mt-2">
            <lean-config-costs
              :game="currentGame"
              :disable="true"
              :recap="true"
            ></lean-config-costs>
          </b-notification>
        </div>
      </section>
    </b-carousel-item>
    <b-carousel-item>
      <section class="hero is-medium is-white pb-6">
        <div class="hero-body has-text-centered pb-0">
          <h1 class="title is-size-4-mobile mb-3">
            Your role: {{ faci ? faci.name : "Observer" | capitalize }}
          </h1>

          <div v-if="!faci" class="content">
            <ul
              class="has-text-left"
              style="margin-left: 15%;margin-right: 10%;"
            >
              <li>
                Click on the various stations while the game is ongoing to see
                what happens.
              </li>
              <li>
                Try to identify potential issues in the process, lack of
                efficiency, and wastes.
              </li>
              <li>
                Thinks of ways we could improve the operations to better serve
                the customers !
              </li>
            </ul>
          </div>
          <div v-if="faci" class="content">
            <img
              class="mt-2"
              style="margin-left: 15%;margin-right: 15%; width:70%; border: solid 1px grey"
              :src="faciScreen[faci.type]"
            />
            <ul
              v-if="faci.type === 'painting'"
              class="has-text-left"
              style="margin-left: 10%;margin-right: 5%;"
            >
              <li>
                You'll receive units to be processed from the left
                (<b>WIP-in</b>). You'll then be able to load them on your
                <b>workdesk</b> in the center.
              </li>
              <li
                v-if="faci.params.availColors && faci.params.availColors.length"
              >
                Select the right color/shape and click at the center of the dots
                to paint them. Beware, if you don't click at the right position,
                you'll create a defect.
              </li>
              <li v-if="faci.params && faci.params.stampRemove">
                Click on the defectuous dots (they have a red border) to remove
                the paint.
              </li>
              <li>
                When you have processed a unit, move it to the completed pile
                (<b>WIP-out</b>).
              </li>
              <li>
                Once you have processed enough units of a particular product
                (Batch sizes:
                <em
                  :key="`batchsize-${temp.template_id}`"
                  v-for="(temp, ind) in Object.values(
                    currentGame.params.productsTemplates
                  ).sort((a, b) => {
                    return a.template_id.localeCompare(b.template_id);
                  })"
                  ><span v-if="ind > 0">, </span>{{ temp.name }}:
                  <b>{{ temp.batchSize }}</b></em
                >), you can send them to the next station.
              </li>
            </ul>
            <ul
              v-if="faci.type === 'inventoryShipping'"
              class="has-text-left"
              style="margin-left: 10%;margin-right: 5%;"
            >
              <li>
                When the game has started, you'll be able to see incoming
                <b>Customer Orders</b> in the right panel of your interface.
              </li>
              <li>
                Launch the corresponding product templates in production from
                the left panel (<b>Raw Material Templates</b>).
              </li>
              <li>
                Once the stations in the workshop have finished processing some
                units, they will arrive in the middle (<b>Finished Good Stock</b
                >).
              </li>
              <li>
                You can then use you stock to fulfill the orders on the right,
                by clicking their blue button
                <b>Fulfill</b>.
              </li>
            </ul>
          </div>
          <div class="buttons is-centered mt-2 are-large">
            <b-button
              rounded
              type="is-primary"
              class="btn-play-onboard-next"
              @click="$emit('closeonboarding')"
              ref="nextbtn"
              :style="{
                'background-color': custoLook.btnBestColor,
                color: 'white'
              }"
            >
              Let's go
            </b-button>
          </div>
        </div>
      </section>
    </b-carousel-item>
  </b-carousel>
</template>

<script>
import { mapGetters } from "vuex";
import LeanConfigCosts from "@/components/lean/Create/LeanConfigCosts.vue";

export default {
  name: "LeanStudentOnboarding",
  components: {
    LeanConfigCosts
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "currentGame",
      "currentFacilities",
      "custoLook",
      "leanProductFigures"
    ]),
    faci() {
      if (
        this.currentUser &&
        this.currentUser.playingGame &&
        this.currentUser.playingGame.id === this.currentGame.id
      ) {
        return this.currentGame.facilities[this.currentUser.playingGame.roleId];
      }
      return null;
    },
    finalStation() {
      let obj = {};
      this.currentFacilities.forEach(fac => {
        if (fac.type === "inventoryShipping") {
          obj = fac;
        }
      });
      return obj;
    },
    stationsDescription() {
      let obj = {};
      this.currentFacilities.forEach((fac, ind) => {
        let desc = "";
        let descPaint = null;
        if (
          fac.params.availColors &&
          fac.params.availColors.length &&
          fac.params.availShapes &&
          fac.params.availShapes.length
        ) {
          descPaint = `color${
            fac.params.availColors.length > 1 ? "s" : ""
          }: ${fac.params.availColors.join(", ")} and shape${
            fac.params.availShapes.length > 1 ? "s" : ""
          }: ${fac.params.availShapes.join(", ")}`;
        }
        if (fac.type === "inventoryShipping") {
          desc =
            "Sends raw materials to the first painting station, and delivers finished goods to the customers based on their orders.";
        } else if (fac.type === "painting") {
          if (fac.params.stampRemove && !descPaint) {
            desc = "Inspects and removes potential defectuous dots.";
          } else if (descPaint && !fac.params.stampRemove) {
            if (fac.name.toLowerCase().indexOf("rework") >= 0) {
              desc = `Rework dots of ${descPaint}.`;
            } else {
              desc = `Paints dots of ${descPaint}.`;
            }
          } else if (descPaint && fac.params.stampRemove) {
            if (fac.name.toLowerCase().indexOf("rework") >= 0) {
              desc = `Inspects dots, and if needed corrects the incorrect ones for ${descPaint}.`;
            } else {
              desc = `Paints dots of ${descPaint}. Able to correct his work if needed.`;
            }
          }
        }
        obj[fac.id] = {
          rank: ind,
          ...fac,
          desc: desc
        };
      });
      return obj;
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.$refs.nextbtn && this.$refs.nextbtn.$el) {
        this.$refs.nextbtn.$el.focus();
      }
      if (this.$refs.leancarouselwrapper) {
        this.$refs.leancarouselwrapper.$el.scrollIntoView({
          behavior: "smooth"
        });
      }
    }, 2);
  },
  methods: {
    nextSlide() {
      if (this.slideRank >= this.nbSlides - 1) {
        this.$store.commit("setShowOnboarding", false);
      } else {
        this.slideRank += 1;
        if (this.$refs.leancarouselwrapper) {
          this.$nextTick(() => {
            this.$refs.leancarouselwrapper.$el.scrollIntoView({
              behavior: "smooth"
            });
          });
        }
      }
    },
    prevSlide() {
      if (this.slideRank > 0) {
        this.slideRank -= 1;
      }
    }
  },
  data() {
    return {
      slideRank: 0,
      nbSlides: 10,
      faciScreen: {
        inventoryShipping: require("@/assets/game/lean-onboarding-warehouse.png"),
        painting: require("@/assets/game/lean-onboarding-painting.png")
      }
    };
  }
};
</script>

<style lang="scss">
.lean-student-onboarding {
  .hero-body {
    padding-top: 2em !important;
    padding-bottom: 4em !important;
    &.pb-0 {
      padding-bottom: 0 !important;
    }
    @media (max-width: 767px) {
      padding-top: 1em !important;
      padding-bottom: 1em !important;
    }
  }
}
</style>
