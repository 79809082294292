<template>
  <custom-chart
    :style="myStyle"
    class="faci-chart"
    v-if="chartdata"
    ref="myCustomChart"
    :chart-data="chartdata"
    :options="options"
  ></custom-chart>
</template>

<script>
import CustomChart from "@/components/bg/Stats/CustomChart.js";

export default {
  name: "FaciChart",
  props: {
    stats: Object,
    myStyle: Object,
    currency: String,
    timeUnit: String,
    metrics: Array,
    maxPcs: Number,
    maxVal: Number,
    showLegend: Boolean
  },
  components: {
    CustomChart
  },
  computed: {
    chartdata() {
      if (!this.stats) {
        return false;
      }
      let retObj = {
        labels: this.stats["week"].map(week => {
          return week;
        }),
        datasets: []
      };
      if (this.metrics.includes("stock")) {
        retObj.datasets.push({
          label: "Stock",
          backgroundColor: "transparent",
          borderColor: "#2e3192",
          data: this.stats["stock"],
          yAxisID: "left-y-axis",
          type: "line"
        });
      }
      if (this.metrics.includes("backlog")) {
        retObj.datasets.push({
          label: "Backorder",
          backgroundColor: "transparent",
          borderColor: "red",
          data: this.stats["backlog"],
          yAxisID: "left-y-axis",
          type: "line"
        });
      }
      if (this.metrics.includes("order")) {
        retObj.datasets.push({
          label: "Order",
          backgroundColor: "#06BD98",
          data: this.stats["order"],
          yAxisID: "left-y-axis"
        });
      }
      if (this.metrics.includes("demand")) {
        retObj.datasets.push({
          label: "Demand",
          backgroundColor: "#fbacad",
          data: this.stats["demand"],
          yAxisID: "left-y-axis"
        });
      }
      if (this.metrics.includes("delivery")) {
        retObj.datasets.push({
          label: "Receipt",
          backgroundColor: "#D3D3D3",
          data: this.stats["delivery"],
          yAxisID: "left-y-axis"
        });
      }
      if (this.metrics.includes("shipment")) {
        retObj.datasets.push({
          label: "Shipment",
          backgroundColor: "#86B9DB",
          data: this.stats["shipment"],
          yAxisID: "left-y-axis"
        });
      }
      if (this.metrics.includes("missed")) {
        retObj.datasets.push({
          label: "Missed Sales",
          backgroundColor: "#F63C3F",
          data: this.stats["missed"],
          yAxisID: "left-y-axis"
        });
      }
      if (this.metrics.includes("retailerDemand")) {
        retObj.datasets.push({
          label: "Final Consumer Demand",
          backgroundColor: "#ff7476",
          data: this.stats["retailerDemand"],
          yAxisID: "left-y-axis"
        });
      }
      if (this.metrics.includes("cost")) {
        retObj.datasets.push({
          label: "Cost",
          backgroundColor: "#FFBABB80",
          // pointBackgroundColor: "#E6E6AC",
          pointBorderColor: "#FFBABB",
          borderColor: "transparent",
          data: this.stats["cost"],
          yAxisID: "right-y-axis",
          type: "line"
        });
      }
      if (this.metrics.includes("revenue")) {
        retObj.datasets.push({
          label: "Revenue",
          backgroundColor: "#99D5FF80",
          borderColor: "transparent",
          data: this.stats["revenue"],
          yAxisID: "right-y-axis",
          type: "line"
        });
      }
      return retObj;
    },
    options() {
      let retObj = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: "top",
          display: this.showLegend
        },
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: this.$options.filters.capitalize(this.timeUnit)
              }
            }
          ],
          yAxes: [
            {
              id: "left-y-axis",
              type: "linear",
              position: "left",
              scaleLabel: {
                display: false,
                labelString: "Units"
              },
              ticks: {
                suggestedMin: 0,
                suggestedMax: this.maxPcs,
                callback: value => {
                  return this.$options.filters.currency(value, "");
                }
              }
            }
          ]
        }
      };
      if (this.metrics.includes("cost") || this.metrics.includes("revenue")) {
        retObj.scales.yAxes.push({
          id: "right-y-axis",
          type: "linear",
          position: "right",
          scaleLabel: {
            display: false,
            labelString: "Value"
          },
          ticks: {
            suggestedMin: 0,
            suggestedMax: this.maxVal,
            callback: value => {
              return this.$options.filters.currency(value, this.currency);
            }
          }
        });
      }
      return retObj;
    }
  }
};
</script>

<style lang="scss">
.faci-chart {
  margin-top: 1em;
}
</style>
