import { Doughnut, mixins } from "vue-chartjs";
import { mapGetters } from "vuex";
const { reactiveProp } = mixins;
function formatTime(millis) {
  let minutes = Math.max(0, Math.floor(millis / (60 * 1000)));
  let seconds = Math.max(
    0,
    Math.floor((10 * (millis - minutes * 60000)) / 1000) / 10
  );
  return (
    (minutes < 10 ? "0" + minutes : minutes) +
    ":" +
    (seconds < 10 ? "0" + seconds : seconds)
  );
}

export default {
  extends: Doughnut,
  mixins: [reactiveProp],
  props: {
    chartData: Object,
    sumstats: Object,
    nbproducts: Number
  },
  computed: {
    ...mapGetters(["leanParams"]),
    chartdata() {
      let sortMetrics = Object.keys(this.sumstats)
        .filter(met => this.leanParams.allMetricsParams[met])
        .map(met => {
          return { ...this.leanParams.allMetricsParams[met], id: met };
        })
        .sort((mpa, mpb) => mpa.rank - mpb.rank);
      return {
        labels: sortMetrics.map(met => met.label),
        datasets: [
          {
            backgroundColor: sortMetrics.map(met => met.color),
            data: sortMetrics.map(met =>
              this.nbproducts ? this.sumstats[met.id] / this.nbproducts : 0
            )
          }
        ]
      };
    },
    options() {
      return {
        legend: {
          position: "left"
        },
        tooltips: {
          mode: "label",
          callbacks: {
            label(tooltipItem, data) {
              let label = data.labels[tooltipItem.index] || "";
              label += ": ";
              label += formatTime(data.datasets[0].data[tooltipItem.index]);
              return label;
            }
          }
        }
      };
    }
  },
  mounted() {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.renderChart(this.chartdata, this.options);
  }
};
