var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentGame)?_c('div',{staticClass:"lean-stage-wrapper"},[(_vm.$route.name === 'play')?_c('b-sidebar',{attrs:{"open":_vm.showMessages && !_vm.showOnboarding,"can-cancel":[],"type":"is-light","right":true}},[_c('game-messages',{attrs:{"is-open":_vm.showMessages && !_vm.showOnboarding}})],1):_vm._e(),_c('b-modal',{attrs:{"active":_vm.showOnboarding,"trap-focus":""},on:{"close":_vm.closeLeanOnboarding}},[_c('lean-student-onboarding',{on:{"closeonboarding":_vm.closeLeanOnboarding}})],1),_c('div',{staticClass:"container is-widescreen pt-5"},[_c('div',{staticClass:"columns is-vcentered"},[_c('div',{staticClass:"column"},[_c('game-status-box')],1),_c('div',{staticClass:"column"},[_c('div',{staticClass:"columns is-vcentered"},[(_vm.openFaciPanel)?_c('div',{staticClass:"column is-narrow px-0"},[_c('b-tooltip',{attrs:{"label":"Back to workshop","type":"is-dark","position":"is-bottom"}},[_c('b-button',{staticStyle:{"height":"2.68em"},attrs:{"size":"is-large","icon-left":"grid"},on:{"click":function($event){return _vm.$store.commit('setOpenFaciPanel', {
                    fid: null,
                    gid: _vm.currentGame.id
                  })}}},[_c('b-icon',{staticClass:"pt-2 pl-2",attrs:{"size":"is-medium","icon":"arrow-left"}})],1)],1)],1):_vm._e(),_c('div',{staticClass:"column px-0 has-text-centered"},[_c('h2',{staticClass:"title is-4 mt-1 mb-1"},[(_vm.openFaciPanel)?_c('span',[_vm._v(" "+_vm._s(_vm.currentGame.facilities[_vm.openFaciPanel].name)+" ")]):_vm._e(),(_vm.myFaci && !_vm.openFaciPanel)?_c('p',[_vm._v(" Playing as: "),_c('a',{on:{"click":function($event){return _vm.$store.commit('setOpenFaciPanel', {
                      fid: _vm.myFaci.id,
                      gid: _vm.currentGame.id
                    })}}},[_vm._v(_vm._s(_vm.myFaci.name))])]):_vm._e(),(!_vm.currentGame.params.game_start_at)?_c('p',{staticClass:"is-size-6 has-text-danger"},[_vm._v(" The game has not started yet ")]):_vm._e(),(_vm.currentGame.params.game_end_at)?_c('p',{staticClass:"is-size-6 has-text-danger"},[_vm._v(" The game is finished - "),_c('router-link',{attrs:{"to":'/game/' + _vm.currentGame.id + '/results'}},[_vm._v("Go to debrief")])],1):_vm._e(),(
                  _vm.currentGame.params.game_start_at &&
                    !_vm.myFaci &&
                    !_vm.currentGame.params.game_end_at
                )?_c('p',{staticClass:"is-size-6 has-text-danger"},[_vm._v(" You are playing as observer ")]):_vm._e(),(
                  !_vm.currentGame.params.game_end_at &&
                    _vm.openFaciPanel &&
                    _vm.currentGame.params.game_start_at &&
                    _vm.myFaci &&
                    _vm.myFaci.id !== _vm.openFaciPanel
                )?_c('p',{staticClass:"is-size-6 has-text-danger"},[_vm._v(" You are playing as another station ")]):_vm._e()])])])]),_c('div',{staticClass:"column is-5"},[(_vm.openFaciPanel)?_c('station-status-box',{attrs:{"faci":_vm.currentGame.facilities[_vm.openFaciPanel]}}):_vm._e()],1)]),_c('div',{staticClass:"columns is-centered"},[(!_vm.openFaciPanel)?_c('div',{staticClass:"column is-1 pt-1 pb-0"},[_c('lean-box-side',{attrs:{"is-small":true}})],1):_vm._e(),(!_vm.openFaciPanel)?_c('div',{staticClass:"column"},[_c('lean-shop-map',{attrs:{"game":_vm.currentGame,"in-game":true},on:{"editname":function($event){return _vm.$emit('editname')}}})],1):_vm._e(),(_vm.openFaciPanel)?_c('div',{staticClass:"column"},[(_vm.faciComponents[_vm.openFaciPanel])?_c(_vm.faciComponents[_vm.openFaciPanel],{tag:"component",attrs:{"faci":_vm.currentGame.facilities[_vm.openFaciPanel]}}):_vm._e()],1):_vm._e()])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }