<template>
  <div class="faci-stock-box-wrapper box is-relative">
    <p
      class="warning-backorder has-text-centered has-text-danger"
      v-if="!faci.stock"
    >
      <b-icon icon="alert" size="is-large"></b-icon>
      <br />
      <span class="is-size-6-tablet">No more stock</span>
    </p>
    <svg viewbox="0 0 100 100" preserveAspectRatio="none" width="100%">
      <g :id="dot.id" v-for="dot in dots" :key="dot.id">
        <circle
          :cx="`${dot.xpos}%`"
          :cy="`${dot.ypos}%`"
          r="10"
          class="unit-circle"
          fill="#0086e3"
        />
        <text
          v-if="dot.text"
          :x="`${dot.xpos}%`"
          :y="`${dot.ypos}%`"
          text-anchor="middle"
          font-size="0.5em"
          dy=".3em"
          fill="white"
        >
          {{ dot.text }}
        </text>
      </g>
    </svg>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import anime from "animejs/lib/anime.es.js";

function calcDivider(a) {
  if (!a || a < 100) {
    return 1;
  }
  var size = Math.floor(Math.log(Math.abs(a)) / Math.log(10));
  var magnitude = Math.pow(10, size);
  return magnitude / 10;
}

export default {
  name: "FaciStockBox",
  props: { statsFaci: Array, faci: Object },
  computed: {
    ...mapGetters(["custoLook", "currentGame"])
  },
  methods: {
    resetStart() {
      this.dots = [];
      this.toShip = [];
      this.receiveDots = [];
      this.stockDots = [];
      anime.remove(this.dots);
      anime({
        targets: ".warning-backorder",
        opacity: 0,
        duration: 200
      });
      if (this.faci) {
        let startStock =
          this.currentGame.week > 0
            ? this.statsFaci[this.currentGame.week - 1].stock || 0
            : this.statsFaci[0].stock || 0;
        let divider = calcDivider(
          startStock + (this.statsFaci[this.currentGame.week].delivery || 0)
        );
        for (let ii = 0; ii < startStock / divider; ii++) {
          this.dots.push({
            id: `dotstock-${ii}`,
            xpos: 10 + Math.round(80 * Math.random()),
            ypos: 10 + Math.round(80 * Math.random()),
            ...(divider > 1 && ii > 0 && { text: divider })
          });
          this.stockDots.push(`dotstock-${ii}`);
        }
        for (
          let ii = 0;
          ii < this.statsFaci[this.currentGame.week].delivery / divider;
          ii++
        ) {
          this.dots.push({
            id: `dotrec-${ii}`,
            xpos: 110 + Math.round(80 * Math.random()),
            ypos: 10 + Math.round(80 * Math.random()),
            ...(divider > 1 && ii > 0 && { text: divider })
          });
          this.receiveDots.push(`dotrec-${ii}`);
        }
        // First take the max dots from stock
        this.toShip = this.stockDots.slice(
          0,
          Math.min(
            this.stockDots.length,
            Math.ceil(this.statsFaci[this.currentGame.week].shipment / divider)
          )
        );
        // If needed add some from the receipt
        if (
          this.toShip.length <
          Math.ceil(this.statsFaci[this.currentGame.week].shipment / divider)
        ) {
          this.toShip.push(
            ...this.receiveDots.slice(
              0,
              Math.ceil(
                this.statsFaci[this.currentGame.week].shipment / divider
              ) - this.toShip.length
            )
          );
        }
      }
    },
    animeReceive(speed) {
      let findReceive = this.dots.filter(dot =>
        this.receiveDots.includes(dot.id)
      );
      anime({
        targets: findReceive,
        xpos() {
          return 10 + Math.round(80 * Math.random());
        },
        duration: speed || 1000,
        delay: anime.stagger((speed || 1000) / findReceive.length),
        easing: "easeInOutQuad"
      });
    },
    animeShip(speed) {
      let findShip = this.dots.filter(dot => this.toShip.includes(dot.id));
      anime({
        targets: findShip,
        xpos() {
          return -10 - Math.round(80 * Math.random());
        },
        duration: speed || 1000,
        delay: anime.stagger((speed || 1000) / findShip.length),
        easing: "easeInOutQuad"
      });
      anime({
        targets: ".warning-backorder",
        opacity: [0, 1],
        duration: speed || 1000,
        delay: speed || 1000
      });
    }
  },
  mounted() {
    this.resetStart();
  },
  data() {
    return {
      receiveDots: [],
      stockDots: [],
      toShip: [],
      dots: []
    };
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.faci-stock-box-wrapper {
  padding: 0 !important;
  margin-left: 1em;
  margin-right: 1em;
  @media (max-width: 767px) {
    margin-left: 0em;
    margin-right: 0em;
  }
  .unit-circle {
    opacity: 0.7;
  }
  .warning-backorder {
    opacity: 0;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
