<template>
  <div class="select-metrics">
    <b-field grouped group-multiline>
      <p class="control">
        <b-button
          :type="
            value.length == tabAllMetrics.length ? 'is-danger' : 'is-warning'
          "
          @click="toggleShowAllMetrics()"
          size="is-small"
          rounded
        >
          {{
            value.length == tabAllMetrics.length ? "Unselect all" : "Select all"
          }}</b-button
        >
      </p>
      <b-checkbox
        v-on:input="clickBox"
        v-model="checkMetrics"
        type="is-warning"
        v-for="metric in tabAllMetrics"
        :key="metric"
        :native-value="metric"
      >
        {{ allLabels[metric] }}
      </b-checkbox>
    </b-field>
  </div>
</template>

<script>
export default {
  name: "SelectLeanMetrics",
  props: {
    value: Array,
    startWith: Array,
    stdMetrics: Array,
    extraLabels: Object
  },
  mounted() {
    if (!this.startWith) {
      this.$emit("input", this.stdMetrics);
      this.checkMetrics = this.stdMetrics;
    } else {
      this.$emit("input", this.startWith);
      this.checkMetrics = this.startWith;
    }
  },
  computed: {
    tabAllMetrics() {
      if (this.startWith && this.startWith.length) {
        return [...new Set(this.stdMetrics.concat(this.startWith))];
      } else {
        return this.stdMetrics;
      }
    },
    allLabels() {
      return { ...this.metricsLabels, ...this.extraLabels };
    }
  },
  methods: {
    clickBox() {
      this.$emit("input", this.checkMetrics);
    },
    toggleShowAllMetrics() {
      if (this.checkMetrics.length == this.tabAllMetrics.length) {
        this.checkMetrics = [];
      } else {
        this.checkMetrics = this.tabAllMetrics.map(metric => {
          return metric;
        });
      }
      this.$emit("input", this.checkMetrics);
    }
  },
  data() {
    return {
      checkMetrics: [],
      metricsLabels: {
        workdeskTime: "Processing",
        changeoverTime: "Tool Changeover",
        waitTime: "Waiting in queue",
        moveTime: "Transportation",
        loadTime: "Loading/Unloading"
      }
    };
  }
};
</script>

<style lang="scss">
.select-metrics {
  .label {
    margin-top: 3px;
    margin-right: 1em;
    font-size: 1.1rem;
    color: hsl(0, 0%, 48%);
  }
  .button {
    margin-right: 1em;
  }
  .b-checkbox {
    margin-top: 0;
    margin-right: 1em;
    margin-bottom: 1em;
  }
}
</style>
