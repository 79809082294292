var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"game-status-box-wrapper box"},[_c('div',{staticClass:"level"},[_c('div',{staticClass:"level-item"},[_c('b-tooltip',{staticStyle:{"cursor":"help"},attrs:{"label":"Game Timer","type":"is-dark","position":"is-bottom"}},[_c('div',{staticClass:"title is-3 has-text-centered mb-1",class:{
            'has-text-grey-light': !_vm.currentGame.params.game_start_at
          }},[_c('timer-elapsed',{ref:"maintimer",attrs:{"is-active":_vm.currentGame.params.game_start_at &&
              !_vm.currentGame.params.game_end_at
                ? true
                : false,"start-sec":_vm.currentGame.params.game_start_at
                ? _vm.currentGame.params.game_start_at.seconds
                : 0,"end-sec":_vm.currentGame.params.game_end_at
                ? _vm.currentGame.params.game_end_at.seconds
                : 0}})],1)])],1),_c('div',{staticClass:"level-item"},[_c('b-tooltip',{staticStyle:{"cursor":"help"},attrs:{"label":"Quantity delivered / Quantity ordered","type":"is-dark","position":"is-bottom","size":"is-small","multilined":""}},[_c('p',{staticClass:"has-text-centered"},[_c('b-icon',{attrs:{"icon":"face-agent","size":"is-small"}}),_vm._v(" Delivered:"),_c('br'),_vm._v(_vm._s(_vm.statusOrders.done)+"/"+_vm._s(_vm.statusOrders.all)+" pcs ")],1)])],1),_c('div',{staticClass:"level-item"},[_c('b-tooltip',{staticStyle:{"cursor":"help"},attrs:{"label":"Total number of units being processed in the workshop","type":"is-dark","position":"is-bottom","size":"is-small","multilined":""}},[_c('p',{staticClass:"has-text-centered"},[_c('b-icon',{attrs:{"icon":"triangle","size":"is-small"}}),_vm._v(" WIP:"),_c('br'),_vm._v(_vm._s(_vm.allStock)+" pcs ")],1)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }