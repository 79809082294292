<template>
  <div
    class="game-debrief"
    :style="{
      'background-color': custoLook.bodyBckColor
    }"
    v-if="currentGame"
  >
    <section class="section">
      <div class="container">
        <div class="columns mb0">
          <div class="column">
            <h1 class="title">
              {{ currentGame.name | capitalize({ onlyFirstLetter: true }) }}
            </h1>
            <h1 class="subtitle">
              {{ creationDate }}
              <span v-if="currentGame.finished" class="ml-3 has-text-danger">
                <b-icon icon="check-circle" size="is-small"></b-icon
                ><span>
                  Finished.
                </span>
              </span>
            </h1>
          </div>
          <div class="column is-narrow">
            <component
              v-if="currentGame && boxes[currentGame.type]"
              v-bind:is="boxes[currentGame.type]"
            ></component>
          </div>
        </div>
        <div v-if="!isPrinting">
          <b-notification
            :active="showError"
            @close="closeError"
            type="is-danger"
            >{{ showError ? error.message : "" }}</b-notification
          >
          <div class="columns is-centered">
            <div
              class="column is-two-thirds"
              v-if="
                currentGame.creator &&
                  !hasSubscribed &&
                  (!currentUser || !currentUser.email)
              "
            >
              <div class="box is-relative">
                <button
                  class="delete"
                  aria-label="close"
                  @click="hasSubscribed = true"
                ></button>
                <p class="mb-1">
                  For more news about our simulations, subscribe to our
                  newsletter!
                </p>
                <p class="mb-3 is-italic is-size-7">
                  We send max 1 email per month and you can unsubscribe anytime.
                </p>
                <b-field
                  grouped
                  position="is-centered"
                  :type="{ 'is-danger': $v.subscriptionEmail.$error }"
                  :message="
                    !$v.subscriptionEmail.$error
                      ? ''
                      : !$v.subscriptionEmail.required
                      ? 'Email is required'
                      : 'Wrong email format'
                  "
                >
                  <b-input
                    v-model="$v.subscriptionEmail.$model"
                    type="email"
                    placeholder="email@company.com"
                    style="width: 300px; max-width: 60%"
                  ></b-input>
                  <p class="control">
                    <b-button type="is-primary" @click="clickSubscribe"
                      >Subscribe</b-button
                    >
                  </p>
                </b-field>
              </div>
            </div>
            <div
              class="column is-three-quarters"
              v-if="!currentGame.creator && !closeThanksFree"
            >
              <div class="box is-relative">
                <button
                  class="delete"
                  aria-label="close"
                  @click="closeThanksFree = true"
                ></button>
                <h4 class="title is-4 mb-2">
                  Thanks for trying out a free trial game
                </h4>
                <div
                  v-if="
                    !currentGame.creator && (!currentUser || !currentUser.email)
                  "
                  class="is-size-5"
                >
                  To create your own customized simulations, create a free
                  account now!
                  <div class="buttons is-centered mt-2">
                    <b-button
                      size="is-medium"
                      rounded
                      type="is-primary"
                      @click="goSignup"
                    >
                      Sign up
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <component
          v-if="currentGame && debriefs[currentGame.type]"
          v-bind:is="debriefs[currentGame.type]"
          @printing="ev => (isPrinting = ev)"
        ></component>
      </div>
    </section>
  </div>
</template>

<script>
import { email, required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import BgRecapBox from "@/components/bg/BgRecapBox.vue";
import LeanRecapBox from "@/components/lean/LeanRecapBox.vue";
import BgDebrief from "@/components/bg/BgDebrief.vue";
import LeanDebrief from "@/components/lean/LeanDebrief.vue";

export default {
  name: "GameDebrief",
  validations: {
    subscriptionEmail: { email, required }
  },
  computed: {
    ...mapGetters([
      "currentGame",
      "custoLook",
      "currentUser",
      "error",
      "showError"
    ]),
    creationDate() {
      let date = this.currentGame.created_at
        ? new Date(this.currentGame.created_at.seconds * 1000)
        : new Date();
      return date.toLocaleDateString(undefined, {
        year: "numeric",
        month: "short",
        day: "numeric"
      });
    }
  },
  methods: {
    closeError() {
      this.$store.commit("clearError");
    },
    clickSubscribe() {
      this.$v.subscriptionEmail.$touch();
      if (!this.$v.subscriptionEmail.$invalid) {
        this.$store.commit("clearError");
        this.$store.commit("setLoading", true);
        this.$store
          .dispatch("verifyEmail", {
            email: this.subscriptionEmail,
            reason: "participant"
          })
          .then(() => {
            this.$store.dispatch("identifyProspect", {
              email: this.subscriptionEmail,
              traits: {
                profile_type: "participant",
                last_game_id: this.currentGame.id,
                last_game_name: this.currentGame.name
              },
              event_name: "participant_subscribe_after_game",
              event_data: {
                name: this.currentGame.name,
                id: this.currentGame.id,
                type: this.currentGame.type
              }
            });
            setTimeout(() => {
              this.hasSubscribed = true;
              this.$buefy.toast.open("Thanks, we'll be in touch!");
              this.$store.commit("setLoading", false);
            }, 10);
          })
          .catch(error => {
            this.$store.commit("setError", error);
            this.$store.commit("setLoading", false);
          });
      }
    },
    goSignup() {
      if (this.currentUser) {
        this.$store.dispatch("logout").then(() => {
          this.$router.push("/signup");
        });
      } else {
        this.$router.push("/signup");
      }
    }
  },
  data() {
    return {
      isPrinting: false,
      hasSubscribed: false,
      subscriptionEmail: "",
      closeThanksFree: false,
      boxes: {
        leangame: LeanRecapBox,
        beergame: BgRecapBox
      },
      debriefs: {
        leangame: LeanDebrief,
        beergame: BgDebrief
      }
    };
  }
};
</script>

<style lang="scss">
.game-debrief {
  .delete {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  &.is-printing {
    background-color: #fff !important;
  }
  .section {
    .title .button {
      margin-left: 1em;
      padding: 6px;
    }
    .subtitle {
      margin-bottom: 0.5em;
    }
  }
  .mb0 {
    margin-bottom: 0;
  }
}
</style>
