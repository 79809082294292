<template>
  <div class="lean-game-recap-wrapper">
    <div class="columns is-centered">
      <div class="column">
        <div class="box mt-2 py-1">
          <b-table
            :data="recapObj.lines"
            :mobile-cards="false"
            :row-class="row => row.rowClass"
          >
            <b-table-column
              v-slot="props"
              field="name"
              custom-key="Summary"
              width="55%"
            >
              {{ props.row.name }}
              <em class="is-size-7 has-text-grey" v-if="!isRecap">{{
                props.row.namedetail
              }}</em>
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="unitProfit"
              :label="`Unit Value`"
              width="15%"
            >
              <em
                v-if="props.row.unitProfit !== null"
                :class="{
                  'has-text-danger': props.row.unitProfit < 0,
                  'has-text-success': props.row.unitProfit > 0
                }"
                >{{
                  props.row.unitProfit
                    | currency(game.params.currency, 0, {
                      showPlusSign: true
                    })
                }}</em
              >
            </b-table-column>
            <b-table-column v-slot="props" field="qty" label="Qty" width="15%">
              <span v-if="props.row.qty !== null">{{ props.row.qty }}</span>
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="profit"
              label="Value"
              width="15%"
            >
              <span
                v-if="props.row.profit !== null"
                :class="{
                  'has-text-danger': props.row.profit < 0,
                  'has-text-success': props.row.profit > 0
                }"
                >{{
                  props.row.profit
                    | currency(game.params.currency, 0, {
                      showPlusSign: true
                    })
                }}</span
              >
            </b-table-column>
            <template slot="footer">
              <div class="columns is-mobile mb-0 ">
                <div class="column ">
                  OTIF Products Delivered
                  <em class="is-size-7 has-text-grey" v-if="!isRecap"
                    >On-time, in-full and conform</em
                  >
                  :
                </div>
                <div class="column is-3 has-text-centered">
                  {{ recapObj.totalOTIF }} units
                  <em>
                    ({{
                      recapObj.totalSales
                        ? recapObj.totalOTIF / recapObj.totalSales
                        : 0 | percent
                    }})
                  </em>
                </div>
              </div>
            </template>
          </b-table>
        </div>
      </div>
      <!-- <div class="column is-5">
        <div class="py-6 px-6">
          <lean-radar-chart></lean-radar-chart>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
// import LeanRadarChart from "@/components/lean/Stats/LeanRadarChart.js";

export default {
  name: "LeanGameRecap",
  components: {
    // LeanRadarChart
  },
  props: {
    recapObj: Object,
    game: Object,
    isRecap: Boolean
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss">
.lean-game-recap-wrapper {
  .table-footer {
    background-color: hsl(48, 100%, 96%);
  }
}
</style>
