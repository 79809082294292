<template>
  <div class="lean-debrief" v-if="currentGame">
    <article>
      <div v-if="!hidePrinting">
        <section class="intro-section mt-2">
          <p>
            Add our Completion Certificate on your LinkedIn profile, and save
            your results for later analysis :
          </p>
          <div class="buttons is-centered mt-4 mb-2">
            <b-button
              tag="a"
              target="_blank"
              :href="certifUrl"
              size="is-medium"
              icon-left="linkedin"
              type="is-info"
              >Add certificate to my profile</b-button
            >
            <b-button
              size="is-medium"
              type="is-warning"
              icon-left="file"
              @click="downloadPdf"
              >Download debrief as pdf</b-button
            >
            <!-- <b-button
              size="is-medium"
              type="is-success"
              icon-left="file-excel-box"
              @click="downloadExcel"
              >Download game data (excel)</b-button
            > -->
          </div>
        </section>
        <p>
          See below your team and individual results :
        </p>
      </div>
      <section class="intro-section mt-2" v-if="allStats && currentProducts">
        <div class="avoid-pagebreak">
          <h5 class="title is-4 mb-2">
            Profit and Loss
          </h5>
          <div class="content mb-2" v-if="!hidePrinting">
            Did your operation actually managed to generate profit ? You'll find
            below a simplified income statement. There are two ways to increase
            your net profit :
            <ul class="mb-0">
              <li>
                Grow revenue : improve your operations to fulfill more orders
              </li>
              <li>
                Reduce costs : identify the major lines of expenses and act on
                these
              </li>
            </ul>
          </div>
          <div class="container is-max-print">
            <!-- <div class="card px-4 py-4">
              <select-lean-metrics
                v-model="checkRecapProducts"
                :std-metrics="Object.keys(currentGame.params.productsTemplates)"
                :extra-labels="productsNameObj"
              ></select-lean-metrics>
            </div> -->
            <lean-recap-table
              v-if="allStats.recapStatsTable"
              :recap-obj="allStats.recapStatsTable"
              :game="currentGame"
            ></lean-recap-table>
          </div>
        </div>
        <br /><br />
        <div class="avoid-pagebreak">
          <h5 class="title is-4 mb-2">
            Customer Orders and Deliveries
          </h5>
          <div class="content">
            From our customer's perspective, the most important is to receive
            the goods on-time and with the required level of quality. See below
            if you managed to meet customers expectations !
            <br />Note that producing too fast/early and with over-quality is
            also considered waste.
          </div>
          <div class="container is-max-print">
            <lean-orders-table
              :recap="true"
              :is-active="false"
              :wip-products="null"
              :products="currentProducts"
              :game="currentGame"
              :orders="allStats.currentLeanGameOrders"
            ></lean-orders-table>
          </div>
        </div>
        <br /><br />
        <div class="columns avoid-pagebreak">
          <div class="column">
            <h2 class="title is-4 mb-2">Resource efficiency</h2>
            <p class="content">
              Looking at the workstations, what was the proportion of their
              capacity put to use ? Here we are assessing if the team was busy
              during the game session.
            </p>
            <div class="tile is-ancestor">
              <div class="tile is-parent">
                <div class="tile is-child box">
                  <h1 class="title is-4">
                    {{ allStats.totalGameDurationMilli | minsec }}
                  </h1>
                  <h1 class="subtitle is-6">
                    Total game duration
                  </h1>
                </div>
              </div>
              <div class="tile is-parent">
                <div class="tile is-child box">
                  <h1 class="title is-4 has-text-success">
                    {{
                      (allStats.capaTimeTable.valAddTot /
                        allStats.capaTimeTable.nbProcStations)
                        | minsec
                    }}
                    <em class="has-text-info"
                      >-
                      {{
                        (allStats.capaTimeTable.valAddTot /
                          allStats.capaTimeTable.nbProcStations /
                          allStats.totalGameDurationMilli)
                          | percent
                      }}</em
                    >
                  </h1>
                  <h1 class="subtitle is-6">
                    Average station active time
                    <b-tooltip
                      type="is-dark"
                      label="When a station was actually processing products, on average"
                      position="is-top"
                      size="is-small"
                      multilined
                    >
                      <b-icon
                        size="is-small"
                        class="ml-1"
                        icon="help-circle-outline"
                      ></b-icon>
                    </b-tooltip>
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-narrow">
            <div class="is-divider is-divider-vertical"></div>
          </div>
          <div class="column">
            <h2 class="title is-4 mb-2">Flow efficiency</h2>
            <p class="content">
              Looking at an individual unit, how long did it take to go through
              the entire process, and which proportion was actually adding value
              ? Here we are assessing if the products went through the process
              as quickly as possible.
            </p>
            <b-notification
              :closable="false"
              v-if="
                !allStats ||
                  !allStats.productsStatsObj ||
                  !allStats.productsStatsObj['all'].manufLeadTime.nbProducts
              "
              >This data is not available because no product went through the
              whole process</b-notification
            >
            <div
              class="tile is-ancestor"
              v-if="
                allStats &&
                  allStats.productsStatsObj &&
                  allStats.productsStatsObj['all'].manufLeadTime.nbProducts
              "
            >
              <div class="tile is-parent">
                <div class="tile is-child box">
                  <h1 class="title is-4">
                    {{
                      (allStats.productsStatsObj["all"].manufLeadTime.tot /
                        allStats.productsStatsObj["all"].manufLeadTime
                          .nbProducts)
                        | minsec
                    }}
                  </h1>
                  <h1 class="subtitle is-6">
                    Average product throughput time
                    <b-tooltip
                      type="is-dark"
                      label="Time between the start of a product's production and its delivery to the customer"
                      position="is-top"
                      size="is-small"
                      multilined
                    >
                      <b-icon
                        size="is-small"
                        class="ml-1"
                        icon="help-circle-outline"
                      ></b-icon>
                    </b-tooltip>
                  </h1>
                </div>
              </div>
              <div class="tile is-parent">
                <div class="tile is-child box">
                  <h1 class="title is-4 has-text-success">
                    {{
                      (allStats.productsStatsObj["all"].sumFaciData[
                        "workdeskTime"
                      ] /
                        allStats.productsStatsObj["all"].manufLeadTime
                          .nbProducts)
                        | minsec
                    }}
                    <em class="has-text-info"
                      >-
                      {{
                        (allStats.productsStatsObj["all"].sumFaciData[
                          "workdeskTime"
                        ] /
                          allStats.productsStatsObj["all"].manufLeadTime.tot)
                          | percent
                      }}</em
                    >
                  </h1>
                  <h1 class="subtitle is-6">
                    Average value-adding time
                    <b-tooltip
                      type="is-dark"
                      label="Average time spent actually processing each product"
                      position="is-top"
                      size="is-small"
                      multilined
                    >
                      <b-icon
                        size="is-small"
                        class="ml-1"
                        icon="help-circle-outline"
                      ></b-icon>
                    </b-tooltip>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="columns avoid-pagebreak mt-4">
          <div class="column">
            <h2 class="title is-5 is-italic mb-2">
              Resource efficiency - Detail per station
            </h2>
            For how long during the game was each station actually processing
            products, vs. the total game duration
            <b>({{ allStats.totalGameDurationMilli | minsec }})</b>.
            <div class="box py-1 px-1 mt-2">
              <b-table
                :data="allStats.capaTimeTable.data"
                :mobile-cards="false"
                :row-class="row => row.rowClass"
              >
                <b-table-column v-slot="props" field="name" label="Station">
                  {{ props.row.name }}
                </b-table-column>
                <b-table-column
                  v-slot="props"
                  field="valTime"
                  label="Tot. Proc. Time"
                >
                  {{ props.row.valTime | minsec }}
                </b-table-column>
                <b-table-column
                  v-slot="props"
                  field="pourcCapa"
                  label="%Utilization"
                >
                  <span v-if="props.row.pourcCapa">{{
                    props.row.pourcCapa | percent
                  }}</span>
                </b-table-column>
                <b-table-column
                  v-slot="props"
                  field="valTime"
                  label="Avg. Proc. Time/unit"
                >
                  {{ props.row.avgProcTime | minsec }}
                </b-table-column>
              </b-table>
            </div>
          </div>
          <div class="column is-narrow">
            <div class="is-divider is-divider-vertical"></div>
          </div>
          <div class="column" :class="{ 'is-6': hidePrinting }">
            <h2 class="title is-5 is-italic mb-2">
              Flow efficiency - Detail per activity
            </h2>
            <p>
              See below the average time spent on the different
              value-adding/non-value-adding activities per unit.
              <br />In the data below we only consider the products which
              completed the whole process.
            </p>
            <b-notification
              :closable="false"
              v-if="
                !allStats ||
                  !allStats.productsStatsObj ||
                  !allStats.productsStatsObj['all'].manufLeadTime.nbProducts
              "
              >This chart is not available because no product went through the
              whole process</b-notification
            >
            <div
              class="columns"
              :class="{ 'is-centered': !hidePrinting }"
              style="margin-top: -3em"
              v-if="
                allStats &&
                  allStats.productsStatsObj &&
                  allStats.productsStatsObj['all'].manufLeadTime.nbProducts
              "
            >
              <div class="column is-9">
                <lean-doughnut-chart
                  :sumstats="allStats.productsStatsObj['all'].sumFaciData"
                  :nbproducts="
                    allStats.productsStatsObj['all'].manufLeadTime.nbProducts
                  "
                ></lean-doughnut-chart>
              </div>
            </div>
          </div>
        </div>
        <br /><br />
        <div class="avoid-pagebreak">
          <h5 class="title is-4 mb-2">
            Balance chart
          </h5>
          <div class="columns is-centered mb-0 mt-1">
            <div class="column is-7">
              <b-message type="is-info">
                <b>Definition:</b>
                The <em>takt time</em> refers to the average rate at which units
                are ordered by your customers.<br />Its calculation is as
                follows :
                <p class="has-text-centered pt-2 is-size-5">
                  <em>Takt time = Available time / Total Customer Demand</em>
                </p>
              </b-message>
            </div>
          </div>
          <div class="content">
            The workshop has to produce units with a sufficient frequency to
            meet customer demand.<br />On average, the time spent per unit at
            each station should be below the takt time. In our case:
            <p class="has-text-centered my-2">
              <em class="is-size-5 ml-5"
                >Takt time = {{ allStats.calcTaktTime.timeAvail | minsec }} /
                {{ allStats.calcTaktTime.totalDem }} units =
                <b>{{ allStats.calcTaktTime.takt | minsec }}</b> sec per
                unit</em
              >
            </p>
            Think of ways the workload could be balanced between stations to
            ensure a continuous flow of products inside the workshop.
          </div>

          <div class="card px-4 py-4" v-if="!hidePrinting">
            <select-lean-metrics
              v-model="checkTimeMetrics"
              :start-with="[
                'workdeskTime',
                'changeoverTime',
                'loadTime',
                'waitTime',
                'moveTime'
              ]"
              :std-metrics="[
                'workdeskTime',
                'changeoverTime',
                'loadTime',
                'waitTime',
                'moveTime'
              ]"
            ></select-lean-metrics>
          </div>
          <div class="container is-max-print">
            <stacked-lean-bar-chart
              ref="leantimechart"
              v-if="allStats.productsStatsObj"
              :stats="allStats.productsStatsObj"
              :game="currentGame"
              :metrics="checkTimeMetrics"
              :takt="
                allStats.calcTaktTime.totalDem
                  ? allStats.calcTaktTime.takt
                  : null
              "
            ></stacked-lean-bar-chart>
          </div>
          <br />
        </div>
        <br /><br />
        <div class="avoid-pagebreak">
          <h5 class="title is-4 mb-2">
            Work-in-Progress Inventory
          </h5>
          <div class="content">
            You'll find below the evolution of the inventory on the various
            stations. Can you identify one or several stations where stock is
            pilling up ?
          </div>

          <div class="card px-4 py-4" v-if="!hidePrinting">
            <div class="columns">
              <div class="column is-narrow">
                <b-field label="Precision:" horizontal>
                  <b-field>
                    <b-radio-button
                      v-model="stockStepPrecision"
                      :native-value="60"
                      type="is-info"
                      @input="keyStockGraph += 1"
                      >1min</b-radio-button
                    >
                    <b-radio-button
                      v-model="stockStepPrecision"
                      :native-value="30"
                      type="is-info"
                      @input="keyStockGraph += 1"
                      >30s</b-radio-button
                    >
                    <b-radio-button
                      v-model="stockStepPrecision"
                      :native-value="10"
                      type="is-info"
                      @input="keyStockGraph += 1"
                      >10s</b-radio-button
                    >
                  </b-field>
                </b-field>
              </div>
              <div class="column is-narrow pr-0">
                <b class="is-inline-block pt-2">Stations:</b>
              </div>
              <div class="column">
                <div class="pt-1">
                  <select-lean-metrics
                    v-model="checkStockFaci"
                    :std-metrics="Object.keys(currentGame.facilities)"
                    :extra-labels="faciNameObj"
                  ></select-lean-metrics>
                </div>
              </div>
            </div>
          </div>

          <div class="container is-max-print">
            <stacked-lean-stock-chart
              v-if="allStats.stockEvolution"
              ref="leanstockchart"
              :per-second="stockStepPrecision < 60 ? true : false"
              :step="stockStepPrecision"
              :stats="allStats.stockEvolution.tabSec"
              :filter-faci-tab="checkStockFaci"
              :average="averageFaciStock.avgSelec"
              :key="`updateif-${keyStockGraph}`"
              :game="currentGame"
            ></stacked-lean-stock-chart>
          </div>
        </div>
        <div class="avoid-pagebreak">
          <div class="columns mt-4">
            <div class="column content">
              <h2 class="title is-4">Inventory Lead Time</h2>
              In queuing theory, the average waiting time can be found by
              looking at the length of the queue divided by the time to process
              one unit. This is know as
              <em class="has-text-weight-bold">Little's law</em>. <br /><br />
              In our case, we can estimate the waiting time in front of each
              station with the following formula :
              <div class="has-text-centered py-2">
                <em class="is-size-5">
                  Waiting time = Average Inventory * Takt time
                </em>
              </div>
              By minimizing the work-in-progress in the system, we reduce the
              queuing/waiting time, and improve the reactivity of the workshop.
            </div>
            <div class="column is-5">
              <h2 class="title is-5 is-italic">
                Average inventory per station
              </h2>
              <div class="box py-1 px-1">
                <b-table
                  :data="averageFaciStock.lines"
                  :mobile-cards="false"
                  :row-class="row => row.rowClass"
                >
                  <b-table-column v-slot="props" field="name" label="Station">
                    {{ props.row.name }}
                  </b-table-column>
                  <b-table-column
                    v-slot="props"
                    field="avgStock"
                    label="Avg. Inventory"
                  >
                    {{ props.row.avgStock }}
                  </b-table-column>
                  <b-table-column
                    v-slot="props"
                    field="equivTime"
                    label="Inventory time"
                  >
                    {{ props.row.equivTime | minsec }}
                  </b-table-column>
                </b-table>
              </div>
            </div>
          </div>
        </div>
        <br /><br />
        <div class="avoid-pagebreak">
          <h5 class="title is-4 mb-2">
            Quality Performance
          </h5>
          <div class="content">
            A common source of waste in a production system is scrap and rework.
            Here are some ways to minimize them:
            <ul>
              <li>
                Add in-process quality inspection, this concept is called
                <em class="has-text-weight-bold">Jidoka</em>. Each worker will
                control and make sure the part is conform before it goes to the
                next station.
              </li>
              <li>
                Standardize work and add
                <em class="has-text-weight-bold">Poka-Yoke</em> mechanisms
                (japanese term meaning "mistake-proofing") to prevent, correct,
                or draw attention on errors as they occur.
              </li>
            </ul>
          </div>
          <div class="columns">
            <div class="column">
              <h2 class="title is-5 mb-2 is-italic">Operations status</h2>
              Which station generated the most internal defects ? Note that some
              rework may have been made to correct these before products were
              delivered to the customer.
              <div class="box py-1 px-1 mt-2">
                <b-table
                  :data="allStats.faciOpeStatus.data"
                  :mobile-cards="false"
                  :row-class="row => row.rowClass"
                >
                  <b-table-column v-slot="props" field="name" label="Station">
                    {{ props.row.name }}
                  </b-table-column>
                  <b-table-column
                    v-slot="props"
                    field="Successful"
                    label="Success"
                  >
                    {{
                      (props.row["Successful"] || 0) +
                        (props.row["Rework"] || 0)
                    }}
                  </b-table-column>
                  <b-table-column v-slot="props" field="Failed" label="Failed">
                    {{ props.row["Failed"] || 0 }}
                  </b-table-column>
                  <b-table-column
                    v-slot="props"
                    field="percSuccess"
                    label="%Success"
                  >
                    <span v-if="props.row.totOpes">{{
                      props.row.percSuccess | percent
                    }}</span>
                  </b-table-column>
                </b-table>
              </div>
            </div>
            <div class="column">
              <h2 class="title is-5 is-italic">
                Defects at the customer
              </h2>
              <div class="content">
                <b>{{ allStats.custoDefects.all.nbProd }}</b> deffective
                products were delivered, concerned by a total of
                <b>{{ allStats.custoDefects.all.nbOpe }}</b> issues.
                <br />
                Detail by product type :
                <ul>
                  <li
                    v-for="prod in Object.values(
                      currentGame.params.productsTemplates
                    ).sort((a, b) => {
                      return a.template_id.localeCompare(b.template_id);
                    })"
                    :key="prod.template_id"
                  >
                    {{ prod.name }} :
                    <b>{{ allStats.custoDefects[prod.template_id].nbProd }}</b>
                    deffective products delivered (<b>{{
                      allStats.custoDefects[prod.template_id].nbOpe
                    }}</b>
                    issues)
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import XlsxPopulate from "xlsx-populate";
import html2pdf from "html2pdf.js";
import LeanRecapTable from "@/components/lean/Stats/LeanRecapTable.vue";
import LeanOrdersTable from "@/components/lean/Stats/LeanOrdersTable.vue";
import SelectLeanMetrics from "@/components/lean/Stats/SelectLeanMetrics.vue";
import StackedLeanBarChart from "@/components/lean/Stats/StackedLeanBarChart.js";
import StackedLeanStockChart from "@/components/lean/Stats/StackedLeanStockChart.js";
import LeanDoughnutChart from "@/components/lean/Stats/LeanDoughnutChart.js";
import calculateLeanStats from "@/components/lean/Stats/calculateLeanStats.js";

// let getMean = function(data) {
//   return (
//     data.reduce(function(a, b) {
//       return Number(a) + Number(b);
//     }) / data.length
//   );
// };
// let getSD = function(data) {
//   let m = getMean(data);
//   return Math.sqrt(
//     data.reduce(function(sq, n) {
//       return sq + Math.pow(n - m, 2);
//     }, 0) /
//       (data.length - 1)
//   );
// };

export default {
  name: "LeanDebrief",
  components: {
    LeanOrdersTable,
    LeanRecapTable,
    SelectLeanMetrics,
    StackedLeanStockChart,
    LeanDoughnutChart,
    StackedLeanBarChart
    // LinesChart,
    // SelectMetrics
  },
  computed: {
    ...mapGetters(["loading", "currentGame", "currentProducts", "currentUser"]),
    productsNameObj() {
      let obj = {};
      Object.entries(this.currentGame.params.productsTemplates).forEach(
        pTab => {
          obj[pTab[0]] = pTab[1].name;
        }
      );
      return obj;
    },
    faciNameObj() {
      let obj = {};
      Object.entries(this.currentGame.facilities).forEach(fTab => {
        obj[fTab[0]] = fTab[1].name;
      });
      return obj;
    },
    averageFaciStock() {
      let obj = {
        sumSelec: 0,
        sumAll: 0,
        avgSelec: 0,
        avgAll: 0,
        lines: []
      };
      Object.keys(this.currentGame.facilities).forEach(fid => {
        if (this.checkStockFaci.includes(fid)) {
          obj.sumSelec +=
            this.allStats.stockEvolution.totalSec.all.faciTot[fid] || 0;
        }
        obj.sumAll +=
          this.allStats.stockEvolution.totalSec.all.faciTot[fid] || 0;
        let avgFaci = Math.max(
          0.1,
          Math.round(
            (10 *
              (this.allStats.stockEvolution.totalSec.all.faciTot[fid] || 0)) /
              this.allStats.stockEvolution.nbSec
          ) / 10
        );
        obj.lines.push({
          id: fid,
          name: this.currentGame.facilities[fid].name,
          avgStock: avgFaci,
          equivTime: avgFaci * this.allStats.calcTaktTime.takt,
          rowClass: ""
        });
      });
      obj.avgAll =
        Math.round((10 * obj.sumAll) / this.allStats.stockEvolution.nbSec) / 10;
      obj.avgSelec =
        Math.round((10 * obj.sumSelec) / this.allStats.stockEvolution.nbSec) /
        10;
      obj.lines.push({
        id: "all",
        name: "All Workshop",
        avgStock: obj.avgAll,
        equivTime: obj.avgAll * this.allStats.calcTaktTime.takt,
        rowClass: "has-text-weight-bold has-background-warning-light"
      });
      return obj;
    },
    certifUrl() {
      let today = new Date();
      let obj = {
        name:
          "Introduction%20to%20Lean%20manufacturing%20principles%20with%20the%20Lean%20game",
        orgId: "70246155",
        site: "https%3A%2F%2Fzensimu.com",
        curYear: today.getFullYear(),
        curMonth: today.getMonth()
      };
      return `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${obj.name}&organizationId=${obj.orgId}&issueYear=${obj.curYear}&
issueMonth=${obj.curMonth}&certUrl=${obj.site}`;
    }
  },
  created() {},
  mounted() {
    this.$store
      .dispatch("bindProducts", {
        id: this.currentGame.id,
        force: true
      })
      .then(() => {
        this.updateStats();
      });
  },
  methods: {
    updateStats() {
      setTimeout(() => {
        this.allStats = calculateLeanStats(
          this.currentGame,
          this.currentProducts
        );
      }, 10);
    },
    downloadPdf() {
      document.querySelector(".game-debrief").classList.add("is-printing");
      this.hidePrinting = true;
      this.$emit("printing", true);
      setTimeout(() => {
        html2pdf(document.querySelector(".game-debrief section.section"), {
          margin: 5,
          pagebreak: { avoid: ".avoid-pagebreak" },
          filename: "leangame_debrief.pdf",
          html2canvas: {
            scale: 2,
            scrollY: 0,
            onclone: element => {
              const canvasElements = Array.from(
                element.querySelectorAll("svg")
              );
              canvasElements.forEach(s => {
                const bBox = s.getBBox();
                s.setAttribute("x", bBox.x);
                s.setAttribute("y", bBox.y);
                s.setAttribute("width", bBox.width * 0.5);
                s.setAttribute("height", bBox.height);
              });
            }
          },
          jsPDF: { orientation: "l", format: "a4" }
        });
        setTimeout(() => {
          document
            .querySelector(".game-debrief")
            .classList.remove("is-printing");
          this.hidePrinting = false;
          this.$emit("printing", false);
        }, 5);
      }, 10);
    },
    downloadExcel() {
      XlsxPopulate.fromBlankAsync().then(workbook => {
        // Modify the workbook
        // Timing data:
        // const timingParams = [
        //   "workdeskTime",
        //   "changeoverTime",
        //   "loadTime",
        //   "waitTime",
        //   "moveTime"
        // ];
        const sheet = workbook.sheet("Sheet1");
        sheet
          .row(1)
          .style("bold", true)
          .style("fill", "fbacad")
          .style("fontColor", "192e30")
          .style("wrapText", true);
        // Add the stations names on top
        Object.values(this.currentGame.facilities).forEach((faci, faciRank) => {
          sheet.cell(1, 1 + faciRank).value(faci.name);
        });

        // Write to file.
        return workbook.outputAsync().then(blob => {
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            // If IE, you must uses a different method.
            window.navigator.msSaveOrOpenBlob(blob, "leangame_data.xlsx");
          } else {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.href = url;
            a.download = "leangame_stats.xlsx";
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
          }
        });
      });
    }
  },
  data() {
    return {
      // stockEvolPerSecond: false,
      keyStockGraph: 0,
      stockStepPrecision: 60,
      hidePrinting: false,
      checkRecapProducts: [],
      checkStockFaci: [],
      checkTimeMetrics: [],
      allStats: null
      // questions: [
      //   {
      //     text:
      //       "Which parameters may have an influence on the Bullwhip effect ?",
      //     answers: [
      //       "Lead times make supply-chains less reactive to changes of demand. This also increases the tendency to 'secure' stock and order more than needed.",
      //       "The number of stages in the chain has a negative impact. Each level tries to avoid backorders and secure their own stock, which creates tension in the chain.",
      //       "Batch sizes and Minimum Order Quantities (MOQ) reduce costs (thanks to economies of scale) - but reduce flexibility.",
      //       "The lack of visibility on the stocks/demand make it impossible to anticipate production. When industrials master their whole supply-chain's capacity, they can take better stock decisions."
      //     ],
      //     toggle: false
      //   },
      // ]
    };
  }
};
</script>

<style lang="scss">
.lean-debrief {
  padding-bottom: 2em;
  .card .field-label.is-normal {
    margin-right: 0.5rem;
  }
  .container.is-max-print {
    width: 1000px !important;
    max-width: 100%;
  }
  .intro-section {
    padding-top: 0.5em;
    padding-bottom: 1em;
    .tile.box {
      display: flex;
      flex-direction: column;
    }
    .content ul {
      margin-top: 0.1em;
      margin-bottom: 0.5em;
    }
  }
}
</style>
