import { Bar } from "vue-chartjs";
import { mapGetters } from "vuex";
// import tinycolor from "tinycolor2";
// const { reactiveProp } = mixins;
function formatTime(millis) {
  let minutes = Math.max(0, Math.floor(millis / (60 * 1000)));
  let seconds = Math.max(
    0,
    Math.floor((10 * (millis - minutes * 60000)) / 1000) / 10
  );
  return (
    (minutes < 10 ? "0" + minutes : minutes) +
    ":" +
    (seconds < 10 ? "0" + seconds : seconds)
  );
}

export default {
  name: "compareChart",
  extends: Bar,
  //   mixins: [reactiveProp],
  props: {
    takt: Number,
    stats: Object,
    detail: Boolean,
    filterFaci: String,
    game: Object,
    metrics: Array
  },
  methods: {
    launchUpdate() {
      this.renderChart(this.chartData, this.options);
    }
  },
  computed: {
    ...mapGetters(["leanParams"]),
    chartData() {
      if (!this.stats) {
        return {};
      }
      let metricsObj = {};
      let faciLabels = Object.keys(this.stats.all.faciData)
        .sort((fa, fb) => fa - fb)
        .filter(fid => !this.filterFaci || this.filterFaci === fid)
        .map(fid => this.game.facilities[fid].name);
      Object.entries(this.stats.all.faciData)
        .sort((taba, tabb) => taba[0] - tabb[0])
        .filter(ftab => !this.filterFaci || this.filterFaci === ftab[0])
        .forEach(faciTab => {
          Object.keys(faciTab[1]).forEach(timeMetric => {
            if (this.leanParams.allMetricsParams[timeMetric]) {
              if (!metricsObj[timeMetric]) {
                metricsObj[timeMetric] = [];
              }
              let denom = this.stats.all.faciData[faciTab[0]].nbProducts;
              metricsObj[timeMetric].push(faciTab[1][timeMetric] / denom);
            }
          });
        });
      let metricsSet = [];
      if (this.takt) {
        metricsSet.push({
          label: "Takt time",
          backgroundColor: "transparent",
          pointBorderColor: "transparent",
          borderColor: "red",
          data: faciLabels.map(() => this.takt),
          type: "line"
        });
      }
      Object.entries(metricsObj).forEach(tabMetric => {
        if (
          this.leanParams.allMetricsParams[tabMetric[0]] &&
          this.metrics.includes(tabMetric[0])
        ) {
          metricsSet.push({
            label: this.leanParams.allMetricsParams[tabMetric[0]].label,
            backgroundColor: this.leanParams.allMetricsParams[tabMetric[0]]
              .color,
            data: tabMetric[1],
            stack: 0
          });
        }
      });
      return {
        labels: faciLabels,
        datasets: metricsSet
      };
    },
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: { display: true },
        tooltips: {
          mode: "label",
          callbacks: {
            label(tooltipItem, data) {
              var label = data.datasets[tooltipItem.datasetIndex].label || "";
              if (label === "Takt time") {
                return "";
              }
              if (label) {
                label += ": ";
              }
              label += formatTime(tooltipItem.yLabel);
              return label;
            },
            footer: (tooltipHover, data) => {
              let faciRank = tooltipHover[0].index;
              let tot = 0;
              data.datasets.forEach(ds => {
                if (ds.label !== "Takt time") {
                  tot += ds.data[faciRank];
                }
              });
              return "Station Total: " + formatTime(tot);
            }
          }
        },
        scales: {
          yAxes: [
            {
              display: true,
              stacked: true,
              labelString: "Duration (mm:ss)",
              ticks: {
                suggestedMin: 0,
                callback(value) {
                  return formatTime(value);
                }
              }
            }
          ]
        }
      };
    }
  },
  watch: {
    metrics() {
      this.renderChart(this.chartData, this.options);
    }
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  }
};
