var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentGame)?_c('div',{staticClass:"game-debrief",style:({
    'background-color': _vm.custoLook.bodyBckColor
  })},[_c('section',{staticClass:"section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"columns mb0"},[_c('div',{staticClass:"column"},[_c('h1',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.currentGame.name,{ onlyFirstLetter: true }))+" ")]),_c('h1',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.creationDate)+" "),(_vm.currentGame.finished)?_c('span',{staticClass:"ml-3 has-text-danger"},[_c('b-icon',{attrs:{"icon":"check-circle","size":"is-small"}}),_c('span',[_vm._v(" Finished. ")])],1):_vm._e()])]),_c('div',{staticClass:"column is-narrow"},[(_vm.currentGame && _vm.boxes[_vm.currentGame.type])?_c(_vm.boxes[_vm.currentGame.type],{tag:"component"}):_vm._e()],1)]),(!_vm.isPrinting)?_c('div',[_c('b-notification',{attrs:{"active":_vm.showError,"type":"is-danger"},on:{"close":_vm.closeError}},[_vm._v(_vm._s(_vm.showError ? _vm.error.message : ""))]),_c('div',{staticClass:"columns is-centered"},[(
              _vm.currentGame.creator &&
                !_vm.hasSubscribed &&
                (!_vm.currentUser || !_vm.currentUser.email)
            )?_c('div',{staticClass:"column is-two-thirds"},[_c('div',{staticClass:"box is-relative"},[_c('button',{staticClass:"delete",attrs:{"aria-label":"close"},on:{"click":function($event){_vm.hasSubscribed = true}}}),_c('p',{staticClass:"mb-1"},[_vm._v(" For more news about our simulations, subscribe to our newsletter! ")]),_c('p',{staticClass:"mb-3 is-italic is-size-7"},[_vm._v(" We send max 1 email per month and you can unsubscribe anytime. ")]),_c('b-field',{attrs:{"grouped":"","position":"is-centered","type":{ 'is-danger': _vm.$v.subscriptionEmail.$error },"message":!_vm.$v.subscriptionEmail.$error
                    ? ''
                    : !_vm.$v.subscriptionEmail.required
                    ? 'Email is required'
                    : 'Wrong email format'}},[_c('b-input',{staticStyle:{"width":"300px","max-width":"60%"},attrs:{"type":"email","placeholder":"email@company.com"},model:{value:(_vm.$v.subscriptionEmail.$model),callback:function ($$v) {_vm.$set(_vm.$v.subscriptionEmail, "$model", $$v)},expression:"$v.subscriptionEmail.$model"}}),_c('p',{staticClass:"control"},[_c('b-button',{attrs:{"type":"is-primary"},on:{"click":_vm.clickSubscribe}},[_vm._v("Subscribe")])],1)],1)],1)]):_vm._e(),(!_vm.currentGame.creator && !_vm.closeThanksFree)?_c('div',{staticClass:"column is-three-quarters"},[_c('div',{staticClass:"box is-relative"},[_c('button',{staticClass:"delete",attrs:{"aria-label":"close"},on:{"click":function($event){_vm.closeThanksFree = true}}}),_c('h4',{staticClass:"title is-4 mb-2"},[_vm._v(" Thanks for trying out a free trial game ")]),(
                  !_vm.currentGame.creator && (!_vm.currentUser || !_vm.currentUser.email)
                )?_c('div',{staticClass:"is-size-5"},[_vm._v(" To create your own customized simulations, create a free account now! "),_c('div',{staticClass:"buttons is-centered mt-2"},[_c('b-button',{attrs:{"size":"is-medium","rounded":"","type":"is-primary"},on:{"click":_vm.goSignup}},[_vm._v(" Sign up ")])],1)]):_vm._e()])]):_vm._e()])],1):_vm._e(),(_vm.currentGame && _vm.debriefs[_vm.currentGame.type])?_c(_vm.debriefs[_vm.currentGame.type],{tag:"component",on:{"printing":function (ev) { return (_vm.isPrinting = ev); }}}):_vm._e()],1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }