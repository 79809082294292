<template>
  <div class="game-status-box-wrapper box">
    <div class="level">
      <div class="level-item">
        <b-tooltip
          style="cursor: help;"
          label="Game Timer"
          type="is-dark"
          position="is-bottom"
        >
          <div
            class="title is-3 has-text-centered mb-1"
            :class="{
              'has-text-grey-light': !currentGame.params.game_start_at
            }"
          >
            <timer-elapsed
              ref="maintimer"
              :is-active="
                currentGame.params.game_start_at &&
                !currentGame.params.game_end_at
                  ? true
                  : false
              "
              :start-sec="
                currentGame.params.game_start_at
                  ? currentGame.params.game_start_at.seconds
                  : 0
              "
              :end-sec="
                currentGame.params.game_end_at
                  ? currentGame.params.game_end_at.seconds
                  : 0
              "
            ></timer-elapsed>
          </div>
        </b-tooltip>
      </div>
      <div class="level-item">
        <b-tooltip
          style="cursor: help;"
          label="Quantity delivered / Quantity ordered"
          type="is-dark"
          position="is-bottom"
          size="is-small"
          multilined
        >
          <p class="has-text-centered">
            <b-icon icon="face-agent" size="is-small"></b-icon>
            Delivered:<br />{{ statusOrders.done }}/{{ statusOrders.all }} pcs
          </p>
        </b-tooltip>
      </div>
      <div class="level-item">
        <b-tooltip
          style="cursor: help;"
          label="Total number of units being processed in the workshop"
          type="is-dark"
          position="is-bottom"
          size="is-small"
          multilined
        >
          <p class="has-text-centered">
            <b-icon icon="triangle" size="is-small"></b-icon>
            WIP:<br />{{ allStock }} pcs
          </p>
        </b-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TimerElapsed from "./TimerElapsed.vue";

export default {
  components: { TimerElapsed },
  name: "GameStatusBox",
  computed: {
    ...mapGetters(["currentGame", "currentProducts", "currentLeanGameOrders"]),
    allStock() {
      return this.currentProducts.filter(prod => !prod.fulfilled_at).length;
    },
    statusOrders() {
      let retObj = { all: 0, done: 0 };
      this.currentLeanGameOrders.forEach(order => {
        retObj.all += order.qty;
        retObj.done += order.fulfilled_at ? order.qty : 0;
      });
      return retObj;
    }
  }
};
</script>

<style lang="scss">
.game-status-box-wrapper {
  padding: 0.5em;
}
</style>
