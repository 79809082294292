<template>
  <div
    class="lean-orders-wrapper mt-2 mx-1"
    :class="{ 'bottom-border': !recap }"
  >
    <div class="box py-3 mb-3" v-if="recap">
      <div class="level mb-1">
        <div class="level-item">
          <h3 class="title has-text-grey is-3">
            <span class="has-text-info">{{ recapCustomerOrders.done }}</span
            >/{{ recapCustomerOrders.all }} pcs
            <b-icon icon="arrow-right"></b-icon>
            <em class="has-text-info">
              {{
                recapCustomerOrders.all
                  ? recapCustomerOrders.done / recapCustomerOrders.all
                  : 0 | percent
              }}
            </em>
            <h3 class="subtitle has-text-dark is-6">Units sold</h3>
          </h3>
        </div>
        <div class="level-item">
          <h3 class="title has-text-grey is-3">
            <span class="has-text-info">{{ recapCustomerOrders.otif }}</span
            >/{{ recapCustomerOrders.all }} pcs
            <b-icon icon="arrow-right"></b-icon>
            <em class="has-text-info">
              {{
                recapCustomerOrders.all
                  ? recapCustomerOrders.otif / recapCustomerOrders.all
                  : 0 | percent
              }}
            </em>
            <h3 class="subtitle has-text-dark is-6">
              Sold on-time and conform
            </h3>
          </h3>
        </div>
      </div>
      <p class="has-text-centered is-italic is-size-5">
        <b
          :class="{
            'has-text-danger': recapCustomerOrders.late,
            'has-text-success': !recapCustomerOrders.late
          }"
          ><b-icon icon="clock-alert" size="is-small"></b-icon>
          {{
            recapCustomerOrders.late | pluralize("pc", { includeNumber: true })
          }}</b
        >
        delivered late,
        <b
          :class="{
            'has-text-danger': recapCustomerOrders.defective,
            'has-text-success': !recapCustomerOrders.defective
          }"
          ><b-icon icon="sim-alert" size="is-small"></b-icon>
          {{
            recapCustomerOrders.defective
              | pluralize("pc", { includeNumber: true })
          }}</b
        >
        delivered with defects
      </p>
    </div>
    <b-collapse aria-id="ordersTableId" animation="slide" :open="!recap">
      <div
        v-if="recap"
        class="has-text-centered is-5"
        slot="trigger"
        slot-scope="props"
      >
        <b-button
          :icon-right="props.open ? 'menu-down' : 'menu-up'"
          aria-controls="ordersTableId"
          type="is-info"
          rounded
          >See detail</b-button
        >
      </div>
      <div class="box max-height-400 mt-2 py-2 px-2">
        <b-table
          class="has-background-white"
          id="clientorders-table"
          :data="clientOrdersList"
          :narrowed="true"
          :row-class="row => row.rowClass"
          :mobile-cards="false"
        >
          <b-table-column v-slot="props" custom-key="rank" width="30px">
            <em class="has-text-grey">#{{ props.row.rank }}</em>
          </b-table-column>
          <b-table-column v-slot="props" label="Created:">
            <em>{{ (props.row.created_min * 60000) | minsec }}</em>
          </b-table-column>

          <b-table-column v-slot="props" label="Due:">
            <em
              :class="{
                'has-text-danger has-text-weight-bold': props.row.is_too_late
              }"
              >{{ (props.row.due_min * 60000) | minsec }}</em
            >
          </b-table-column>
          <b-table-column v-slot="props" label="Detail:">
            {{
              props.row.product_name | capitalize({ onlyFirstLetter: true })
            }}:
            <b>{{ props.row.qty }} pcs</b>
          </b-table-column>
          <b-table-column v-slot="props" label="Status:">
            <b-icon
              v-if="!props.row.fulfilled_at"
              class="has-text-grey"
              icon="close"
              size="is-small"
            ></b-icon>
            <b-tooltip
              v-if="props.row.fulfilled_at"
              style="cursor: help;"
              multilined
              size="is-small"
              type="is-dark"
              position="is-bottom"
              :label="
                `Order fulfilled ` +
                  (props.row.is_on_time ? `on time ` : `late `) +
                  (props.row.defectProducts
                    ? `with ${props.row.defectProducts} defective product${
                        props.row.defectProducts > 1 ? 's' : ''
                      }.`
                    : `with no defect.`)
              "
            >
              <b
                :class="{
                  'has-text-success':
                    props.row.is_on_time && !props.row.defectProducts,
                  'has-text-danger':
                    !props.row.is_on_time || props.row.defectProducts
                }"
              >
                <b-icon icon="check" size="is-small"></b-icon>
                {{ (props.row.fulfilled_min * 60000) | minsec }}
                <b-icon
                  v-if="!props.row.is_on_time"
                  icon="clock-alert"
                  size="is-small"
                ></b-icon>
                <b-icon
                  v-if="props.row.defectProducts > 0"
                  icon="sim-alert"
                  size="is-small"
                ></b-icon>
              </b>
            </b-tooltip>
          </b-table-column>
          <b-table-column
            v-slot="props"
            custom-key="Action"
            :visible="isActive"
          >
            <div class="is-relative">
              <b-icon
                v-if="
                  !props.row.fulfilled_at &&
                    (!game.params.consumerDemandParams.firstonly ||
                      props.index <= recapCustomerOrders.nextRank) &&
                    props.row.check_enough
                "
                class="lean-moving-order-arrow"
                icon="arrow-right-bold"
                type="is-primary"
                size="is-large"
              ></b-icon>
              <b-button
                @click="fulfillOrder(props.row)"
                v-if="
                  !props.row.fulfilled_at &&
                    (!game.params.consumerDemandParams.firstonly ||
                      props.index <= recapCustomerOrders.nextRank)
                "
                size="is-small"
                type="is-info"
                :disabled="!props.row.check_enough"
                >Fulfill</b-button
              >
            </div>
          </b-table-column>
        </b-table>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import firebase from "@/firebaseConfig";

export default {
  name: "LeanOrdersTable",
  computed: {
    clientOrdersList() {
      return [...this.orders]
        .sort((a, b) => {
          return a.created_min - b.created_min;
        })
        .map((order, ind) => {
          let timeElapsed = this.game.params.game_start_at
            ? Math.ceil(
                (Date.now() / 1000 - this.game.params.game_start_at.seconds) / 6
              ) / 10
            : 0;
          let fulMin =
            order.fulfilled_at && this.game.params.game_start_at
              ? Math.max(
                  0,
                  Math.ceil(
                    (order.fulfilled_at.seconds -
                      this.game.params.game_start_at.seconds) /
                      6
                  ) / 10
                )
              : null;
          return {
            ...order,
            rank: ind + 1,
            fulfilled_min: fulMin,
            rowClass: order.fulfilled_at ? "has-text-grey-light" : "",
            is_too_late:
              timeElapsed > order.created_min + order.obj_lead_time &&
              !order.fulfilled_at,
            is_on_time:
              fulMin < order.created_min + order.obj_lead_time ? true : false,
            check_enough: this.checkOrderSelec(order),
            due_min: order.created_min + order.obj_lead_time,
            product_name: this.game.params.productsTemplates[order.template_id]
              .name,
            qty: order.qty
          };
        });
    },
    recapCustomerOrders() {
      let globals = {
        all: 0,
        allorders: 0,
        done: 0,
        doneorders: 0,
        otif: 0,
        late: 0,
        defective: 0,
        nextRank: 0
      };
      this.clientOrdersList.forEach((order, ind) => {
        globals.all += order.qty;
        globals.allorders += 1;
        let checkDef = order.defectProducts ? order.defectProducts : 0;
        if (order.fulfilled_at) {
          globals.done += order.qty;
          globals.nextRank = ind + 1;
          globals.doneorders += 1;
          if (!order.is_on_time) {
            globals.late += order.qty;
          } else {
            globals.otif += order.qty - checkDef;
          }
          if (order.defectProducts) {
            globals.defective += checkDef;
          }
        }
      });
      return globals;
    }
  },
  methods: {
    checkOrderSelec(order) {
      let selecSum = {};
      if (!this.wipProducts || !this.wipProducts.all.length) {
        return;
      }
      let wipTab = this.wipProducts.selected.length
        ? this.wipProducts.selected
        : this.wipProducts.all;
      wipTab.forEach(prod => {
        if (!selecSum[prod.template_id]) {
          selecSum[prod.template_id] = 0;
        }
        selecSum[prod.template_id] += 1;
      });
      let checkEnough =
        selecSum[order.template_id] && selecSum[order.template_id] >= order.qty;
      return checkEnough;
    },
    fulfillOrder(order) {
      if (!this.wipProducts || !this.wipProducts.all.length) {
        return;
      }
      const timestamp = firebase.firestore.Timestamp.now();
      let orderProdIds = { all: [], finalCheck: true };
      orderProdIds[order.template_id] = { need: order.qty, tab: [] };
      let wipTab = this.wipProducts.selected.length
        ? this.wipProducts.selected
        : this.wipProducts.all;
      let defectProducts = 0;
      let defectOpes = 0;
      wipTab.forEach(prod => {
        if (
          prod.template_id === order.template_id &&
          !prod.fulfilled_at &&
          orderProdIds[prod.template_id].tab.length <
            orderProdIds[prod.template_id].need
        ) {
          let checkOpe = true;
          prod.operations.forEach(ope => {
            if (ope.status !== "Successful") {
              checkOpe = false;
              defectOpes += 1;
            }
          });
          defectProducts += checkOpe ? 0 : 1;
          orderProdIds[prod.template_id].tab.push(prod.id);
          // Update moves tab for the product
          let newMovesTab = [...prod.stats.moves];
          newMovesTab[newMovesTab.length - 1].leave_at = timestamp;
          orderProdIds.all.push({
            pid: prod.id,
            gid: this.game.id,
            obj: {
              fulfilled_at: timestamp,
              [`stats.moves`]: newMovesTab
            }
          });
        }
      });
      orderProdIds.finalCheck =
        orderProdIds[order.template_id].tab.length >= order.qty;
      if (orderProdIds.finalCheck && orderProdIds.all.length) {
        let newConsumDemand = [...this.game.params.consumerDemand];
        newConsumDemand.forEach(ord => {
          if (
            ord.created_min === order.created_min &&
            ord.template_id === order.template_id
          ) {
            ord.fulfilled_at = timestamp;
            ord.defectProducts = defectProducts;
            ord.defectOpes = defectOpes;
          }
        });
        this.$store.dispatch("updateProductsDocs", orderProdIds.all);
        this.$store
          .dispatch("updateGameDoc", [
            {
              obj: { [`params.consumerDemand`]: newConsumDemand },
              gid: this.game.id
            }
          ])
          .then(() => {
            this.$buefy.toast.open(
              defectOpes
                ? "You fulfilled a customer order but some products contained defects !"
                : "Congrat's ! You just fulfilled a customer order"
            );
            this.$emit("sentorder");
          });
      } else {
        this.$buefy.toast.open("Can't fulfill the order");
      }
    }
  },
  props: {
    recap: Boolean,
    wipProducts: Object,
    isActive: Boolean,
    game: Object,
    orders: Array,
    products: Array
  },
  data() {
    return {
      // openDetail:true
    };
  }
};
</script>

<style lang="scss">
.inventory-shipping-station-wrapper {
  @keyframes leftright {
    from {
      transform: translateX(-10px);
    }
    to {
      transform: translateX(0px);
    }
  }
  .lean-moving-order-arrow {
    position: absolute;
    right: 100%;
    bottom: -20%;
    animation: leftright 0.8s ease-in-out infinite alternate;
  }
}
</style>
