<template>
  <div class="stats-table">
    <b-button
      type="is-white"
      size="is-medium"
      class="has-text-success"
      :icon-right="isOpen ? 'menu-up' : 'menu-down'"
      v-if="nbtab > 1"
      @click="isOpen = !isOpen"
      >Detailed history
    </b-button>
    <b-table
      v-if="isOpen || nbtab <= 1"
      :data="myData"
      :columns="myColumns"
      narrowed
      striped
      class="is-size-7"
      :mobile-cards="false"
    >
    </b-table>
  </div>
</template>

<script>
export default {
  name: "StatsTable",
  props: {
    stats: Object,
    currency: String,
    metrics: Array,
    nbtab: Number,
    timeUnit: String
  },
  computed: {
    myData() {
      let retTab = [];
      if (!this.stats || !this.stats.week.length) {
        return [];
      }
      let weeksTab = this.stats["week"];
      weeksTab
        .sort((x, y) => {
          return y - x;
        })
        .map(weekRank => {
          let histoElem = { week: weekRank };
          this.metrics.forEach(metric => {
            let curr = "";
            if (metric === "cost" || metric === "revenue") {
              curr = this.currency;
            }
            if (this.stats[metric]) {
              histoElem[metric] = this.$options.filters.currency(
                this.stats[metric][weekRank],
                curr
              );
            }
          });
          retTab.push(histoElem);
        });
      return retTab;
    },
    myColumns() {
      let colTab = [];
      let metTabCC = this.metrics;
      colTab.push({
        field: "week",
        label: this.timeUnit,
        id: 0,
        centered: true
      });
      metTabCC.forEach(metric => {
        if (this.stats && this.stats[metric]) {
          colTab.push({
            field: metric,
            label: this.metricsLabels[metric],
            id: this.colSort[metric],
            numeric: true
          });
        }
      });
      return colTab.sort((x, y) => {
        return x.id - y.id;
      });
    }
  },
  data() {
    return {
      isOpen: false,
      colSort: {
        stock: 6,
        backlog: 7,
        order: 1,
        demand: 2,
        delivery: 3,
        shipment: 4,
        cost: 8,
        revenue: 9,
        missed: 5
      },
      metricsLabels: {
        stock: "Stock",
        backlog: "Backorder",
        order: "Order",
        demand: "Demand",
        delivery: "Receipt",
        shipment: "Shipment",
        cost: "Cost",
        revenue: "Revenue",
        missed: "Missed Sales"
      }
    };
  }
};
</script>

<style lang="scss">
.stats-table {
  .button {
    margin-bottom: 0.5em;
  }
}
</style>
