<template>
  <div
    class="faci-calc-panel-wrapper"
    :id="`faci-calc-panel-${faci.id}`"
    v-if="faci"
  >
    <div
      class="last-faci-hover is-size-6-mobile"
      @click="$store.commit('setShowNewTurnDetails', myFaci ? true : false)"
    >
      <b-tooltip
        class="is-block"
        type="is-dark"
        :active="myFaci ? true : false"
        :label="myFaci ? 'See new turn animation' : ''"
        position="is-bottom"
      >
        <div class="columns is-mobile mt-0 mx-0">
          <div class="column has-text-centered py-0 px-0">
            <b-icon icon="triangle" size="is-small"></b-icon>
            <span
              :class="{ 'has-text-weight-semibold': myFaci && faci.stock > 0 }"
            >
              Stock:
            </span>
            <em class="has-text-grey is-size-7"
              >{{
                week > 0 && statsFaci[week - 1]
                  ? statsFaci[week - 1].stock
                  : statsFaci[0].stock
              }}<b-icon
                icon="arrow-right"
                size="is-small"
                class="mx-0 data-appear-next"
              ></b-icon>
            </em>
            <b class="data-appear-next">{{ faci.stock }}</b>
          </div>
          <div
            v-if="
              (week > 0 &&
                statsFaci[week - 1] &&
                statsFaci[week - 1].backlog) ||
                faci.backlog
            "
            class="column has-text-centered py-0 px-0"
            :class="{ 'has-text-danger': faci.backlog }"
          >
            <b-icon icon="clock" size="is-small"></b-icon>
            <span
              :class="{ 'has-text-weight-semibold': myFaci && faci.backlog }"
            >
              Backorder:
            </span>
            <em class="has-text-grey is-size-7"
              >{{
                week > 0 && statsFaci[week - 1]
                  ? statsFaci[week - 1].backlog
                  : statsFaci[0].backlog
              }}<b-icon
                icon="arrow-right"
                size="is-small"
                class="mx-0 data-appear-next"
              ></b-icon>
            </em>
            <b class="data-appear-next">{{ faci.backlog }} </b>
          </div>
        </div>
        <!-- <div class="faci-divider"></div> -->
        <div class="columns is-mobile mt-0 mx-0">
          <div class="data-appear column has-text-centered py-0 px-0">
            <b-icon icon="email" size="is-small"></b-icon>
            Demand:
            <b class="has-text-primary">
              -{{ statsFaci[week] ? statsFaci[week].demand || 0 : "" }}
            </b>
          </div>
          <div class="data-appear column has-text-centered py-0 px-0">
            <b-icon
              :icon="faci.type === 'factory' ? 'wrench' : 'truck'"
              size="is-small"
            ></b-icon>
            {{ faci.type === "factory" ? "Production" : "Receipt" }}:
            <b class="has-text-success">
              +{{ statsFaci[week] ? statsFaci[week].delivery || 0 : "" }}
            </b>
          </div>
        </div>
        <div class="columns my-0 mx-0">
          <div
            class="column has-text-centered data-appear py-0 px-0"
            :class="{ 'has-text-danger': faci.backlog || missedSale }"
          >
            <b-icon
              :icon="faci.backlog || missedSale ? 'close' : 'check'"
              size="is-small"
            ></b-icon>
            <span v-if="faci.allowBacklog">Outgoing</span> Shipment:
            <b class="has-text-info">
              {{ statsFaci[week] ? statsFaci[week].shipment || 0 : "" }}
            </b>
            <b-tooltip
              class="is-hidden-touch"
              style="cursor: help;"
              multilined
              type="is-dark"
              :label="shipmentInfo"
            >
              <b-icon size="is-small" icon="help-circle-outline"></b-icon>
            </b-tooltip>
          </div>
          <div
            class="column py-0 px-0 has-text-centered data-appear"
            v-if="!faci.allowBacklog && missedSale"
          >
            <b-icon type="is-danger" icon="alert" size="is-small"></b-icon>
            Missed Sales:
            <b class="has-text-danger">
              {{ missedSale }}
            </b>
          </div>
        </div>
      </b-tooltip>
    </div>

    <b-modal
      :active="showNewTurnDetails && myFaci"
      trap-focus
      @close="$store.commit('setShowNewTurnDetails', false)"
    >
      <section
        v-if="statsFaci[week]"
        class="box is-size-5 is-size-7-mobile px-1-mobile mb-0"
      >
        <h5 class="title has-text-centered is-size-4-mobile mb-3">
          {{ week > 0 ? "New" : "First" }} Turn -
          {{ currentGame.params.timeUnit || "week" | capitalize }}
          <span v-if="week > 0">
            {{ week - 1 }}
            <b-icon icon="arrow-right" size="is-small"></b-icon>
          </span>
          {{ week }} :
        </h5>
        <div class="box-divider is-hidden-tablet mb-2 my-0"></div>
        <div class="box-divider is-hidden-mobile"></div>
        <div
          class="columns mb-0 is-mobile is-multiline is-centered is-vcentered is-italic"
        >
          <div class="column has-text-centered">
            <b-icon
              icon="triangle"
              size="is-small"
              class="is-hidden-mobile"
            ></b-icon>
            Stock:
            <strong class="has-text-grey"
              >{{
                week > 0 ? statsFaci[week - 1].stock : statsFaci[0].stock || 0
              }}
            </strong>
            <br />
            <span class="is-inline-block">
              <strong class="appear-elem appear-delivery has-text-success">
                +{{ statsFaci[week].delivery || 0 }} </strong
              >&nbsp;
              <strong class="appear-elem appear-shipment has-text-info">
                -{{ statsFaci[week].shipment || 0 }} </strong
              >&nbsp;
              <span class="appear-elem appear-last">
                <b-icon icon="arrow-right" size="is-small"></b-icon>
                <strong class="is-underlined">{{ faci.stock }} </strong>
              </span>
            </span>
          </div>
          <div
            class="column has-text-centered"
            v-if="(week > 0 && statsFaci[week - 1].backlog) || faci.backlog"
          >
            <b-icon
              icon="clock"
              size="is-small"
              class="is-hidden-mobile"
            ></b-icon>
            Backorder:
            <strong class="has-text-grey"
              >{{
                week > 0 ? statsFaci[week - 1].backlog : statsFaci[0].backlog
              }}
            </strong>
            <br />
            <span class="is-inline-block">
              <span class="appear-elem appear-last">
                <b-icon icon="arrow-right" size="is-small"></b-icon>
                <strong class="has-text-danger is-underlined"
                  >{{ faci.backlog }}
                </strong>
              </span>
            </span>
          </div>
          <div class="column has-text-centered">
            <b-icon
              icon="cash"
              size="is-small"
              class="is-hidden-mobile"
            ></b-icon>
            Cost:
            <strong class="has-text-grey"
              >{{
                (week > 0 ? statsFaci[week - 1].cost : 0)
                  | currency(currentGame.params.currency)
              }}
            </strong>
            <br />
            <b-tooltip
              :active="costInfo.mess && costInfo.mess.length ? true : false"
              multilined
              position="is-bottom"
              style="cursor: help;"
              type="is-dark"
              :label="costInfo.mess"
            >
              <strong
                class="appear-elem appear-last has-text-danger"
                v-for="(ci, cii) in costInfo.arr"
                :key="`cinfo-${cii}`"
              >
                +{{ ci | currency(currentGame.params.currency) }}</strong
              >
              &nbsp;
              <span class="appear-elem appear-last">
                <b-icon icon="arrow-right" size="is-small"></b-icon>
                <strong class="has-text-danger is-underlined"
                  >{{ faci.cost | currency(currentGame.params.currency) }}
                  <b-icon
                    class="is-hidden-touch"
                    size="is-small"
                    icon="help-circle-outline"
                    v-if="costInfo.mess && costInfo.mess.length"
                  ></b-icon>
                </strong>
              </span>
            </b-tooltip>
          </div>
          <div class="column has-text-centered" v-if="faci.unitPrice">
            <b-icon
              icon="cash"
              size="is-small"
              class="is-hidden-mobile"
            ></b-icon>
            Revenue:
            <strong class="has-text-grey"
              >{{
                (week > 0 ? statsFaci[week - 1].revenue : 0)
                  | currency(currentGame.params.currency)
              }}
            </strong>
            <br />
            <b-tooltip
              style="cursor: help;"
              multilined
              position="is-bottom"
              type="is-dark"
              :active="
                revenueInfo.mess && revenueInfo.mess.length ? true : false
              "
              :label="revenueInfo.mess"
            >
              <strong
                class="appear-elem appear-last has-text-success"
                v-for="(ri, rii) in revenueInfo.arr"
                :key="`rinfo-${rii}`"
              >
                +{{ ri | currency(currentGame.params.currency) }}</strong
              >
              &nbsp;
              <span class="appear-elem appear-last">
                <b-icon icon="arrow-right" size="is-small"></b-icon>
                <strong class="has-text-success is-underlined"
                  >{{ faci.revenue | currency(currentGame.params.currency) }}
                  <b-icon
                    size="is-small"
                    icon="help-circle-outline"
                    class="is-hidden-touch"
                    v-if="revenueInfo.mess && revenueInfo.mess.length"
                  ></b-icon>
                </strong>
              </span>
            </b-tooltip>
          </div>
        </div>
        <div class="box-divider is-hidden-tablet my-0"></div>
        <div class="box-divider is-hidden-mobile my-2"></div>
        <div class="mt-1 columns is-vcentered is-mobile">
          <div class="column has-text-grey has-text-centered px-0">
            <span v-if="!faci.client_id">
              <b-icon
                size="is-medium"
                class="is-hidden-mobile"
                icon="account"
              ></b-icon>
              <b-icon class="is-hidden-tablet" icon="account"></b-icon>
              <br />
              Consumer
              <br />
              <em class="is-size-6-tablet"
                ><span class="is-hidden-mobile">Your </span>Client</em
              >
            </span>
            <span v-if="faci.client_id">
              <b-icon
                size="is-medium"
                class="is-hidden-mobile"
                :icon="currentGame.facilities[faci.client_id].icon"
              ></b-icon>
              <b-icon
                class="is-hidden-tablet"
                :icon="currentGame.facilities[faci.client_id].icon"
              ></b-icon>
              <br />
              {{ currentGame.facilities[faci.client_id].name | capitalize }}
              <br />
              <em class="is-size-6-tablet"
                ><span class="is-hidden-mobile">Your </span>Client</em
              >
              <br />
              <b-tooltip
                style="cursor: help;"
                type="is-dark"
                :label="
                  `Lead time: ${
                    currentGame.facilities[faci.client_id].leadtime
                  } ${currentGame.params.timeUnit}`
                "
                position="is-bottom"
              >
                <em class="has-text-grey is-size-6-tablet"
                  >LT: {{ currentGame.facilities[faci.client_id].leadtime
                  }}{{ currentGame.params.timeUnit[0] }}</em
                >
              </b-tooltip>
            </span>
          </div>
          <div class="column is-2 is-size-6-tablet py-0 px-1">
            <div class="appear-elem demand-line">
              <div class="has-text-centered">
                <b-icon icon="email" size="is-small"></b-icon>Demand:
                <strong class="has-text-danger">
                  {{ statsFaci[week].demand || 0 }}
                </strong>
              </div>
              <svg class="svg-wrap-horizontal mb-4" width="100%" height="20px">
                <svg viewBox="0 0 1 2" preserveAspectRatio="xMaxYMid">
                  <polyline
                    fill="#fff"
                    stroke-width="0.1"
                    stroke="lightgrey"
                    points="0,0 1,1 0,2 "
                  />
                </svg>
                <svg viewBox="0 0 1 1" preserveAspectRatio="none">
                  <rect
                    x="0"
                    y="0.475"
                    width="1"
                    height="0.05"
                    fill="lightgrey"
                  />
                </svg>
              </svg>
            </div>
            <div class="my-5 is-hidden-mobile"></div>
            <div
              class="appear-elem shipment-line"
              :class="{ 'has-text-danger': faci.backlog || missedSale }"
            >
              <svg class="svg-wrap-horizontal mt-5" width="100%" height="20px">
                <svg viewBox="0 0 2 2" preserveAspectRatio="xMinYMid">
                  <polyline
                    fill="#fff"
                    stroke-width="0.1"
                    stroke="lightgrey"
                    points="1,0 0,1 1,2 "
                  />
                </svg>
                <svg viewBox="0 0 1 1" preserveAspectRatio="none">
                  <rect
                    x="0"
                    y="0.475"
                    width="1"
                    height="0.05"
                    fill="lightgrey"
                  />
                </svg>
              </svg>
              <div class="has-text-centered">
                <b-icon
                  icon="truck"
                  size="is-small"
                  custom-class="mdi-flip-h"
                ></b-icon
                >You Ship:
                <strong class="has-text-info">
                  {{ statsFaci[week].shipment || 0 }}
                </strong>
              </div>
              <div
                class="has-text-centered"
                v-if="!faci.allowBacklog && missedSale"
              >
                <b-icon
                  icon="alert"
                  size="is-small"
                  custom-class="mdi-flip-h"
                ></b-icon
                >Missed<span class="is-hidden-mobile"> Sales</span>:
                <strong class="has-text-danger">
                  {{ missedSale || 0 }}
                </strong>
              </div>
              <div class="mb-5 is-hidden-mobile"></div>
            </div>
          </div>
          <div class="column is-4 has-text-centered has-text-black">
            <b-icon
              size="is-medium"
              class="is-hidden-mobile"
              :icon="faci.icon"
            ></b-icon>
            <b-icon class="is-hidden-tablet" :icon="faci.icon"></b-icon>
            <br />
            {{ faci.name | capitalize }}
            <!-- <p class="mt-2 is-size-6 has-text-centered has-text-info">
              <em>Inventory: {{ modalAnimeData.centerStock }}pcs</em>
            </p> -->
            <faci-stock-box
              ref="facistockbox"
              class="mb-2 mt-2"
              :stats-faci="statsFaci"
              :faci="faci"
            ></faci-stock-box>
          </div>
          <div class="column px-1 is-2 is-size-6-tablet has-text-left">
            <div class="appear-elem order-line">
              <div class="has-text-centered">
                <b-icon icon="email" size="is-small"></b-icon>You Ordered:
                <strong class="has-text-black">
                  {{
                    statsFaci[week - 1]
                      ? statsFaci[week - 1].order
                      : faci.startOrders
                  }}
                </strong>
              </div>
              <svg class="svg-wrap-horizontal mb-5" width="100%" height="20px">
                <svg viewBox="0 0 1 2" preserveAspectRatio="xMaxYMid">
                  <polyline
                    fill="#fff"
                    stroke-width="0.1"
                    stroke="lightgrey"
                    points="0,0 1,1 0,2 "
                  />
                </svg>
                <svg viewBox="0 0 1 1" preserveAspectRatio="none">
                  <rect
                    x="0"
                    y="0.475"
                    width="1"
                    height="0.05"
                    fill="lightgrey"
                  />
                </svg>
              </svg>
            </div>
            <div class="my-5 is-hidden-mobile"></div>
            <div class="appear-elem receive-line">
              <svg class="svg-wrap-horizontal mt-5" width="100%" height="20px">
                <svg viewBox="0 0 2 2" preserveAspectRatio="xMinYMid">
                  <polyline
                    fill="#fff"
                    stroke-width="0.1"
                    stroke="lightgrey"
                    points="1,0 0,1 1,2 "
                  />
                </svg>
                <svg viewBox="0 0 1 1" preserveAspectRatio="none">
                  <rect
                    x="0"
                    y="0.475"
                    width="1"
                    height="0.05"
                    fill="lightgrey"
                  />
                </svg>
              </svg>
              <div class="has-text-centered">
                <b-icon
                  :icon="faci.supplier_id ? 'truck' : 'wrench'"
                  size="is-small"
                  custom-class="mdi-flip-h"
                ></b-icon
                >{{ faci.supplier_id ? "Receipt" : "Prod" }}:
                <strong class="has-text-success">
                  {{ statsFaci[week].delivery || 0 }}
                </strong>
                <b-tooltip
                  style="cursor: help;"
                  multilined
                  type="is-dark"
                  position="is-bottom"
                  :label="
                    `This receipt comes from an order sent ${faci.leadtime} ${currentGame.params.timeUnit}s ago`
                  "
                >
                  <b-icon size="is-small" icon="help-circle-outline"></b-icon>
                </b-tooltip>
              </div>
              <div class="mb-5 is-hidden-mobile"></div>
            </div>
          </div>
          <div class="column px-0 has-text-grey has-text-centered px-0">
            <span v-if="faci.supplier_id">
              <b-icon
                size="is-medium"
                class="is-hidden-mobile"
                :icon="currentGame.facilities[faci.supplier_id].icon"
              ></b-icon>
              <b-icon
                class="is-hidden-tablet"
                :icon="currentGame.facilities[faci.supplier_id].icon"
              ></b-icon>
              <br />
              {{ currentGame.facilities[faci.supplier_id].name | capitalize }}
              <br />
              <em class="is-size-6-tablet"
                ><span class="is-hidden-mobile">Your </span>Supplier</em
              >
            </span>
            <span v-if="!faci.supplier_id">
              <b-icon
                size="is-medium"
                class="is-hidden-mobile"
                icon="cog"
              ></b-icon>
              <b-icon class="is-hidden-tablet" icon="cog"></b-icon>
              <br />
              Production
            </span>
            <br />
            <b-tooltip
              style="cursor: help;"
              type="is-dark"
              :label="
                `Lead time: ${faci.leadtime} ${currentGame.params.timeUnit}s`
              "
              position="is-bottom"
            >
              <em class="has-text-grey is-size-6-tablet"
                >LT: {{ faci.leadtime }}{{ currentGame.params.timeUnit[0] }}</em
              >
            </b-tooltip>
          </div>
        </div>
        <div class="box-divider is-hidden-tablet mt-0 mb-2"></div>
        <div class="box-divider is-hidden-mobile"></div>
        <div class="has-text-centered">
          <span class="appear-elem appear-last is-size-6-tablet">
            <b-icon
              :type="faci.backlog || missedSale ? 'is-danger' : 'is-success'"
              :icon="faci.backlog || missedSale ? 'close' : 'check'"
              size="is-small"
            ></b-icon>
            {{ shipmentInfo }}
          </span>
        </div>
        <div class="box-divider is-hidden-tablet my-2"></div>
        <div class="box-divider is-hidden-mobile"></div>
        <div class="pl-5 columns is-relative has-text-centered">
          <div class="column is-hidden-mobile pt-5">
            <b-field position="is-centered" grouped>
              <p class="control">
                <b-button
                  type="is-info"
                  size="is-small"
                  @click="animateNewTurnDetails"
                  rounded
                  icon-left="reload"
                  >See animation again</b-button
                >
              </p>
              <b-select
                v-model="selectSpeed"
                type="is-info is-light"
                size="is-small"
                rounded
              >
                <option
                  v-for="ss in speedList"
                  :key="ss.duration"
                  :value="ss.duration"
                  >{{ ss.name }}</option
                >
              </b-select>
            </b-field>
          </div>
          <div class="column is-hidden-mobile">
            <b-button
              rounded
              ref="closebtn"
              size="is-large"
              type="is-primary"
              :style="{
                'background-color': custoLook.btnBestColor,
                color: 'white'
              }"
              @click="$store.commit('setShowNewTurnDetails', false)"
              >Next: Decide Order</b-button
            >
          </div>
          <div class="column">
            <b-checkbox
              v-model="autoShowModalLocalVar"
              @input="
                val =>
                  $store.commit(
                    'setAutoShowNewTurnModal',
                    autoShowModalLocalVar
                  )
              "
              class="is-size-6 pt-3"
            >
              Show each {{ currentGame.params.timeUnit }}
            </b-checkbox>
          </div>
        </div>
      </section>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import anime from "animejs/lib/anime.es.js";
import FaciStockBox from "./FaciStockBox.vue";

export default {
  name: "FaciCalcPanel",
  components: { FaciStockBox },
  props: {
    faci: Object,
    myFaci: Boolean,
    animeData: Object,
    rankBacklog: Number
  },
  computed: {
    ...mapGetters([
      "currentFacilities",
      "currentGame",
      "currentUser",
      "currentMessages",
      "currentStats",
      "showOnboarding",
      "custoLook",
      "showNewTurnDetails",
      "autoShowNewTurnModal"
    ]),
    missedSale() {
      if (
        this.faci.allowBacklog ||
        this.week <= 0 ||
        !this.statsFaci[this.week]
      ) {
        return 0;
      }
      return Math.abs(
        Math.min(
          0,
          this.statsFaci[this.week - 1].stock +
            this.statsFaci[this.week].delivery -
            this.statsFaci[this.week].demand
        )
      );
    },
    week() {
      return this.currentGame.week;
    },
    statsFaci() {
      if (
        this.currentFacilities &&
        this.currentStats &&
        this.currentStats.length
      ) {
        let copyStats = [...this.currentStats];
        return copyStats
          .sort((sa, sb) => {
            return sa.week - sb.week;
          })
          .map(statElem => {
            return statElem.facilities[this.faci.id];
          });
      }
      return [
        {
          stock: this.faci.stock,
          backlog: this.faci.backlog,
          delivery: this.faci.startOrders,
          shipment: this.faci.startOrders,
          demand: this.faci.startOrders,
          order: this.faci.startOrders,
          cost: 0,
          revenue: 0
        }
      ];
    },
    supplierText() {
      if (this.currentFacilities && this.currentUser && this.faci.supplier_id) {
        let faciSupply = this.currentFacilities.find(faci => {
          return faci.id.toString() === this.faci.supplier_id.toString();
        });
        if (faciSupply) {
          return this.$options.filters.capitalize(faciSupply.name);
        }
      }
      return false;
    },
    clientText() {
      if (this.currentFacilities && this.currentUser && this.faci.client_id) {
        let faciClient = this.currentFacilities.find(faci => {
          return faci.id.toString() === this.faci.client_id.toString();
        });
        if (faciClient) {
          return this.$options.filters.capitalize(faciClient.name);
        }
      }
      return false;
    },
    costInfo() {
      let mess = ``;
      let arr = [];
      if (this.faci.stock && this.faci.stockCost) {
        arr.push(this.faci.stockCost * this.faci.stock);
        mess += `Stock cost: ${this.faci.stock} x ${
          this.currentGame.params.currency
        }${this.faci.stockCost} = +${this.currentGame.params.currency}${this
          .faci.stockCost * this.faci.stock}
          `;
      }
      if (this.faci.backlog && this.faci.backlogCost) {
        arr.push(this.faci.backlogCost * this.faci.backlog);
        mess += `Backorder cost: ${this.faci.backlog} x ${
          this.currentGame.params.currency
        }${this.faci.backlogCost} = +${this.currentGame.params.currency}${this
          .faci.backlogCost * this.faci.backlog}
        `;
      }
      if (this.missedSale && this.faci.missedSaleCost) {
        arr.push(this.faci.missedSaleCost * this.missedSale);
        mess += `Missed sales: ${this.missedSale} x ${
          this.currentGame.params.currency
        }${this.faci.missedSaleCost} = +${
          this.currentGame.params.currency
        }${this.faci.missedSaleCost * this.missedSale}
        `;
      }
      return { mess: mess, arr: arr };
    },
    revenueInfo() {
      return {
        mess: `Sales: ${this.statsFaci[this.week].shipment} x ${
          this.currentGame.params.currency
        }${this.faci.unitPrice} = +${this.currentGame.params.currency}${this
          .faci.unitPrice * this.statsFaci[this.week].shipment}`,
        arr: [this.faci.unitPrice * this.statsFaci[this.week].shipment]
      };
    },
    shipmentInfo() {
      let week = this.week;
      let prevStock =
        week > 0 && this.statsFaci[this.week - 1]
          ? this.statsFaci[this.week - 1].stock
          : this.statsFaci[0].stock;
      let delivery = this.statsFaci[this.week]
        ? this.statsFaci[this.week].delivery
        : 0;
      let avail = prevStock + delivery;
      let prevBacklog =
        week > 0 && this.statsFaci[this.week - 1]
          ? this.statsFaci[this.week - 1].backlog
          : this.statsFaci[0].backlog;
      let demand = this.statsFaci[this.week]
        ? this.statsFaci[this.week].demand
        : 0;
      if (demand === 0 && prevBacklog === 0) {
        return `No demand or backorders to fulfill this ${this.currentGame.params.timeUnit}. You don't need to ship anything.`;
      }
      if (avail >= demand + prevBacklog) {
        let stockOrRec =
          prevStock >= demand + prevBacklog ? "stock" : "stock/receipt";
        let demOrBack =
          prevBacklog === 0
            ? "the demand"
            : demand === 0
            ? "your backorders"
            : "the demand and your backorders";
        return `You had enough ${stockOrRec} to fulfill ${demOrBack} this ${this.currentGame.params.timeUnit}`;
      }
      if (avail < demand + prevBacklog) {
        let demOrBack = demand
          ? prevBacklog
            ? "the demand and your backorders"
            : "the demand"
          : "your backorders";
        if (prevStock && delivery) {
          return `You didn't have enough to fulfill ${demOrBack}, you only shipped the ${prevStock} pcs in stock and ${delivery} pcs received this ${this.currentGame.params.timeUnit}`;
        } else if (prevStock > 0) {
          return `You didn't have enough to fulfill ${demOrBack}, you only shipped the ${prevStock} pcs in stock`;
        } else if (delivery > 0) {
          return `You didn't have enough to fulfill ${demOrBack}, you only shipped the ${delivery} pcs received this ${this.currentGame.params.timeUnit}`;
        } else {
          return "You have no stock or receipt, you couldn't ship anything";
        }
      }
      return "";
    }
  },
  watch: {
    week() {
      if (
        this.autoShowNewTurnModal &&
        this.week < this.currentGame.params.totalWeeks &&
        this.myFaci
      ) {
        this.$store.commit("setShowNewTurnDetails", true);
      } else {
        if (this.myFaci) {
          this.$buefy.toast.open({
            duration: 1500,
            message: `${this.$options.filters.capitalize(
              this.currentGame.params.timeUnit
            )} ${this.week}`
          });
          this.$emit("animarrow");
        }
        this.animateNewTurnFaciData(this.selectSpeed);
      }
    },
    showOnboarding(newVal, oldVal) {
      if (!newVal && oldVal && this.myFaci && this.week === 0) {
        this.$store.commit("setShowNewTurnDetails", true);
      }
    },
    showNewTurnDetails(newVal, oldVal) {
      if (newVal && this.myFaci) {
        this.animateNewTurnDetails();
      }
      if (!newVal && oldVal) {
        this.$emit("animarrow");
        if (this.week === 0 && this.currentMessages.length) {
          this.$store.commit("setShowMessages", true);
        }
      }
    }
  },
  created() {
    this.modalAnimeData = {
      centerStock: this.faci.stock,
      stock: this.faci.stock,
      cost: this.faci.cost,
      backlog: this.faci.backlog,
      revenue: this.faci.revenue
    };
  },
  methods: {
    animateNewTurnDetails() {
      setTimeout(() => {
        this.animateNewTurnFaciData(20);
        if (this.$refs.closebtn && this.$refs.closebtn.$el) {
          this.$refs.closebtn.$el.focus();
        }
        let boxref = this.$refs.facistockbox;
        let mySpeed = this.selectSpeed;
        if (boxref) {
          boxref.resetStart();
        }
        if (this.week > 0) {
          anime({
            targets: `#faci-calc-panel-${this.faci.id} .appear-elem.order-line`,
            opacity: [0, 1],
            translateX: [-50, 0],
            duration: this.selectSpeed,
            easing: "easeInOutExpo"
          });
        }
        anime({
          targets: `#faci-calc-panel-${this.faci.id} .appear-elem.receive-line`,
          opacity: [0, 1],
          translateX: [50, 0],
          duration: this.selectSpeed,
          easing: "easeInOutExpo",
          delay: this.selectSpeed,
          changeBegin() {
            if (boxref) {
              boxref.animeReceive(mySpeed);
            }
          }
        });
        this.modalAnimeData.centerStock =
          this.week > 0 ? this.statsFaci[this.week - 1].stock : this.faci.stock;
        anime({
          targets: this.modalAnimeData,
          centerStock: [
            {
              value:
                (this.week > 0
                  ? this.statsFaci[this.week - 1].stock
                  : this.faci.stock) + this.statsFaci[this.week].delivery,
              round: 1,
              delay: this.selectSpeed,
              duration: this.selectSpeed,
              easing: "easeInOutExpo"
            },
            {
              value: Math.max(
                0,
                (this.week > 0
                  ? this.statsFaci[this.week - 1].stock
                  : this.faci.stock) +
                  this.statsFaci[this.week].delivery -
                  this.statsFaci[this.week].shipment
              ),
              delay: 2.5 * this.selectSpeed,
              duration: this.selectSpeed,
              easing: "easeInOutExpo",
              round: 1
            }
          ]
        });
        anime({
          targets: `#faci-calc-panel-${this.faci.id} .appear-elem.demand-line`,
          opacity: [0, 1],
          translateX: [-50, 0],
          duration: this.selectSpeed,
          easing: "easeInOutExpo",
          delay: 2 * this.selectSpeed
        });
        anime({
          targets: `#faci-calc-panel-${this.faci.id} .appear-elem.shipment-line`,
          opacity: [0, 1],
          translateX: [50, 0],
          duration: this.selectSpeed,
          easing: "easeInOutExpo",
          delay: 3 * this.selectSpeed,
          changeComplete() {
            if (boxref) {
              boxref.animeShip(mySpeed);
            }
          }
        });
        anime({
          targets: `#faci-calc-panel-${this.faci.id} .appear-elem.appear-delivery`,
          opacity: [0, 1],
          duration: this.selectSpeed,
          easing: "easeInOutExpo",
          delay: this.selectSpeed
        });

        anime({
          targets: `#faci-calc-panel-${this.faci.id} .appear-elem.appear-shipment`,
          opacity: [0, 1],
          duration: this.selectSpeed,
          easing: "easeInOutExpo",
          delay: 3 * this.selectSpeed
        });
        let elemstwo = document.querySelectorAll(
          `#faci-calc-panel-${this.faci.id} .appear-elem.appear-last`
        );
        anime({
          targets: elemstwo,
          opacity: [0, 1],
          duration: this.selectSpeed,
          easing: "easeInOutExpo",
          delay: (el, i) => {
            return 4 * this.selectSpeed + (i * this.selectSpeed) / 2;
          }
        });
      }, 50);
    },
    animateNewTurnFaciData(dur) {
      anime({
        targets: document.querySelectorAll(
          `#faci-calc-panel-${this.faci.id} .data-appear-next`
        ),
        opacity: 0,
        duration: 1
      });
      anime({
        targets: document.querySelectorAll(
          `#faci-calc-panel-${this.faci.id} .data-appear`
        ),
        opacity: [0, 1],
        duration: dur,
        easing: "easeInOutExpo",
        delay: anime.stagger(dur),
        complete: () => {
          anime({
            targets: document.querySelectorAll(
              `#faci-calc-panel-${this.faci.id} .data-appear-next`
            ),
            opacity: [0, 1],
            duration: dur,
            easing: "easeInOutExpo",
            delay: anime.stagger(dur)
          });
        }
      });
    }
  },
  data() {
    return {
      autoShowModalLocalVar: true,
      modalAnimeData: {
        centerStock: 0,
        stock: 0,
        cost: 0,
        backlog: 0,
        revenue: 0
      },
      selectSpeed: 1000,
      speedList: [
        { name: "Slow", duration: 2000 },
        { name: "Normal", duration: 1000 },
        { name: "Fast", duration: 500 }
      ]
    };
  }
};
</script>

<style lang="scss">
.faci-calc-panel-wrapper {
  margin-top: 0;
  @media (max-width: 767px) {
    padding-top: 0;
    .column.is-3 {
      padding-top: 0;
      padding-bottom: 0;
    }
    .modal-content .box {
      overflow-x: hidden;
    }
  }
  .appear-elem,
  .appear-elem {
    display: inline-block;
    opacity: 0;
  }
  .icon {
    opacity: 0.6;
  }
  .box .icon {
    margin-right: 4px;
  }
}
.is-large-faci.is-5-desktop .faci-calc-panel-wrapper .icon {
  margin-right: 4px;
}
.is-large-faci .last-faci-hover {
  border-radius: 0.3em;
  margin: 0.1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  cursor: pointer;
  &:hover {
    background-color: hsla(0, 0%, 21%, 0.1);
  }
}
</style>
