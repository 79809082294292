<template>
  <div class="lean-box-side">
    <b-modal :active.sync="isImproveModalActive" has-modal-card>
      <lean-game-improve></lean-game-improve>
    </b-modal>
    <b-modal :active.sync="isSettingsModalActive" has-modal-card>
      <lean-settings :game="currentGame"></lean-settings>
    </b-modal>
    <div class="box px-0 bg-is-flex-column has-text-centered">
      <div v-if="currentGame.finished" class="mt-1 has-text-danger">
        <b-tooltip
          :active="isSmall"
          label="See Results"
          type="is-dark"
          position="is-right"
        >
          <b-button
            class="my-2"
            type="is-primary"
            tag="router-link"
            size="is-medium"
            :to="'/game/' + currentGame.id + '/results'"
            icon-left="chart-line"
            :label="isSmall ? '' : 'See Results'"
          ></b-button>
        </b-tooltip>
      </div>
      <div v-if="isCreator || !currentGame.creator" class="has-text-centered">
        <b-tooltip
          v-if="!currentGame.params.game_start_at"
          :active="isSmall"
          label="Start Game"
          type="is-dark"
          position="is-right"
        >
          <b-button
            type="is-success"
            @click="$store.dispatch('startLeanRun', [currentGame])"
            icon-left="play"
            class="my-2"
            size="is-medium"
            :label="isSmall ? '' : 'Start Game'"
          >
          </b-button>
        </b-tooltip>

        <b-tooltip
          :active="isSmall"
          v-if="
            currentGame.params.game_start_at && !currentGame.params.game_end_at
          "
          label="End Game"
          type="is-dark"
          position="is-right"
        >
          <b-button
            type="is-danger"
            @click="endGame"
            icon-left="stop"
            class="my-2"
            size="is-medium"
            :label="isSmall ? '' : 'End Game'"
          >
          </b-button>
        </b-tooltip>

        <b-tooltip
          v-if="
            currentGame.params.game_start_at && currentGame.params.game_end_at
          "
          :active="isSmall"
          label="Reset Game"
          type="is-dark"
          position="is-right"
        >
          <b-button
            type="is-danger is-light"
            @click="startAgain"
            icon-left="reload"
            class="my-2"
            size="is-medium"
            :label="isSmall ? '' : 'Reset Game'"
          >
          </b-button>
        </b-tooltip>
        <b-tooltip
          v-if="isCreator"
          :active="isSmall"
          label="Settings"
          type="is-dark"
          position="is-right"
        >
          <b-button
            class="my-2"
            size="is-medium"
            type="is-warning"
            icon-left="cog"
            @click="isSettingsModalActive = true"
            :label="isSmall ? '' : 'Game Settings'"
          >
          </b-button>
        </b-tooltip>
        <div
          class="is-divider my-3 is-hidden-mobile"
          style="opacity: 0.5;"
        ></div>
      </div>
      <div class="has-text-centered">
        <b-tooltip
          :active="isSmall"
          label="Instructions"
          type="is-dark"
          position="is-right"
        >
          <b-button
            :label="isSmall ? '' : 'Instructions'"
            class="my-2"
            size="is-medium"
            type="is-light"
            icon-left="help-circle"
            @click="$store.commit('setShowOnboarding', true)"
          >
          </b-button>
        </b-tooltip>
        <b-tooltip
          :active="isSmall"
          label="Chat"
          type="is-dark"
          position="is-right"
        >
          <b-button
            :label="isSmall ? '' : 'Chat'"
            class="my-2"
            size="is-medium"
            type="is-light"
            icon-left="email"
            @click="$store.commit('setShowMessages', !showMessages)"
          >
          </b-button>
        </b-tooltip>

        <b-tooltip
          v-if="hasImprovements"
          :active="isSmall"
          label="Improve"
          type="is-dark"
          position="is-right"
        >
          <b-button
            class="my-2"
            size="is-medium"
            type="is-info"
            icon-left="chevron-triple-up"
            @click="isImproveModalActive = true"
            :label="isSmall ? '' : 'Improve'"
          >
          </b-button>
        </b-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LeanSettings from "@/components/lean/Play/LeanSettings.vue";
import LeanGameImprove from "@/components/lean/Play/Menus/LeanGameImprove.vue";

export default {
  name: "LeanStage",
  props: {
    isSmall: Boolean
  },
  components: {
    LeanSettings,
    LeanGameImprove
  },
  computed: {
    ...mapGetters([
      "currentGame",
      "isCreator",
      "myFaci",
      "currentProducts",
      "showOnboarding",
      "showMessages"
    ]),
    allStock() {
      return this.currentProducts.filter(prod => !prod.fulfilled_at).length;
    },
    hasImprovements() {
      return Object.values(this.currentGame.params.improvements).some(val => {
        return val;
      });
    }
  },
  methods: {
    startAgain() {
      this.$store.dispatch("resetLeanRun", [this.currentGame]).then(() => {
        if (this.$refs.maintimer) {
          this.$refs.maintimer.updateCycle();
        }
      });
    },
    endGame() {
      this.$store.dispatch("updateGameDoc", [
        {
          obj: {
            finished: true,
            [`params.game_end_at`]: new Date()
          },
          gid: this.currentGame.id
        }
      ]);
    }
  },
  data() {
    return {
      isSettingsModalActive: false,
      isStatsModalActive: false,
      isImproveModalActive: false
    };
  }
};
</script>

<style lang="scss">
.lean-box-side .box.bg-is-flex-column {
  height: 100%;
}
</style>
