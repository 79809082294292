<template>
  <section class="stats-section" v-if="myStats">
    <!-- GRAPHS AND TABLES ALL FACI (LIST) -->
    <div class="columns is-gapless is-multiline is-centered">
      <div
        class="column is-multiline is-gapless"
        :class="{
          'has-right-border': visibleFacilities.length > 1,
          'is-3': visibleFacilities.length > 4,
          columns: visibleFacilities.length <= 1,
          'is-full': vertical
        }"
        v-for="faci in visibleFacilities"
        :key="faci.id"
      >
        <div
          class="column is-full has-text-centered is-paddingless"
          :class="{ 'mt-2': vertical }"
        >
          <h1 class="title is-6 has-text-grey-darker">
            <b-icon :icon="faci.icon" size="is-small"></b-icon>
            {{ faci.name | capitalize }}
            <span class="has-text-grey is-size-6">{{
              faci.playerName | capitalize({ onlyFirstLetter: true })
            }}</span>
            <span v-if="!statsObj[faci.id]">: no stats yet</span>
          </h1>
        </div>
        <div
          class="column is-paddingless"
          :class="{
            'is-full': visibleFacilities.length > 1,
            'is-centered': visibleFacilities.length <= 1
          }"
        >
          <faci-chart
            ref="myStatsChart"
            :my-style="myStyle"
            :maxPcs="
              Math.max(globalMax ? globalMax.maxPcs : 0, statsObj.maxPcs)
            "
            :maxVal="
              Math.max(globalMax ? globalMax.maxVal : 0, statsObj.maxVal)
            "
            :metrics="customMetrics"
            :stats="statsObj[faci.id]"
            :currency="game.params.currency"
            :timeUnit="game.params.timeUnit || 'Week'"
            :show-legend="showLegend"
          ></faci-chart>
        </div>
        <div
          class="column tables-wrapper"
          v-if="showDetails"
          :class="{
            'is-full': visibleFacilities.length > 1,
            'is-centered': visibleFacilities.length <= 1
          }"
        >
          <stats-table
            :nbtab="visibleFacilities.length"
            :stats="statsObj[faci.id]"
            :metrics="customMetrics"
            :currency="game.params.currency"
            :timeUnit="game.params.timeUnit || 'Week'"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import StatsTable from "@/components/bg/Stats/StatsTable.vue";
import FaciChart from "@/components/bg/Stats/FaciChartContainer.vue";
import firebase from "@/firebaseConfig";

export default {
  name: "StatsSection",
  components: {
    StatsTable,
    FaciChart
  },
  props: {
    showFaciTab: Array,
    showDetails: Boolean,
    showTotal: Boolean,
    customMetrics: Array,
    vertical: Boolean,
    showLegend: Boolean,
    game: Object,
    myStyle: Object,
    globalMax: Object
  },
  computed: {
    visibleFacilities() {
      if (!this.showTotal) {
        return Object.values(this.game.facilities).filter(faci => {
          return this.showFaciTab.includes(faci.id);
        });
      } else {
        return [{ id: "all", name: "Game Total" }];
      }
    },
    statsObj() {
      let retObj = {
        all: { week: [] },
        maxPcs: 0,
        maxAllPcs: 0,
        maxVal: 0,
        maxAllVal: 0
      };
      let copyStats = JSON.parse(JSON.stringify(this.myStats));
      copyStats
        .sort((x, y) => {
          return x.week - y.week;
        })
        .forEach(statElem => {
          for (let [faciKey, faciVal] of Object.entries(statElem.facilities)) {
            for (let [valKey, valVal] of Object.entries(faciVal)) {
              if (!retObj[faciKey]) {
                retObj[faciKey] = { week: [] };
              }
              if (!retObj[faciKey][valKey]) {
                retObj[faciKey][valKey] = [];
              }
              if (!retObj["all"][valKey]) {
                retObj["all"][valKey] = [];
              }
              retObj[faciKey][valKey].push(valVal);
              if (
                this.showFaciTab.includes(faciKey) &&
                this.customMetrics.includes(valKey)
              ) {
                if (valKey !== "cost" && valKey !== "revenue") {
                  if (retObj.maxPcs < valVal) {
                    retObj.maxPcs = valVal;
                  }
                } else {
                  if (retObj.maxVal < valVal) {
                    retObj.maxVal = valVal;
                  }
                }
              }
              // Sum all metrics into All except the ones of the consumer (orders)
              if (faciKey !== "0") {
                if (
                  retObj["all"][valKey].length < retObj[faciKey][valKey].length
                ) {
                  retObj["all"][valKey].push(valVal);
                } else {
                  retObj["all"][valKey][
                    retObj["all"][valKey].length - 1
                  ] += valVal;
                }
              }
            }
            if (!retObj[faciKey]) {
              retObj[faciKey] = { week: [] };
            }
            retObj[faciKey]["week"].push(statElem.week);
          }
          retObj["all"]["week"].push(statElem.week);
        });
      if (retObj["1"] && retObj["1"]["demand"].length) {
        for (let [faciKey] of Object.entries(retObj)) {
          if (retObj[faciKey] && typeof retObj[faciKey] === "object") {
            retObj["1"]["demand"].forEach(dem => {
              if (!retObj[faciKey]["retailerDemand"]) {
                retObj[faciKey]["retailerDemand"] = [];
              }
              retObj[faciKey]["retailerDemand"].push(dem);
            });
          }
        }
      }
      //Find the maxPcs and maxVal of the cumulated "all" faci data :
      let pcsTab = [];
      let valTab = [];
      this.customMetrics.forEach(valKey => {
        if (retObj["all"][valKey] && retObj["all"][valKey].length) {
          if (valKey === "cost" || valKey === "revenue") {
            valTab.push(...retObj["all"][valKey]);
          } else {
            pcsTab.push(...retObj["all"][valKey]);
          }
        }
      });
      retObj.maxAllPcs = Math.max(...pcsTab);
      retObj.maxAllVal = Math.max(...valTab);
      return retObj;
    }
  },
  watch: {
    "game.id": {
      immediate: true,
      handler(gid) {
        this.$bind(
          "myStats",
          firebase
            .firestore()
            .collection("games")
            .doc(gid)
            .collection("stats")
        );
      }
    },
    showFaciTab(newTab) {
      if (
        newTab.length &&
        this.$refs.myStatsChart &&
        this.$refs.myStatsChart.length
      ) {
        this.$refs.myStatsChart.forEach(mc => {
          if (mc.$refs.myCustomChart) {
            mc.$refs.myCustomChart.launchUpdate();
          }
        });
      }
    }
  },
  data() {
    return {
      myStats: []
    };
  }
};
</script>

<style lang="scss">
.stats-section {
  .container {
    margin-bottom: 2em;
  }
  .columns.column {
    padding: 0;
    margin: 0;
  }
  .is-multiline {
    overflow-x: scroll;
    white-space: nowrap;
    .is-centered div {
      padding: 0 2%;
    }
  }
  .is-multiline.has-right-border {
    border-right: 1px dotted lightgrey;
  }
}
.analysis-tab .stats-section .is-multiline {
  overflow-x: hidden !important;
}
</style>
