<template>
  <div class="stage-wrapper" v-if="currentFacilities">
    <bg-recap-box></bg-recap-box>
    <section class="pt-2">
      <div class="container mb-2" v-if="isCreator">
        <b-field v-if="myFaci">
          <b-checkbox
            v-model="showStudent"
            class="has-text-link pl-5"
            type="is-link"
          >
            See the game stage as a student
          </b-checkbox>
        </b-field>
      </div>
      <div class="container mb-2 has-text-centered" v-if="currentGame.finished">
        <p class="is-italic is-size-4">
          This game is finished.
        </p>
        <p class="mt-3 mb-6">
          <b-button
            size="is-medium"
            type="is-success"
            rounded
            :style="{
              'background-color': custoLook.btnBestColor,
              color: 'white'
            }"
            tag="router-link"
            :to="'/game/' + currentGame.id + '/results'"
            icon-left="chart-line"
          >
            See the results
          </b-button>
        </p>
      </div>
      <div
        class="columns is-marginless is-paddingless is-centered is-multiline is-desktop mb-4"
      >
        <facility
          :ref="`faci-${faci.id}`"
          v-for="faci in currentFacilities"
          :key="faci.id"
          :faci="faci"
          @editname="$emit('editname')"
        />
      </div>
    </section>

    <!-- STATISTICS filter -->
    <div class="container pl-3">
      <h5 class="title is-4 mb-3">Statistics</h5>
      <div class="buttons mb-3" v-if="showAllData">
        <button
          class="button is-dark"
          @click="filterFaci('total')"
          :style="{
            'background-color': showStatsTotal
              ? custoLook.btnBestColor
              : 'hsl(48, 100%, 67%)',
            color: showStatsTotal ? 'white' : 'hsl(0, 0%, 21%)'
          }"
        >
          Game total
        </button>
        <button
          class="button"
          v-for="faci in currentFacilities"
          :key="faci.id"
          @click="filterFaci(faci.id)"
          :style="{
            'background-color':
              showStatsFaci.includes(faci.id) && !showStatsTotal
                ? custoLook.btnBestColor
                : 'white',
            color:
              showStatsFaci.includes(faci.id) && !showStatsTotal
                ? 'white'
                : 'hsl(0, 0%, 21%)'
          }"
        >
          {{ faci.name | capitalize({ onlyFirstLetter: true }) }}
        </button>
      </div>
    </div>
    <div class="container box">
      <select-metrics
        v-model="checkMetrics"
        :game="currentGame"
        :start-with="baseMetrics"
      ></select-metrics>
    </div>
    <stats-section
      :show-details="true"
      :show-faci-tab="showStatsFaci"
      :show-total="showStatsTotal"
      :custom-metrics="checkMetrics"
      :game="currentGame"
      :show-legend="true"
    ></stats-section>
  </div>
</template>

<script>
import BgRecapBox from "@/components/bg/BgRecapBox.vue";
import Facility from "@/components/bg/Facility/Facility.vue";
import StatsSection from "@/components/bg/Stats/StatsSection.vue";
import SelectMetrics from "@/components/bg/Stats/SelectMetrics.vue";
import { mapGetters } from "vuex";

export default {
  name: "Stage",
  components: {
    BgRecapBox,
    Facility,
    StatsSection,
    SelectMetrics
  },
  computed: {
    ...mapGetters([
      "loading",
      "currentGame",
      "currentUser",
      "authUser",
      "currentFacilities",
      "isCreator",
      "showAllData",
      "custoLook",
      "myFaci"
    ]),
    finished() {
      return this.currentGame.finished;
    },
    baseMetrics() {
      let retTab = [
        "stock",
        "backlog",
        "order",
        "cost",
        "demand",
        "delivery",
        "shipment"
      ];
      if (this.isCreator && this.showAllData) {
        retTab.push("revenue", "missed");
      } else if (this.myFaci) {
        if (!this.myFaci.allowBacklog) {
          retTab.push("missed");
        }
        if (this.myFaci.unitPrice) {
          retTab.push("revenue");
        }
      }
      return retTab;
    }
  },
  mounted() {
    if (this.myFaci) {
      this.showStudent = true;
      this.$store.commit("setShowStudentView", true);
    } else {
      this.showStudent = false;
      this.$store.commit("setShowStudentView", false);
    }

    this.$store.dispatch("bindOrders", {
      id: this.currentGame.id,
      force: false
    });
    this.$store.dispatch("bindMessages", {
      id: this.currentGame.id,
      force: false
    });
    this.$store.dispatch("bindStats", {
      id: this.currentGame.id,
      force: false
    });
    this.resetFilterFaci();
  },
  methods: {
    filterFaci(filter) {
      if (filter === "all") {
        if (this.showStatsTotal) {
          this.showStatsTotal = false;
        } else {
          if (this.showStatsFaci.length < this.currentFacilities.length) {
            this.showStatsFaci = this.currentFacilities.map(fac => {
              return fac.id;
            });
          } else {
            this.showStatsFaci = [];
          }
        }
      } else if (filter === "total") {
        this.showStatsTotal = !this.showStatsTotal;
        this.showStatsFaci = this.currentFacilities.map(fac => {
          return fac.id;
        });
      } else {
        if (this.showStatsTotal) {
          this.showStatsTotal = false;
          this.showStatsFaci = [filter];
        } else {
          if (this.showStatsFaci.includes(filter)) {
            this.showStatsFaci = this.showStatsFaci.filter(
              fac => fac !== filter
            );
          } else {
            this.showStatsFaci.push(filter);
          }
        }
      }
    },
    resetFilterFaci() {
      if (this.currentUser && this.myFaci) {
        this.filterFaci("total");
        this.filterFaci(this.currentUser.playingGame.roleId);
      } else {
        this.filterFaci("total");
      }
    }
  },
  watch: {
    currentUser() {
      this.resetFilterFaci();
    },
    showAllData() {
      this.resetFilterFaci();
    },
    showStudent(newVal) {
      if (typeof newVal !== "undefined") {
        this.resetFilterFaci();
        this.$store.commit("setShowStudentView", newVal);
      }
    }
  },
  data() {
    return {
      checkMetrics: [],
      timerCommit: false,
      showStudent: true,
      showStatsFaci: [],
      showStatsTotal: false
    };
  }
};
</script>

<style lang="scss">
.stage-wrapper {
  @media (max-width: 767px) {
    .section {
      padding-top: 0;
    }
  }

  .nopadtop {
    padding-top: 0;
  }
  .b-checkbox {
    margin-top: 0.5em;
  }
}
</style>
